<mi-page-header title="{{title}}" [breadcrumbs]="breadcrumbs" *ngIf="title">
</mi-page-header>
<div class="mt-2">
  <div class="card">
    <div class="note note-info mb-2">
      <h3>Company Information</h3>
    </div>
    <ng-container>
      <div class="d-flex w-40 card p-3 ml-2">
        <div class="form-container">
          <div class="mb-3">
            <strong class="required-text required">Company Name</strong>
            <div><span type="text" class="domain--suffix text-style mt-2">{{tenantV1Data.v2.name}}</span></div>
          </div>
          <div class="">
            <strong class="required-text required">URL</strong>
            <div><span type="text" class="domain--suffix text-style">{{tenantV1Data.v2.url}}</span></div>
          </div>
          <!-- <div class="mb-3">
            <strong class="required-text">Company Logo</strong>
            <div><img class="img-fluid text-style mt-2" src="https://placehold.co/60x40?text=Company Logo"></div>
          </div> -->
        </div>
        <div class="edit-container">
          <div class="position-btn" (click)="onEditClick()"></div>
        </div>
      </div>
      <div class="d-flex w-40 card p-3 mt-2 ml-2 mb-2">
        <mi-opacity-loader [ready]="isDataLoaded">
          <div class="form-container">
            <div class="mb-3">
              <strong class="required-text required">Primary Contact</strong>
              <div><span type="text" class="domain--suffix text-style">{{fullName}}</span></div>
            </div>
            <div class="mb-3">
              <strong class="required-text required">Email</strong>
              <div><span type="text" class="domain--suffix text-style">{{tenantContactData.email}}</span></div>
            </div>
            <div>
              <strong class="required-text required">Phone</strong>
              <div>
                <span class="domain--suffix text-style">
                  {{ tenantContactData.mobile_phone ? tenantContactData.mobile_phone : '-' }}
                </span>
              </div>
            </div>
          </div>
          <div class="edit-container">
            <div class="position-contact-btn" (click)="onEditContactClick()"></div>
          </div>
        </mi-opacity-loader>
      </div>
    </ng-container>
  </div>
</div>
<modal #editContactModal>
  <modal-header>
    <h4>Edit Contact Details</h4>
  </modal-header>
  <modal-content>
    <form [formGroup]="form">
      <mi-extended-input labelText="First Name" [labelRequired]="true" [(inputControl)]="form.controls['first_name']"
        [errorDefs]="{required: 'Please enter a first name', invalidFormat: 'Please enter a valid first name!'}">
        <input id="first_name" class="form-control" name="first_name" placeholder="" formControlName="first_name"
          type="text" [attr.disabled]="!canEditView ? true : null" autocomplete="none" mi-restrict-input miRestrictType="Name">
      </mi-extended-input>
      <mi-extended-input labelText="Last Name" [labelRequired]="true" [(inputControl)]="form.controls['last_name']"
        [errorDefs]="{required: 'Please enter a last name.', invalidFormat: 'Please enter a valid last name!'}">
        <input id="last_name" class="form-control" name="last_name" placeholder="" formControlName="last_name"
          type="text" [attr.disabled]="!canEditView ? true : null" autocomplete="none" mi-restrict-input miRestrictType="Name">
      </mi-extended-input>
      <mi-extended-input labelText="Email" [labelRequired]="true" [(inputControl)]="form.controls['email']"
        [errorDefs]="{ required: 'Please enter an email address.', email: 'Please enter a valid email address.' }">
        <input id="email" class="form-control" name="email" formControlName="email" type="email" autocomplete="off"
          [attr.disabled]="!canEditView ? true : null">
      </mi-extended-input>
      <mi-extended-input labelText="Phone" [labelRequired]="true" [(inputControl)]="form.controls['mobile_phone']"
        [errorDefs]="{ required: 'Please enter your mobile phone number.', pattern: 'Please enter a valid  phone number.' }">
        <input id="mobile_phone" class="form-control" name="mobile_phone" formControlName="mobile_phone" type="tel"
          autocomplete="off" [attr.disabled]="!canEditView ? true : null">
      </mi-extended-input>
    </form>
  </modal-content>
  <modal-footer>
    <div class="d-flex mr-1">
      <div>
        <a mi-button href="#" buttonTheme="light-theme" (click)="onResetForm($event)">Cancel</a>
        <button [disabled]="!form.valid" mi-button buttonTheme="dark-theme" class="login-button ml-2"
          (click)="onSubmitContact(form.value)">Save</button>
      </div>
    </div>
  </modal-footer>
</modal>
<modal #editModal>
  <modal-header>
    <h4>Edit Company Details</h4>
  </modal-header>
  <modal-content>
    <form [formGroup]="formCompany">
      <mi-extended-input class="domain--input" labelText="Company Name" [labelRequired]="true"
        [(inputControl)]="formCompany.controls['name']" [errorDefs]="{ required: 'Please enter your company name' }">
        <input id="name" class="form-control" name="name" placeholder="" formControlName="name" type="text"
          [attr.disabled]="!canEditView ? true : null" autocomplete="none" mi-restrict-input miRestrictType="NameWithNumber">
      </mi-extended-input>
      <mi-extended-input class="domain--input" labelText="URL" [labelRequired]="true"
        [(inputControl)]="formCompany.controls['url']" [errorDefs]="{
            required: 'Please enter your company web address.',
            url: 'Please enter a valid web address'
           }">
        <input id="url" class="form-control" name="url" placeholder="" formControlName="url" type="text"
          [attr.disabled]="!canEditView ? true : null" autocomplete="none">
      </mi-extended-input>
      <!-- <mi-extended-input labelText="Company Logo" [(inputControl)]="formCompany.controls['logo']" [errorDefs]='{}'>
        <mi-upload formControlName="logo" accept="{{UPLOAD_TYPES.TENANT_LOGO.CONTENT_TYPE}}"
          upload_type="{{UPLOAD_TYPES.TENANT_LOGO.KEY_TO_STRING}}">
        </mi-upload>
      </mi-extended-input> -->
    </form>
  </modal-content>
  <modal-footer>
    <div class="d-flex mr-1">
      <div>
        <a mi-button href="#" buttonTheme="light-theme" (click)="onResetForm($event)">Cancel</a>
        <button [disabled]="!formCompany.valid" mi-button buttonTheme="dark-theme" class="login-button ml-2"
          (click)="onSubmitCompany(formCompany.value)">Save</button>
      </div>
    </div>
  </modal-footer>
</modal>