import {Component, Input, Output, OnInit, OnDestroy, EventEmitter} from '@angular/core';
import {AbstractPageComponent} from '../../pages/abstract-page/abstract-page.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'mi-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  searchForm: FormGroup;
  searchValue: Subject<string> = new Subject<string>();
  searchString: string = '';
  _lastValue: string = '';

  @Input() type: string = 'primary';
  @Input() qtyMessage: string = '';
  @Input() placeholder: string = 'Quick Search';
  @Input() isSearchDisabled: boolean = false;
  @Output() searchTermChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchSubmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() clearSearch: EventEmitter<string> = new EventEmitter();

  // you can set value from outside, and it will overwrite when it changes
  @Input('value')
  set value(val: string) {
    if(this._lastValue && !val) {
      this.searchForm.get('search').setValue('');
    }
    if(!this.searchForm){
      this.searchForm = this.fb.group({
        search: [this._lastValue]
      });
    }
    if (val && val !== this._lastValue) {
      this._lastValue = val;
      this.searchForm.get('search').setValue(val);
    }
  }

  constructor(private fb: FormBuilder) {
    super();
  }

  clearSearchEvent() {
    this.searchForm.get('search').setValue('');
    this.clearSearch.emit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {

    this.searchForm = this.fb.group({
      search: [this._lastValue]
    });

    this.searchForm.valueChanges
      .takeUntil(this.destroy$)
      .subscribe((res) => {
        this.searchValue.next(res.search);
      });

    this.searchValue.pipe(debounceTime(1000))
      .subscribe((query)=>{
        this.searchString = this._lastValue = query;
        this.searchTermChanged.emit(this.searchString);
        this.processSearch(query);
    })

  }

  onSearchSubmit(data: any): void {
    this.searchValue.next(data.search || '');
    this.processSearch(data.search || '');
  }

  processSearch(str: string): void {
    this.searchSubmit.emit(str);
  }
}
