import { Component, Input, TemplateRef, ViewChild, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import * as ASActions from "../../../../shared/state/advanced-search/actions";
import { NO_DATA } from "../../../../shared/ng-models/SectionDataTable.model";
import { NOTIFICATION_SEVERITY } from "../../../../shared/utils/notificationSeverity";
import { SectionDataTableColumn } from "../../../../shared/ng-models/SectionDataColumn.interface";
import { AbstractPageComponent } from "../../../../shared/pages/abstract-page/abstract-page.component";
import * as appDataReducer from "../../../../shared/state/app-data/reducers";
import { ModalService } from '@independer/ng-modal';
import { UsersManagementFormModalComponent } from "src/shared/components/users-management-form-modal/users-management-form-modal.component";
import { UsersAccessFormModalComponent } from 'src/shared/components/users-management-form/users-access-form-modal/users-access-form-modal.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PaginationPayload } from 'src/shared/models/MiPagination';
import { UsersV2Service } from 'src/libs/api2/users-v2/users-v2.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from "rxjs";
import { Link } from "src/shared/ng-models/Link.interface";

@Component({
  selector: 'mi-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[];
  title: string;
  loaded: boolean = false;


  @Input() canEditRoleOnBu: boolean = false;
  @Input() employeeList: boolean = true;
  hits: any[];
  resultsLoaded: boolean = false;
  queryParams: Params;
  totalRow: number;
  pageNumber: number = 0;
  pageSizeData: number[] = [10, 20, 30, 40, 50];
  selectedPageSize: number = this.pageSizeData[0];
  totalPages: number = 1
  searchParam: Subject<string> = new Subject<string>();
  userSearchParam: string = ""
  error: string
  tenantId: string
  userType: string
  sortByColumn: string
  selectedColumnOrder: string

  isLoading: boolean = true;

  readonly NO_DATA: string = NO_DATA;
  readonly ARCHIVED: string = NOTIFICATION_SEVERITY.ARCHIVED;
  @ViewChild('mrcTmpl', { static: true }) mrcTmpl: TemplateRef<any>;
  @ViewChild('contactTmpl', { static: true }) contactTmpl: TemplateRef<any>;
  @ViewChild('mobileTmpl', { static: true }) mobileTmpl: TemplateRef<any>;
  @ViewChild('costCenterTmpl', { static: true }) costCenterTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', { static: true }) statusTmpl: TemplateRef<any>;
  @ViewChild('miMisoAccessTmpl', { static: true }) miMisoAccessTmpl: TemplateRef<any>;
  // @ViewChild('misoAccessTmpl', { static: true }) misoAccessTmpl: TemplateRef<any>;
  @ViewChild('vendorTmpl', { static: true }) vendorTmpl: TemplateRef<any>;
  @ViewChild('userAccessTmpl', { static: true }) userAccessTmpl: TemplateRef<any>;

  contactsColumns: SectionDataTableColumn[] = [];

  constructor(
    private store: Store<any>,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private usersV2Service: UsersV2Service,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.getTenantDetails();
    this.initializeTable();

    this.activatedRoute
      .params
      .subscribe((queryParams: Params) => {

        this.loaded = false;
        this.title = `Users`;

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: this.title,
            url: './',
            internal: true
          }
        ];
        this.loaded = true;
      });

    this.searchParam.pipe(debounceTime(1000))
      .subscribe(searchParam => {
        this.userSearchParam = searchParam
        this.getTenantUsers();
      });
  }

  onUserCreated(newUserData: any) {
    this.searchParamChangeHandler(newUserData.email);
  }

  initializeTable() {
    this.contactsColumns = [
      {
        name: "Employee Name",
        prop: 'full_name',
        cellTemplate: this.contactTmpl,
        sortable: true,
        serverSideSortName: 'first_name'
      },
      {
        name: "Email",
        prop: 'user.email',
        sortable: true,
        serverSideSortName: 'email'
      },
      {
        name: "Mobile Phone",
        prop: 'user.mobile_phone',
        cellTemplate: this.mobileTmpl
      },
      // {
      //   name: "Cost Center",
      //   prop: 'user.cost_center',
      //   cellTemplate: this.costCenterTmpl,
      //   sortable: true,
      //   serverSideSortName: 'cost_center'
      // },
      {
        name: 'Status',
        prop: 'user.is_active',
        cellTemplate: this.statusTmpl,
        sortable: true,
        serverSideSortName: 'is_active'
      },
      {
        name: "MiSO3 Access",
        prop: 'user.miso_access',
        sortable: false,
      },
      {
        name: 'Mi-MiSO Access',
        prop: 'user.mi_access_level',
        cellTemplate: this.miMisoAccessTmpl,
        sortable: false,
      }
    ];

    if (this.employeeList) {
      this.contactsColumns.push(
        {
          name: 'Access',
          prop: 'id',
          cellTemplate: this.userAccessTmpl,
          sortable: false
        }
      );
    }
  }

  getTenantDetails() {
    this.isLoading = false;
    const tenantStateData = this.store.select(appDataReducer.getAppData);
    tenantStateData.first().subscribe(
      (data) => {
        this.tenantId = data.tenantData.domain
        this.getTenantUsers();
        this.isLoading = true;
      },
      (e) => (this.error = e)
    );
  }

  sortChangeHandler(event) {
    this.sortByColumn = event.column.serverSideSortName
    this.selectedColumnOrder = event.column.sort.toUpperCase()
    this.getTenantUsers()
  }

  searchParamChangeHandler(searchParam) {
    const trimmedSearchParam = searchParam.replace(/\s{2,}/g, ' ').trim()
    this.searchParam.next(trimmedSearchParam);
    this.userSearchParam = trimmedSearchParam
    this.pageNumber = 0
  }

  onClearSearch() {
    this.searchParam.next('');
    this.userSearchParam = ""
  }

  getTenantUsers() {
    this.resultsLoaded = false;
    this.isLoading = false;
    this.usersV2Service
      .getUsersByVendor(this.pageNumber, this.selectedPageSize, this.userSearchParam, this.userType, this.sortByColumn, this.selectedColumnOrder, "cmrc")
      .toPromise()
      .then(data => {
        this.hits = data.data
        this.totalRow = data.total
        this.totalPages = this.totalRow > 0 ? Math.ceil(this.totalRow / this.selectedPageSize) : 1
        this.resultsLoaded = true;
        this.isLoading = true;
      })
      .catch((e) => (this.error = e));
      this.isLoading = false;
  }

  paginationChangeHandler(paginationPayload: PaginationPayload) {
    this.pageNumber = paginationPayload.selectedPageNumber - 1
    this.selectedPageSize = paginationPayload.selectedPageSize
    this.getTenantUsers()
  }

  openUserEditModal(userId) {
    const modalRef = this.modalService.open(UsersManagementFormModalComponent, m => {
      m.userId = userId;
      m.canEditRoleOnBu = true;      
    });
    console.log('here the modalRef', modalRef);
    
    modalRef.closed.subscribe(({ reason, result = [] }) => {
      //results come in array, as the modal supports multiple returned results
      const [user] = result;
      if (user) {
        this.getTenantUsers()
        setTimeout(() => {
          this.store.dispatch(new ASActions.AsDeeplinkChanged(this.queryParams));
          //this.store.dispatch(new ShowPageAlertAction({}));
        }, 1000);
      }
    });
  }

  openUserAccessModal(userId, row) {
    const modalRef = this.modalService.open(UsersAccessFormModalComponent, m => {
      m.editId = userId;
      m.userData = row;
      m.canEditRoleOnBu = this.canEditRoleOnBu;
    });

    modalRef.closed.subscribe(({ reason, result = [] }) => {
      //results come in array, as the modal supports multiple returned results
      const [message] = result;
      if (message) {
        setTimeout(() => {
          this.store.dispatch(new ASActions.AsDeeplinkChanged(this.queryParams));
          //this.store.dispatch(new ShowPageAlertAction({}));
        }, 1000);
      }
      if (result[0].updated) {
        this.getTenantUsers()
      }
    });
  }

}
