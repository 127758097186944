import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractPageComponent } from "../../../shared/pages/abstract-page/abstract-page.component";
import * as userReducer from "../../../libs/user-store/state/reducers";
import { select, Store } from "@ngrx/store";
import { TenantV2 } from "../../../libs/api2/types/TenantV2.interface";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ISO_TIMEZONES, IsoTimezone } from "../../../common/utils/isoConstants";
import * as appDataReducer from "../../../shared/state/app-data/reducers";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { TenantV2Service } from "../../../libs/api2/tenant-v2/tenant-v2.service";
import { combineLatest, Observable } from "rxjs";
import { Settings as luxonSettings } from 'luxon';
import { isEditRoleOnBU } from "../../../common/utils/UserAccess";
import { urlValidator, alphanumericValidator, noWhitespaceValidator, minLengthWithTrimmedWhitespace, maxLengthWithTrimmedWhitespace } from 'src/shared/shared.validators';
import { UPLOAD_TYPES } from '../../../common/utils/sharedConstants';
import { FileUpload } from '../../../shared/models/FileUpload';
import { ModalService } from '@independer/ng-modal';
import { LocationV2 } from '../../../libs/api2/types/LocationV2.interface';
import { CurrencyService } from '../../../libs/api2/currency-v2/currency.service';
import { TenantCurrencyV2 } from '../../../libs/api2/types/TenantCurrencyV2.interface';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { TenantMiServiceService } from 'src/libs/api/tenant/tenantMiService.service';
import { ExcelEmailNotificationModalComponent } from 'src/shared/components/excel-email-notification-modal/excel-email-notification-modal.component';
import linkFormatter from 'src/common/utils/linkFormatter';
import { ToastrService } from 'ngx-toastr';
import { TenantUserManagementService } from 'src/libs/api/tenant/tenantUserManagement.service';
import { Link } from 'src/shared/ng-models/Link.interface';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'mi-platform-information',
  templateUrl: './platform-information.component.html',
  styleUrls: ['./platform-information.component.scss']
})
export class PlatformInformationComponent extends AbstractPageComponent implements OnInit {

  readonly DOMAIN_MIN_LENGTH: number = 4;
  readonly DOMAIN_MAX_LENGTH: number = 25;

  loaded: boolean = false;
  buData: TenantV2;
  error: any;
  form: FormGroup;
  timezones: IsoTimezone[];
  success: string = '';
  $isSuperUser: Observable<boolean>;
  canViewOrEdit: boolean = false;
  location: LocationV2;
  locationId: string;
  tenantId: string;
  canRecalculate: boolean = false;
  allTenants: any = [{
    value: '',  // or any value representing "All Tenants"
    title: 'All Tenants'
  }];
  createdOnUtc: string;
  breadcrumbs: Link[];
  title: string;
  isLoading: boolean = true;
  buttonCurrentStatus: boolean
  disableCardContainer: boolean = false;

  readonly UPLOAD_TYPES: any = UPLOAD_TYPES;
  @ViewChild('confirmModal') confirmModal;
  @ViewChild('activeInactiveModal') activeInactiveModal;

  isActive: boolean = false;
  modalTitle: string = '';
  modalMessage: string = '';
  name: string = '';
  tenantName: string = '';

  searchInited = false;

  tenantV1Data: any = {};
  tenantCurrencies: TenantCurrencyV2[];

  oldDomain: string = "";

  constructor(
    private tenantV2Service: TenantV2Service,
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private modalService: ModalService,
    private currencyService: CurrencyService,
    private tenantMiServiceService: TenantMiServiceService,
    private toastr: ToastrService,
    private tenantUserManagementService: TenantUserManagementService,
    private activatedRoute: ActivatedRoute,) {
    super();

  }

  ngOnInit() {
    this.timezones = ISO_TIMEZONES;
    this.getAllTenants();

    this.activatedRoute
      .params
      .subscribe((queryParams: Params) => {

        this.loaded = false;
        this.title = `Platform Information`;

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: this.title,
            url: './',
            internal: true
          }
        ];
        this.loaded = true;
      });


    const buSub = this.store.pipe(
      select(appDataReducer.getAppData),
      map(appData => {        
        this.tenantV1Data = appData.tenantData;
        this.isActive = appData.tenantData.v2.is_active
        this.toggleCardContainer();
        this.oldDomain = appData.tenantData.v2.domain;
        this.tenantId = appData.tenantData.v2.id;
        this.canRecalculate = appData.tenantData.v2.role == 1 || appData.tenantData.v2.role == 6 || appData.tenantData.v2.role == 5;
        this.tenantName = appData.tenantData.display_name;
        return appData.tenantData.domain
      }),
      switchMap(id => this.tenantV2Service.getBusinessUnit(id)),
    );

    combineLatest(
      buSub
    )
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        ([buData]) => {
          this.buData = buData;
          this.isActive = buData.is_active
          this.toggleCardContainer();          
          this.canViewOrEdit = isEditRoleOnBU(buData.role);
          this.currencyService.getTenantCurrencies(this.buData.id, "")
            .toPromise()
            .then(tenantCurrencies => {
              this.tenantCurrencies = tenantCurrencies;
              this.setupForm(this.buData);
            })
        },
        (e) => {
          this.error = e;
        }
      );

    this.$isSuperUser = this.store.pipe(select(userReducer.isSuperUser)) as unknown as Observable<boolean>;

  }
  onStatusChangeHandler(event: boolean): void {
    this.buttonCurrentStatus = event;

    if (this.buttonCurrentStatus) {
        this.modalTitle = 'Activate Confirmation';
    } else {
        this.modalTitle = 'Deactivate Confirmation';
    }
    this.activeInactiveModal.open();
  }

  toggleCardContainer(): void {
    this.disableCardContainer = !this.isActive; // Overlay if not active
  }

  getAllTenants() {
    this.tenantUserManagementService
      .getBusinessUnits()
      .toPromise()
      .then((response) => {
        const tenantList = response
          .filter(customer => customer.created_on_utc)
          .map(customer => {
            const date = new Date(customer.created_on_utc); // Create a Date object
            const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
            return {
              value: customer.id,
              created_on_utc: formattedDate,
              name: customer.name
            };
          });

        const selectedTenant = tenantList.find(tenant => tenant.value === this.tenantId);
        if (selectedTenant) {
          this.name = selectedTenant.name;
        }
        this.createdOnUtc = tenantList.find(tenant => tenant.value === this.tenantId);
      })
      .catch((e) => (this.error = e));
  }

  getSelectedCurrency() {
    return this.tenantCurrencies?.find(
      (currency) => currency.id === this.form.controls['tenant_currency_id'].value
    );
  }


  setupForm(currentData: TenantV2): void {
    this.isLoading = true;
    let {
      domain = '',
      name = '',
      customer_type = 'End Customer',
      time_zone,
      url = '',
      office_phone = '',
      active = true,
      address_location_id,
      tenant_currency_id
    } = currentData;

    active = currentData.is_active;

    this.locationId = address_location_id;
    let logo: FileUpload = this.tenantV1Data.logo;

    if (currentData.logo) {
      if (typeof currentData.logo === 'string') {
        logo = {
          file_name: currentData.logo,
          upload_type: UPLOAD_TYPES.TENANT_LOGO.KEY_PREFIX,
          file_path: currentData.logo,
          full_path: currentData.logo
        };
      }
      else {
        logo = currentData.logo;
      }
    }

    let currency = 'USD';

    if (!time_zone) {
      let timezoneRaw: string = luxonSettings.defaultZoneName || 'America/New_York';

      this.timezones.forEach(tz => {
        if (tz.utc.includes(timezoneRaw)) {
          time_zone = tz.value;
        }
      })
    }

    // debugger;

    if (!this.form) {
      this.form = this.formBuilder.group({
        domain: [domain, Validators.compose([
          Validators.required,
          alphanumericValidator,
          noWhitespaceValidator,
          minLengthWithTrimmedWhitespace(this.DOMAIN_MIN_LENGTH),
          maxLengthWithTrimmedWhitespace(this.DOMAIN_MAX_LENGTH)
        ])],
        name: [name, Validators.compose([Validators.required])],
        url: [url, Validators.compose([urlValidator, Validators.required])],
        office_phone: [office_phone, Validators.compose([])],
        tenant_currency_id: [tenant_currency_id, Validators.compose([])],
        time_zone: [time_zone, Validators.compose([Validators.required])],
        active: [active, Validators.compose([])],
        logo: [logo],
        address_location_id: [address_location_id]
      });
    } else {
      //this must be a reset
      this.form.reset({
        name,
        customer_type,
        time_zone,
        currency,
        office_phone,
        url,
        active,
        logo,
        domain,
        address_location_id,
        tenant_currency_id
      })
    }

    this.loaded = true;
    this.isLoading = false;
  }

  onResetForm($event): void {
    $event.preventDefault();
    this.setupForm(this.buData);
  }

  currencyChangeNotification() {
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m => {
      m.title = "Notification";
      m.message = "You are about to change default currency for your M3 environment. This process will require a recalculation of all services and may take a few minutes.";
      m.closeButtonLabel = "Continue";
    });
  }

  onSubmit(data): void {

    const newData: TenantV2 = {
      ...this.buData,
      ...data,
      is_active: this.buttonCurrentStatus,
      logo: data.logo ? data.logo.full_path : null
    };

    this.success = '';
    this.error = '';

    this.tenantV2Service.saveBusinessUnit(newData)
      .subscribe(
        (data) => {
          //this.store.dispatch(new AppDataGetAction({}));

          if (newData.tenant_currency_id && this.buData.tenant_currency_id != newData.tenant_currency_id) {
            this.tenantMiServiceService.updateCMRC("")
              .toPromise()
              .then(() => { });
            FrontEndSettingsUtil.setCurrency(this.tenantCurrencies.find(c => c.id == newData.tenant_currency_id));
            this.currencyChangeNotification();
          }
          this.buData = data;
          this.isActive = data.is_active;
          this.toggleCardContainer();
          
          this.setupForm(this.buData);
          this.toastr.success(`${this.name} is successfully ${this.buData.is_active == true ? 'activated' : 'deactivated'}`);
          this.activeInactiveModal.close();
          if (data.domain !== this.oldDomain) {
            const link = linkFormatter.getTenantManageLink(data.domain);
            window.location.href = link;
          }
        },
        (e) => {
          this.error = e;
        });
  }

  openModal(): void {
    this.confirmModal.open();
  }

  closeModal(): void {
    this.confirmModal.close();
  }

  closeActiveModal() {
    this.activeInactiveModal.close();
    this.buttonCurrentStatus = this.isActive;
  }

  confirmRecalculation(): void {
    this.tenantV2Service.reCalculatingServices(this.tenantId).subscribe({
      next: () => {
        console.log('here the re-calculation started');
      },
      error: err => {
        console.error(err);
      }
    });
    this.toastr.success(`Recalculation of the services has started.`);
    this.confirmModal.close();
  }

}
