<div *ngIf="filter && !displayNone" class="filter">
  <div *ngIf="!tagHeaderName(filter.filterName)" class="filter-label">{{filter.filterLabel || ('GLOBAL.SEARCH_FILTERS.' + filter.filterName.toUpperCase() | translate) }}</div>
  <div *ngIf="tagHeaderName(filter.filterName)" class="filter-label">{{getTagName(filter.filterName)}}</div>
  <div class="filter-value">
    <ng-container *ngIf="isArray; else singleValue">
      <div *ngFor="let filterValue of filter.value"
           class="filter-value--container">

        <ng-container [ngSwitch]="filter.filterName">
          <ng-container *ngSwitchCase="'business_unit_id'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
                {{filterValue ? businessUnits[filterValue].name : filterValue}}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'service_group_id'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
                {{filterValue ? serviceGroupByIDs[filterValue].name : filterValue}}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'contact'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
                {{filterValue ? contactsByIDs[filterValue].first_name + ' ' + contactsByIDs[filterValue].last_name : filterValue}}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'account_id'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
              <span *ngIf="accountIdsByIDs[filterValue]">
                {{accountIdsByIDs[filterValue].label}} 
                <span *ngIf="accountIdsByIDs[filterValue].label ==='Not Provided'">
                  ({{accountIdsByIDs[filterValue].vendor.vendor_name}})
                </span>
              </span>
              <span *ngIf="!accountIdsByIDs[filterValue]">
                {{filterValue}}
              </span>
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'parent_service_group_search'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
            
              <span *ngIf="!searchInputSVC[filterValue]">
                {{filterValue}}
              </span>
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'next_event'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
                {{'TENANT.SERVICE_DETAILS.EVENTS.'+filterValue | translate}}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'cost_center_id'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
                {{filterValue ? businessUnits[filterValue].name : filterValue}}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'division'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
                {{filterValue ? businessUnits[filterValue].name : filterValue}}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'team'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
                {{filterValue ? businessUnits[filterValue].name : filterValue}}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'service_associations'">
            <a class="filter-item"
                (click)="onRemoveClick(filterValue)">
                {{customField[filterValue] ? customField[filterValue].label : filterValue}}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'parent_service_status'">
            <a class="filter-item"
               (click)="onRemoveClick(filterValue)">
              {{'TENANT.SERVICE_DETAILS.PARENT_SERVICE_STATUS.' + filterValue | translate }}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'alert_status'">
            <a class="filter-item"
               (click)="onRemoveClick(filterValue)">
              {{'ALERT_STATUS.' + filterValue | translate }}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'data_integrity_status'">
            <a class="filter-item"
               (click)="onRemoveClick(filterValue)">
              {{'DATA_INTEGRITY_STATUS.' + filterValue | translate }}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'in_term'">
            <a class="filter-item"
               (click)="onRemoveClick(filterValue)">
              {{'IN_TERM.' + filterValue | translate }}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'document_id'">
            <a class="filter-item"
               (click)="onRemoveClick(filterValue)">
              {{documentDisplayId}}
            </a>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <a class="filter-item no-close" *ngIf="!filter.removable">
              {{filterValue}}
            </a>
            <a class="filter-item" *ngIf="filter.removable"
               (click)="onRemoveClick(filterValue)">
              {{filterValue}}
            </a>
          </ng-container>
        </ng-container>

      </div>
    </ng-container>
    <ng-template #singleValue>
      <div class="filter-value--container">
        <a class="filter-item"
           (click)="onRemoveClick(filter.value)">
          {{filter.value}}
        </a>
      </div>
    </ng-template>
  </div>
</div>