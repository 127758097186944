<mi-page-header title="Document Manager" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
</mi-page-header>


<div class="card mt-2">
    <div class="card-body py-3">
        <div class="d-flex">
            <div class="px-0 w-30 mr-4">
                <div class="label-combo-input">
                    <label class="label-combo-input-label">SVC-</label>
                    <input #searchInputSVC (input)="onSearchChange($event.target.value)"
                        placeholder="Search Documents by MiSO3 ID" type="number" autofocus>
                    <button class="cross-button" *ngIf="!isSearchEmptySVC" (click)="onClearSearchSVC()"></button>
                </div>
            </div>
            <div class="px-0 w-40">
                <div class="label-combo-input">
                    <label class="label-combo-input-big-label">Vendor Service ID</label>
                    <input #searchInputServiceIdentifier (input)="searchByServiceIdentifierHandler($event.target.value)"
                        placeholder="Search Documents by Vendor Service ID" type="text">
                    <button class="cross-button" *ngIf="!isSearchEmptyServiceIdentifier"
                        (click)="onClearSearchServiceIdentifier()"></button>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="card mt-2">
    <div class="sticky-header top-pagination custom-hover-effect">
        <div class="note note-info d-flex justify-content-between align-items-center">
            <div *ngIf="isDataLoaded">
                Total <span><b>{{ totalNumberOfDocuments }}</b></span>
                <span> {{ totalNumberOfDocuments === 1 ? 'Document found.' : 'Documents found.' }}</span>
            </div>
            <div *ngIf="!isDataLoaded">
                Fetching the documents
                <span>
                    <mi-loading-animation [type]="'dot-loader'"></mi-loading-animation>
                </span>
            </div>
            <div>
                <div *ngIf="totalNumberOfDocuments">
                    <custom-pager [perPage]="settings.pager.perPage" [totalItems]="totalNumberOfDocuments"
                        [page]="selectedPageNumber" (pageChange)="onPageChange($event)">
                    </custom-pager>
                </div>
            </div>
        </div>
        <mi-opacity-loader [ready]="isDataLoaded && !isLoading">
            <ng2-smart-table (userRowSelect)="onRowSelect($event)" [settings]="settings" [source]="source"
                class="table-hover"></ng2-smart-table>
        </mi-opacity-loader>
    </div>
</div>