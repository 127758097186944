<mi-server-error [error]="error"></mi-server-error>
<div class="table-wrap-container">
  <div class="top-scroll mb-1" #topScroll>
    <div class="top-scroll-inner"></div>
  </div>
  <div class=table-wrap #tableWrap>
    <table class="mi-sort-table {{externalClasses}}" [ngClass]="_classes">

      <thead>
        <tr>
          <ng-container *ngFor="let column of _columns; let i = index">
            <th *ngIf="!column.hidden" [attr.data-index]="i" [draggable]="i>numberOfStickyColumn-1"
              (drop)="i>numberOfStickyColumn-1? drop($event): ''"
              (dragover)="i>numberOfStickyColumn-1? allowDrop($event): ''"
              (dragleave)="i>numberOfStickyColumn-1? onDragout($event): ''"
              (dragstart)="i>numberOfStickyColumn-1? drag($event): ''" [ngClass]="{'draggable-th': i>numberOfStickyColumn-1, 
        'right': column.headerAlignRight||column.name=='CMRC',
        'fixed-headers': i===numberOfStickyColumn-1
      }">
              <div [attr.data-index]="i" [draggable]="i>0" (drop)="i>0? drop($event): ''"
                [miSortableColumn]="column.sortable" [column]="column" (sortChanged)="onChangeTable($event)" [ngClass]="{
          'header-label':true,
          'sortable': column.sortable,
          'sort-asc': column.sort === 'asc',
          'sort-desc': column.sort === 'desc'}">
                <ng-template [ngTemplateOutlet]="column.headerTemplate" [ngTemplateOutletContext]="{column: column}">
                </ng-template>
              </div>

              <ng-container [ngSwitch]="searchType">
                <ng-container *ngSwitchCase="'CONTACT'">
                  <ng-container [ngSwitch]="column.name">
                    <!-- contact -->
                    <ng-container *ngSwitchCase="'Company'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="vendor_name">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Role'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="role">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Status'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="status">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'TENANT_USER'">
                  <ng-container [ngSwitch]="column.name">
                    <ng-container *ngSwitchCase="'Cost Center'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="cost_center_id">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Status'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="status">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'ADDRESS'">
                  <ng-container [ngSwitch]="column.name">
                    <ng-container *ngSwitchCase="'City'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="city_long">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'State'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="state_long">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Country'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="country_long">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'SERVICE_GROUP'">
                  <ng-container [ngSwitch]="column.name">
                    <ng-container *ngSwitchCase="'Status'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="alert_status">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Spend (cMRC)'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="mrc">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'EQUIPMENT'">
                  <ng-container [ngSwitch]="column.name">
                    <!-- contact -->
                    <ng-container *ngSwitchCase="'Manufacturer'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="vendor_name">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Price $'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="price">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Status'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="status">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Location'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="location_id">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'DOCUMENT'">
                  <ng-container [ngSwitch]="column.name">
                    <!-- DOCUMENT -->
                    <ng-container *ngSwitchCase="'Vendor Name'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="vendor_name">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Type'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="type">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'PRODUCT'">
                  <ng-container [ngSwitch]="column.name">
                    <!-- PRODUCT -->
                    <ng-container *ngSwitchCase="'Category'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="product_category_name">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'ACCOUNT_ID'">
                  <ng-container [ngSwitch]="column.name">
                    <!-- ACCOUNT_ID -->
                    <ng-container *ngSwitchCase="'Account Representative'">
                      <mi-advanced-search-filter-table-header [title]="column.name" filter_name="contact">
                      </mi-advanced-search-filter-table-header>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'SERVICE' || 'EVENT'">
                  <!-- customFields -->
                  <!--
            <ng-container *ngIf="customHeaderName(column.name)">
              <mi-advanced-search-filter-table-header-v2
                [isCustomField]="true"
                [title]="customFieldById[column.name]"
                [filter_name]="column.name">
              </mi-advanced-search-filter-table-header-v2>
            </ng-container>
            -->

                  <!-- tags -->

                  <ng-container *ngIf="tagHeaderName(column.name) && isTagTypeOption(column.name)">
                    <mi-advanced-search-filter-table-header-v2 [isTag]="true"
                      [isTagTypeOption]="isTagTypeOption(column.name)" [tagById]="tagById"
                      [title]="customFieldById[column.name]" [filter_name]="column.name">
                    </mi-advanced-search-filter-table-header-v2>
                  </ng-container>

                  <ng-container [ngSwitch]="column.name">
                    <!-- service -->
                    <ng-container *ngSwitchCase="'Vendor (Purchasing)'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name"
                        filter_name="purchasing_vendor_name">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Vendor (Manufacturer)'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name"
                        filter_name="manufacturing_vendor_name">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Product'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="product_name">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Account ID'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="account_id_label">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <!--
              <ng-container *ngSwitchCase="'M3 Owner'">
                <mi-advanced-search-filter-table-header-v2
                  [title]="column.name"
                  filter_name="m3_owner_email">
                </mi-advanced-search-filter-table-header-v2>
              </ng-container>
              -->
                    <ng-container *ngSwitchCase="'Business Unit'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="business_unit_id">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Service Status'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="service_status">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Data Integrity'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name"
                        filter_name="data_integrity_status">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Service Category'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name"
                        filter_name="product_category_name">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Location A'">
                      <mi-advanced-search-filter-table-header-v2 [title]="'Location A & B'" filter_name="location_a">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <!-- <ng-container *ngSwitchCase="'Unit Non-Recurring Cost'">
                <mi-advanced-search-filter-table-header-v2
                  [title]="column.name"
                  filter_name="unit_non_recurring_cost">
                </mi-advanced-search-filter-table-header-v2>
              </ng-container> -->
                    <ng-container *ngSwitchCase="'Unit Recurring Cost'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name"
                        filter_name="unit_recurring_cost">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <!--<ng-container *ngSwitchCase="'cMRC'">-->
                    <ng-container *ngSwitchCase="'CMRC'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="cmrc">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <!--
              <ng-container *ngSwitchCase="'Division'">
                <mi-advanced-search-filter-table-header-v2
                  [title]="column.name"
                  filter_name="division">
                </mi-advanced-search-filter-table-header-v2>
              </ng-container>
              <ng-container *ngSwitchCase="'Team'">
                <mi-advanced-search-filter-table-header-v2
                  [title]="column.name"
                  filter_name="team">
                </mi-advanced-search-filter-table-header-v2>
              </ng-container>
              -->
                    <ng-container *ngSwitchCase="'Order Date'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="order_date">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Event Date'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="notification_date">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'End of Term'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="end_of_term_date">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Subscriber'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="subscriber_email">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <!--<ng-container *ngSwitchCase="'Service Owner'">-->
                    <ng-container *ngSwitchCase="'Accountable Executive'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name"
                        filter_name="service_owner_email">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Mi-MiSO Owner'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="mi_miso_user_email">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Days to Event'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="days_to_event">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Next Event'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="next_event">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Service Group'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="service_group">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Validation Flag'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="validation_flag">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Days Since Last Update'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name"
                        filter_name="days_since_last_update">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'In-Term'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="in_term">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Liability'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="liability">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Termination Date'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="termination_date">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Activity Alert'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name" filter_name="active_usage">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Price Increase %'">
                      <mi-advanced-search-filter-table-header-v2 [title]="column.name"
                        filter_name="price_increase_percentage">
                      </mi-advanced-search-filter-table-header-v2>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

            </th>
          </ng-container>
          <th class="add-column-th" *ngIf="editableColumns">
            <a (click)="addColumn()" class="add-column-button">+</a>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of _rows" class="{{ (_selectedRow == row)? 'selected' : '' }}" (click)="onRowClick(row)">
          <!-- <span class="fixed-columns"> -->
          <!-- <ng-container *ngFor="let column of _columns.slice(0, numberOfStickyColumn); let i = index">
        <td *ngIf="!column.hidden"     
          ngClass="{{column.className}}"
          class="fixed-columns">
          <ng-template
            [ngTemplateOutlet]="column.cellTemplate"
            [ngTemplateOutletContext]="{row: row, value: getData(row, column.prop), prop: column.prop, columnName: column.name}">
          </ng-template>
        </td>
      </ng-container> -->
          <!-- </span> -->
          <!-- <ng-container *ngFor="let column of _columns.slice(numberOfStickyColumn); let i = index"> -->
          <ng-container *ngFor="let column of _columns; let i = index">
            <td *ngIf="!column.hidden" [ngClass]="{
          'fixed-columns': i===numberOfStickyColumn-1
        }" class="{{column.className}}">
              <ng-template [ngTemplateOutlet]="column.cellTemplate"
                [ngTemplateOutletContext]="{row: row, value: getData(row, column.prop), prop: column.prop, columnName: column.name}">
              </ng-template>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #defaultCellTpl let-row="row" let-value="value">
  {{value}}
</ng-template>

<ng-template #defaultHeaderTpl let-column="column">
  {{column.name}}
</ng-template>

<ng-template #idLinkTpl let-row="row" let-value="value">
  <mi-id-link *ngIf="value" id="{{value}}"></mi-id-link>
  <ng-container *ngIf="!value">{{this.NO_DATA}}</ng-container>
</ng-template>

<ng-template #idLinkTpl2 let-row="row" let-value="value">
  <mi-id-link *ngIf="value" id="{{row.id}}" display_id="{{value}}"></mi-id-link>
  <ng-container *ngIf="!value">{{this.NO_DATA}}</ng-container>
</ng-template>

<ng-template #idLinkTpl2Edit let-row="row" let-value="value">
  <mi-id-link *ngIf="value" id="{{row.id}}" display_id="{{value}}" isEdit="true"></mi-id-link>
  <ng-container *ngIf="!value">{{this.NO_DATA}}</ng-container>
</ng-template>

<ng-template #contactTpl let-row="row" let-value="value">
  <mi-id-link *ngIf="value" display_id="{{value.display_id}}" id="{{value.id}}"></mi-id-link>
  <ng-container *ngIf="value">{{value.first_name}} {{value.last_name}}</ng-container>
  <ng-container *ngIf="!value">{{this.NO_DATA}}</ng-container>
</ng-template>

<ng-template #shortDateTpl let-row="row" let-value="value">
  {{value | date:'shortDate' }}
</ng-template>

<ng-template #shortDateTimeTpl let-row="row" let-value="value">
  {{value | date:'short' }}
</ng-template>

<ng-template #shortISODateTimeTpl let-row="row" let-value="value">
  {{convertTimeZone(value) | date:'short' }}
</ng-template>

<ng-template #trimmedTextTpl let-row="row" let-value="value">
  {{value | limitText}}
</ng-template>

<ng-template #tenantCurrencyTpl let-row="row" let-value="value">
  <span class="overflow-controlled number-template">
    {{ value | tenantcurrency:'1.2-2' }}
  </span>
</ng-template>

<ng-template #serviceCurrencyTpl let-row="row" let-value="value">
  {{ value | currency:(row.miso_service.service_data.currency ? row.miso_service.service_data.currency :
  'USD'):'symbol':'1.2-2' }}
</ng-template>

<ng-template #currencyTpl let-row="row" let-value="value">
  <span class="overflow-controlled number-template">
    {{ value | currency:'USD':'symbol':'1.2-2' }}
  </span>
</ng-template>

<ng-template #roundedCurrencyTpl let-row="row" let-value="value">
  {{ value | round | currency:'USD':'symbol':'1.0' }}
</ng-template>

<ng-template #truncateTpl let-row="row" let-value="value" let-prop="prop">
  <span
    (click)="_columnClicked = (_columnClicked !== (row[rowIdProp] + '#@#' + prop) ? (row[rowIdProp] + '#@#' + prop) : null)"
    [ngClass]="{'overflow-controlled': _columnClicked !== (row[rowIdProp] + '#@#' + prop), 'wrap': _columnClicked == (row[rowIdProp] + '#@#' + prop)}"
    title="{{value}}">
    {{value}}
  </span>
</ng-template>

<ng-template #numberTpl let-row="row" let-value="value">
  <span class="overflow-controlled number-template" title="{{stringToNumber(value)}}" *ngIf="value || value===0">
    {{stringToNumber(value)| number : '1.0-3'}}
  </span>
</ng-template>

<ng-template #locationsTpl let-row="row" let-value="value">
  <span *ngIf="value.id">
    {{value.address.formatted_address}}, {{value.label}}<span *ngIf="value.nick_name">({{value.nick_name}})</span>
  </span>
</ng-template>

<ng-template #customFieldTpl let-row="row" let-value="value" let-columnName="columnName">
  <span *ngIf="value && value.length">
    <!--
    <span *ngIf="!getCFData(columnName, value)">
      <span>
        {{getCfCellValue(columnName, value)}}
      </span>
    </span>

    <span *ngIf="getCFData(columnName, value)">
      <ng-container [ngSwitch]="getCFData(columnName, value).custom_field_type">
        <ng-container *ngSwitchCase="'TEXT'">
          {{getCfCellValue(columnName, value)}}
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span class="overflow-controlled number-template">
            {{getCfCellValue(columnName, value)}}
          </span>
        </ng-container>
      </ng-container>
    </span>
    -->
    <span *ngIf="getCFData(columnName, value)">
      <span>
        {{getCfCellValue(columnName, value)}}
      </span>
    </span>

  </span>
</ng-template>

<ng-template #tagTpl let-row="row" let-value="value" let-columnName="columnName">
  <span *ngIf="value && value.length">
    <span *ngIf="getTagData(columnName, value)">
      <span>
        {{getTagCellValue(columnName, value)}}
      </span>
    </span>
  </span>
</ng-template>

<ng-template #statusTmpl let-row="row" let-value="value">
  {{'GENERAL_STATUSES.' + value | translate}}
</ng-template>

<ng-template #countTmpl let-row="row" let-value="value">
  <span class="overflow-controlled number-template">
    {{value.length}}
  </span>
</ng-template>

<ng-template #fileLink let-row="row" let-value="value">
  <a *ngIf="row.is_v1" href="{{getV1FileUrl(row)}}" target="_blank">{{ shortenFileName(row.file_name) }}</a>
  <a *ngIf="!row.is_v1&&!row.is_url" (click)="downloadDocument(row.id)">{{ shortenFileName(row.file_name) }}</a>
  <a *ngIf="!row.is_v1&&row.is_url" [href]="'//' + row.file_name" target="_blank">{{ shortenFileName(row.file_name)
    }}</a>
</ng-template>

<ng-template #fileType let-row="row" let-value="value">
  {{ 'UPLOAD_DOC_TYPES.' + DOC_TYPES[row.file_type||row.type] | translate }}
</ng-template>
<ng-template #fileSize let-row="row" let-value="value">
  {{ value | filesize }}
</ng-template>