import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddressPayload, BulkIgnoreAddressPayload, GenesisAddressPayload} from 'src/shared/ng-models/Address';

@Injectable()
export class AddressResolutionService {
  private getAddressUrl = "_MICRO_SERVICE_/upload/property/address/resolution/search";
  private getAllAddressUrl = "_MICRO_SERVICE_/upload/property/address/unresolved";
  private getNewAllAddressUrl = "_MICRO_SERVICE_/buquery/resolution/get-address-resolutions";
  private addressUpdate = "_MICRO_SERVICE_/upload/updateServiceAddress";
  private updateAddressUrl = "_MICRO_SERVICE_/upload/property/address/resolution";
  /* private bulkIgnoreAddressUrl = "_MICRO_SERVICE_/upload/property/address/bulk_resolution"; */
  private bulkIgnoreAddressGenesisUrl = "_MICRO_SERVICE_/buquery/resolution/bulk-ignore-resolution";
  

  private addressUpdateGenesis = "_MICRO_SERVICE_/buquery/resolution/update-resolution";



  constructor(private http: HttpClient) { }

  list(tenant_id: string, search: string, pageNumber: number, pageSize: number, statuses: string[]): Observable<any> {
    return this.http.get(`${this.getAddressUrl}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&tenantId=${tenant_id}&statuses=${statuses}`);
  }

  getAllUnresolvedAddress( search: string, pageNumber: number, pageSize: number, is_resolved: boolean = false): Observable<any> {
    return this.http.get(`${this.getAllAddressUrl}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&is_resolved=${is_resolved}`);
  }

  getNewAllUnresolvedAddress(search: string, pageNumber: number, pageSize: number, type: string, tenantId?: string): Observable<any> {
    let url = `${this.getNewAllAddressUrl}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&type=${type}`
    if (tenantId) {
      url = url + `&tenantId=${tenantId}`;
    }

    return this.http.get(url);
  }

  // updateAddress(payload: AddressPayload): Observable<any> {
  //   return this.http
  //     .put(`${this.updateAddressUrl}`, payload)
  //     .map((response: any) => response);
  // }

  updateAddressGenesis(payload: GenesisAddressPayload): Observable<any> {
    return this.http
      .post(`${this.addressUpdateGenesis}`, payload)
      .map((response: any) => response);
  }

  /* bulkIgnoreAddress(payload: any[]): Observable<any> {
    return this.http
      .put(`${this.bulkIgnoreAddressUrl}`, payload)
      .map((response: any) => response);
  } */

  bulkIgnoreAddressGenesis(payload: BulkIgnoreAddressPayload): Observable<any> {
    return this.http
      .post(`${this.bulkIgnoreAddressGenesisUrl}`, payload)
      .map((response: any) => response);
  }  

  addressUpdatesInResolved(payload: AddressPayload): Observable<any> {
    return this.http
      .post(`${this.addressUpdate}`, payload)
      .map((response: any) => response);
  }

}
