import { Component, OnInit } from '@angular/core';
import { Link } from 'src/shared/ng-models/Link.interface';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { ITenantReport } from 'src/libs/api/settings/settings.service';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import { IDropDownOption } from 'src/shared/models/DropdownItem.interface';
import { ToastrService } from 'ngx-toastr';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';
import { DropDownOptions } from 'src/shared/models/Report';
import { select, Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import * as appDataReducer from 'src/shared/state/app-data/reducers';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { debounce } from 'lodash';



interface CategoryOption {
  key: string;
  value: string | string[];
  type: 'recurring' | 'non-recurring';
  billing_type: string[] | string;
  dashboardId: string[]
}

interface Category {
  name: string;
  options: CategoryOption[];
}

enum CategoryName {
  CostsThatIncreased = 'Costs that increased',
  CostsThatDecreased = 'Costs that decreased',
  /* AllCostChanges = 'All cost changes', */
  NoChangesInCost = 'No changes in cost',
  /* MissingInvoices = 'Missing invoices' */
}

enum CategoryOptionKey {
  FromExistingServices = "From existing services",
  /* FromNewServices = "From new services", */
  FromNonRecurringCharges = "From non-recurring charges (NRC)",
  /* FromRemovedServices = "From removed services", */
  Credits = "Credits",
  NewAndRemovedServices = "New and removed services",
  NRCsAndCredits = "NRCs and credits"
}

enum ChargeType {
  IncreaseExisting = 'Increase - Existing Charge',
  IncreaseNew = 'Increase - New Charge',
  DecreaseExisting = 'Decrease - Existing Charge',
  DecreaseRemoved = 'Decrease - Removed Charge',
  NoChanges = 'No Change - Existing Charge',
  MissingInvoice = 'Missing Invoice'
}

enum BillingType {
  Recurring = 'Recurring',
  Discount = 'Discount',
  NRC = 'NRC',
  Credits = 'Credits'
}

enum DashboardId {
  CostsThatIncreasedFromExistingServices = '368',
  /* NewServices = '369', */
  CostsThatDecreasedFromExistingServices = '370',
 /*  CostsThatDecreasedFromRemovedServices = '371', */
  NoChanges = '372',
  MissingInvoice = '373',
}


@Component({
  selector: 'mi-cost-change-analysis',
  templateUrl: './cost-change-analysis.component.html',
  styleUrls: ['./cost-change-analysis.component.scss']
})
export class CostChangeAnalysisComponent extends AbstractPageComponent implements OnInit {
  tenantId: string = null;
  showLoader: boolean = true;
  loadedIframes = 0;

  isIframe1Loaded: boolean = false;
  isIframe1Available: boolean = false;
  isIframe2Loaded: boolean = false;
  isIframe2Available: boolean = false;
  isIframe3Loaded: boolean = false;
  isIframe3Available: boolean = false;
  isIframe4Loaded: boolean = false;
  isIframe4Available: boolean = false;

  missingReportPayload: string[] | string;
  isAnalysisAllowed: boolean = false;

  allMetabaseUrls: string[] = [];


  /* billingTypesPayload : string[] = [] */
  chargeTypesPayload: string[] = [];
  isGetAnalyzeButtonClicked: boolean = false;
  isGetMonthButtonClicked: boolean = false;


  categories: Category[] = [
    {
      name: CategoryName.CostsThatIncreased,
      options: [
        {
          key: CategoryOptionKey.FromExistingServices,
          value: ChargeType.IncreaseExisting,
          type: 'recurring',
          billing_type: BillingType.Recurring,
          dashboardId: [DashboardId.CostsThatIncreasedFromExistingServices]
        },
        /* {
          key: CategoryOptionKey.FromNewServices,
          value: ChargeType.IncreaseNew,
          type: 'recurring',
          billing_type: BillingType.Recurring,
          dashboardId: [DashboardId.NewServices]
        }, */
        {
          key: CategoryOptionKey.FromNonRecurringCharges,
          value: ChargeType.IncreaseExisting,
          type: 'non-recurring',
          billing_type: BillingType.NRC,
          dashboardId: [DashboardId.CostsThatIncreasedFromExistingServices]
        }
      ]
    },
    {
      name: CategoryName.CostsThatDecreased,
      options: [
        {
          key: CategoryOptionKey.FromExistingServices,
          value: ChargeType.DecreaseExisting,
          type: 'recurring',
          billing_type: BillingType.Recurring,
          dashboardId: [DashboardId.CostsThatDecreasedFromExistingServices]
        },
        /* {
          key: CategoryOptionKey.FromRemovedServices,
          value: ChargeType.DecreaseRemoved,
          type: 'recurring',
          billing_type: BillingType.Recurring,
          dashboardId: [DashboardId.CostsThatDecreasedFromRemovedServices]
        }, */
        {
          key: CategoryOptionKey.Credits,
          value: ChargeType.DecreaseExisting,
          type: 'non-recurring',
          billing_type: BillingType.NRC,
          dashboardId: [DashboardId.CostsThatDecreasedFromExistingServices]
        }
      ]
    },
    /* {
      name: CategoryName.AllCostChanges,
      options: [
        {
          key: CategoryOptionKey.FromExistingServices,
          value: [ChargeType.IncreaseExisting, ChargeType.DecreaseExisting],
          type: 'recurring',
          billing_type: BillingType.Recurring,
          dashboardId: [DashboardId.CostsThatIncreasedFromExistingServices, DashboardId.CostsThatDecreasedFromExistingServices]
        },
        {
          key: CategoryOptionKey.NewAndRemovedServices,
          value: [ChargeType.IncreaseNew, ChargeType.DecreaseRemoved],
          type: 'recurring',
          billing_type: BillingType.Recurring,
          dashboardId: [DashboardId.NewServices, DashboardId.CostsThatDecreasedFromRemovedServices]
        },
        {
          key: CategoryOptionKey.NRCsAndCredits,
          value: [ChargeType.IncreaseExisting, ChargeType.DecreaseExisting, ChargeType.IncreaseNew, ChargeType.DecreaseRemoved],
          type: 'non-recurring',
          billing_type: BillingType.NRC,
          dashboardId: [DashboardId.CostsThatIncreasedFromExistingServices, DashboardId.CostsThatDecreasedFromExistingServices, DashboardId.NewServices, DashboardId.CostsThatDecreasedFromRemovedServices]
        }
      ]
    }, */
    { name: CategoryName.NoChangesInCost, options: [] },
   /*  { name: CategoryName.MissingInvoices, options: [] } */
  ];

  selectedBillingTypes: string[] | string;
  selectedChargeType: string;

  selectedCategory: string = '';
  filteredOptions: CategoryOption[] = [];
  selectedOptions: CategoryOption[] = [];

  breadCrumbs: Link[] = [
    { label: 'Dashboard', url: '/', internal: true },
    { label: 'Cost Change Analysis', url: '/cost-change-analysis', internal: true }
  ];

  invoiceOptions: any = [];
  isInVoicesLoading: boolean = false;
  selectedInvoice: DropDownOptions;
  selectedInvoiceLabel: string;

  vendorsOptions: any = [];
  isVendorsLoading: boolean = false;
  selectedVendor: DropDownOptions;
  selectedVendorLabel: string;

  selectedVendorAccountsOptions: any;
  selectedVendorAccount: DropDownOptions;
  selectedVendorAccountLabel: string;
  isVendorAccountsLoading: boolean = false;
  showMetabaseUi: boolean = false;
  monthOptions = this.generateMonthYearOptions();
  billingMonthOptions: IDropDownOption[] = [];
  isMonthOptionsLoading: boolean = false;
  isMonthOptionsLoaded: boolean = false;
  selectedBillingMonthOption: string = undefined;
  selectedPreviousBillingMonthOptions: string;
  frontEndSettings: ITenantReport[] = FrontEndSettingsUtil.get().TENANT_REPORTS || [];
  headerLinks: Link[] = [];
  isStep1Clear: boolean = false;
  isStep2Clear: boolean = false;


  constructor(
    private reportV2Service: ReportV2Service,
    private toastr: ToastrService,
    private tenantVendorService: TenantVendors,
    private store: Store<any>,
  ) { super(); }

  ngOnInit(): void {
    this.store.pipe(
      select(appDataReducer.getAppData),
      takeUntil(this.destroy$),
      map((appData) => {
        this.tenantId = appData.tenantData.domain;
      })
    ).subscribe();
  }

  onBillingMonthChangesHandler(selectedOption: string): void {
    this.selectedBillingMonthOption = this.billingMonthOptions.find(billingMonthOption => billingMonthOption.value === selectedOption).value;
    this.calculatePreviousMonth();
    this.isStep2Clear = true;
  }

  calculatePreviousMonth(): void {
    const [year, month] = this.selectedBillingMonthOption.split(' ');
    const inputDate = new Date(Number(year), Number(month) - 1);
    inputDate.setMonth(inputDate.getMonth() - 1);
    const previousYear = inputDate.getFullYear();
    const previousMonthNumber = String(inputDate.getMonth() + 1).padStart(2, '0');
    const previousMonthName = inputDate.toLocaleString('default', { month: 'short' });
    this.selectedPreviousBillingMonthOptions = `${previousYear} ${previousMonthNumber} (${previousMonthName})`;
  }



  getAvailableMonths = debounce(() => {
    this.isMonthOptionsLoading = true;
    this.isMonthOptionsLoaded = false;

    this.tenantVendorService
      .getCcaSummaryReportRecentMonthServices(
        this.tenantId,
        this.selectedBillingTypes,
        [...new Set(this.chargeTypesPayload)]
      )
      .toPromise()
      .then(response => {
        this.isMonthOptionsLoading = false;
        this.isMonthOptionsLoaded = true;
        this.isGetMonthButtonClicked = false;

        this.billingMonthOptions = response.map(item => ({
          label: item,
          value: item
        })).reverse(); // Reverse the order of options
      })
      .catch(() => {
        this.isMonthOptionsLoading = false;
        this.isMonthOptionsLoaded = true;
        this.isGetMonthButtonClicked = false;
        
        this.toastr.error('Facing issue while fetching months');
      });
   
  }, 500); // Adjust debounce time as needed (300ms here)




  fetchTenantVendors(): void {
    this.isVendorsLoading = true;
    this.tenantVendorService
      .getCcaSummaryReportVendorsServices(
        this.tenantId,
        this.selectedBillingMonthOption || '',
        this.selectedBillingTypes,
        [...new Set(this.chargeTypesPayload)]
      )
      .toPromise()
      .then(response => {
        this.isVendorsLoading = true;
        if (!response || !Array.isArray(response)) {
          throw new Error('Invalid response format');
        }

        const transformedData = response.map(item => ({
          label: item,
          value: item
        }));

        this.vendorsOptions = transformedData.sort((a, b) => a.label.localeCompare(b.label));
        this.isVendorsLoading = false;
      })
      .catch(error => {
        console.error('Error fetching vendors:', error);
        this.toastr.error('Facing issue while fetching vendors');
        this.isVendorsLoading = false;
      });
  }

  getVendorAccounts(): void {
    this.isVendorAccountsLoading = true;
    this.tenantVendorService.getCcaSummaryReportAccountsServices(
      this.tenantId,
      this.selectedVendorLabel,
      this.selectedBillingMonthOption,
      this.selectedBillingTypes,
      [...new Set(this.chargeTypesPayload)]
    )
      .toPromise()
      .then(response => {
        this.selectedVendorAccountsOptions = response.map(item => ({
          label: item,
          value: item
        }));
        this.isVendorAccountsLoading = false;
      })
      .catch(() => {
        this.toastr.error('Facing issue while fetching vendors');
      });
  }

  fetchTenantInvoice(): void {
    this.isInVoicesLoading = true;
    this.tenantVendorService
      .getCcaSummaryReportInvoicesServices(
        this.tenantId,
        this.selectedBillingMonthOption,
        this.selectedVendorLabel,
        this.selectedVendorAccountLabel,
        this.selectedBillingTypes,
        [...new Set(this.chargeTypesPayload)]
      )
      .toPromise()
      .then(response => {
        this.isInVoicesLoading = false;
        this.invoiceOptions = response.map(item => ({
          label: item,
          value: item
        }));
      })
      .catch(() => {
        this.toastr.error('Facing issue while fetching Invoices');
      });
  }

  generateMonthYearOptions(): { key: string; value: string }[] {
    const options = [];
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
    while (startDate <= endDate) {
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, '0');
      const monthName = startDate.toLocaleString('default', { month: 'long' });
      options.push({ key: `${monthName} ${year}`, value: `${year}-${month}` });
      startDate.setMonth(startDate.getMonth() + 1);
    }
    return options;
  }

  onVendorChangeHandler(selectedValue: any): void {
    if (selectedValue !== undefined) {
      this.selectedVendor = selectedValue;
      this.selectedVendorLabel = this.selectedVendor?.label || null;
    } else {
      this.selectedVendor = null;
      this.selectedVendorLabel = null;
    }

    this.selectedVendorAccountsOptions = [];
    this.selectedVendorAccount = null;
    this.selectedVendorAccountLabel = null;
    this.invoiceOptions = [];
    this.selectedInvoice = null;
    this.selectedInvoiceLabel = null;
    this.getAnalyzeReport();
  }


  onAccountChangeHandler(selectedValue: any): void {
    if (selectedValue !== undefined) {
      this.selectedVendorAccount = selectedValue;
      this.selectedVendorAccountLabel = this.selectedVendorAccount.label;
    } else {
      this.selectedVendorAccount = null;
      this.selectedVendorAccountLabel = null;
    }
    this.getAnalyzeReport();
  }


  onInvoiceChangeHandler(selectedValue: any): void {
    if (selectedValue !== undefined) {
      this.selectedInvoice = selectedValue;
      this.selectedInvoiceLabel = this.selectedInvoice.label;
    } else {
      this.selectedInvoice = null;
      this.selectedInvoiceLabel = null;
    }
    this.getAnalyzeReport();
  }


  onCategoryClick(categoryName: string): void {
    this.chargeTypesPayload = [];
    this.selectedCategory = categoryName;
    this.selectedOptions = [];
    this.updateFilteredOptions();
  }

  restStep2Handler() {
    this.selectedBillingMonthOption = undefined;
    this.billingMonthOptions = [];
    this.isMonthOptionsLoaded = this.isMonthOptionsLoading = this.isStep2Clear = false;
  }


  updateFilteredOptions(): void {
    if (!this.selectedCategory) {
      this.filteredOptions = [];
      return;
    }

    const selectedCategoryObj = this.categories.find(cat => cat.name === this.selectedCategory);

    if (selectedCategoryObj?.options?.length) {
      this.filteredOptions = selectedCategoryObj.options;
      this.isStep1Clear = false;
      this.isStep2Clear = false;
      this.billingMonthOptions = [];
      this.selectedBillingMonthOption = undefined;
    } else {
      this.filteredOptions = [];
      this.isStep1Clear = true;
    }

    this.restStep2Handler();
  }


  isOptionSelected(option: CategoryOption): boolean {
    return this.selectedOptions.some(selectedOption => selectedOption.key === option.key);
  }

  hasRecurringSelected(): boolean {
    return this.selectedOptions.some(option => option.type === 'recurring');
  }

  hasNonRecurringSelected(): boolean {
    return this.selectedOptions.some(option => option.type === 'non-recurring');
  }

  isOptionDisabled(option: CategoryOption): boolean {
    return (this.hasRecurringSelected() && option.type === 'non-recurring') ||
      (this.hasNonRecurringSelected() && option.type === 'recurring');
  }

  onOptionToggle(option: CategoryOption): void {
    if (this.isOptionSelected(option)) {
      this.selectedOptions = this.selectedOptions.filter(selectedOption => selectedOption.key !== option.key);
    } else {
      if (option.type === 'recurring' && this.hasNonRecurringSelected()) {
        this.selectedOptions = [];
      } else if (option.type === 'non-recurring' && this.hasRecurringSelected()) {
        this.selectedOptions = [];
      }
      this.selectedOptions.push(option);
    }

    this.chargeTypesPayload = [];
    this.isStep1Clear = this.selectedOptions.length > 0;
    this.restStep2Handler();
  }

  getAccountTagPlaceholderText(): string {
    if (!this.selectedVendor) {
      return 'Please select a vendor to view available accounts.';
    }

    return this.isVendorAccountsLoading
      ? `Loading accounts for ${this.selectedVendor.label}... Please wait.`
      : 'Search or select an account';
  }



  transformData(inputArray) {
    let result = [];

    inputArray.forEach(item => {
      const minLength = Math.min(item.value.length, item.dashboardId.length);

      for (let i = 0; i < minLength; i++) {
        result.push({
          key: item.key,
          value: item.value[i], // Pick one value
          type: item.type,
          billing_type: item.billing_type, // Keep billing_type as an array
          dashboardId: [item.dashboardId[i]] // Pick one dashboardId
        });
      }
    });

    return result;
  }

  getMonthsWithRespectToSelectedOptions() {
    this.isGetMonthButtonClicked = true;
    this.isGetAnalyzeButtonClicked = false;
    this.analyzeReportHandler();
  }

  getAnalyzeReport() {
    this.isGetMonthButtonClicked = false;
    this.isGetAnalyzeButtonClicked = true;
    this.fetchTenantVendors();
    this.allMetabaseUrls = [];
    this.analyzeReportHandler();
  }

  analyzeReportHandler() {
    const basePayload = [
      { key: "tenant_id", value: this.tenantId },
      { key: "bill_from", value: this.selectedPreviousBillingMonthOptions },
      { key: "bill_to", value: this.selectedBillingMonthOption },
      { key: "type", value: "pop" },
      { key: "vendor", value: this.selectedVendor?.label },
      { key: "account_id", value: this.selectedVendorAccount?.label },
      { key: "bill_to_invoice", value: this.selectedInvoice?.label },
    ];

    if (this.selectedCategory === CategoryName.CostsThatIncreased ||
      this.selectedCategory === CategoryName.CostsThatDecreased 
      
     /*  ||
      this.selectedCategory === CategoryName.AllCostChanges */
    
    ) {

     /*  if (this.selectedCategory === CategoryName.AllCostChanges) { 
       
        let selectedOptions = this.transformData(this.selectedOptions);
        selectedOptions.forEach(selectedOptions => {
          this.processSelectedOptions([selectedOptions], basePayload);
        });
      }  */
      
      if (this.selectedCategory === CategoryName.CostsThatIncreased ||
      this.selectedCategory === CategoryName.CostsThatDecreased 
        )  { // If CategoryName.CostsThatIncreased or CategoryName.CostsThatDecreased
        this.processSelectedOptions(this.selectedOptions, basePayload);
      }
    } else { // CategoryName.NoChangesInCost Or CategoryName.MissingInvoices
      if (this.selectedCategory === CategoryName.NoChangesInCost) {
        this.selectedBillingTypes = 'Recurring';
        this.selectedChargeType = ChargeType.NoChanges;
        const payload = [...basePayload,
        { key: "billing_type", value: this.selectedBillingTypes },
        { key: "change_type", value: this.selectedChargeType }
        ];

        if (this.isGetMonthButtonClicked) {
          this.chargeTypesPayload.push(this.selectedChargeType);
          this.getAvailableMonths();
        } else if (this.isGetAnalyzeButtonClicked) {
          this.getMetabaseDetails(payload, DashboardId.NoChanges);
        }
      }

     /*  if (this.selectedCategory === CategoryName.MissingInvoices) {
        this.selectedBillingTypes = 'Recurring';
        this.selectedChargeType = ChargeType.MissingInvoice;
        if (this.isGetMonthButtonClicked) {
          this.chargeTypesPayload.push(this.selectedChargeType);
          this.getAvailableMonths();
        } else if (this.isGetAnalyzeButtonClicked) {
          const payload = [...basePayload,
          { key: "billing_type", value: this.selectedBillingTypes },
          { key: "change_type", value: this.selectedChargeType }
          ];
          this.getMetabaseDetails(payload, DashboardId.MissingInvoice);
        }

      } */
    }
  }

  processSelectedOptions(selectedOptions, basePayload) {
    selectedOptions.slice(0, 2).forEach((selectedOption, selectedOptionIndex) => {

      if (Array.isArray(selectedOption.value) && selectedOption.value.length > 1) {
        selectedOption.value.forEach((changeType, index) => {
          this.selectedBillingTypes = selectedOption.billing_type;
          this.selectedChargeType = changeType;

          /*  from non recurring charges NRC */
          if (this.isGetMonthButtonClicked) {
            this.chargeTypesPayload.push(this.selectedChargeType);
            this.getAvailableMonths();
          } else if (this.isGetAnalyzeButtonClicked) {
            const payload = [...basePayload,
            { key: "billing_type", value: this.selectedBillingTypes },
            { key: "change_type", value: this.selectedChargeType }
            ];

            this.getMetabaseDetails(payload, selectedOption.dashboardId[index]);
          }

        });
      } else { /* From existing services , from new services */

        this.selectedBillingTypes = selectedOption.billing_type;
        this.selectedChargeType = selectedOption.value;
        if (this.isGetMonthButtonClicked) {
          this.chargeTypesPayload.push(this.selectedChargeType);

          this.getAvailableMonths();
        } else if (this.isGetAnalyzeButtonClicked) {
          const payload = [...basePayload,
          { key: "billing_type", value: this.selectedBillingTypes },
          { key: "change_type", value: this.selectedChargeType }
          ];
          this.getMetabaseDetails(payload, selectedOption.dashboardId[0]);
        }
      }
    });
  }

  getMetabaseDetails(payload, metaBaseDashboardId) {
    this.showMetabaseUi = true;
    this.showLoader = true;

    this.reportV2Service.getAnalysisReportDetails(metaBaseDashboardId, payload)
      .then((res: string) => {
        this.allMetabaseUrls.push(res);
        this.loadIframes();
      })
      .catch((error) => {
        console.error("Error fetching Metabase details:", error);
        this.showLoader = false;
      });
  }

  loadIframes() {
    const iframeElements = [
      { element: document.getElementById("metabase-iframe-1") as HTMLIFrameElement, property: "isIframe1Loaded", availabilityProperty: "isIframe1Available" },
      { element: document.getElementById("metabase-iframe-2") as HTMLIFrameElement, property: "isIframe2Loaded", availabilityProperty: "isIframe2Available" },
      { element: document.getElementById("metabase-iframe-3") as HTMLIFrameElement, property: "isIframe3Loaded", availabilityProperty: "isIframe3Available" },
      { element: document.getElementById("metabase-iframe-4") as HTMLIFrameElement, property: "isIframe4Loaded", availabilityProperty: "isIframe4Available" }
    ]
      .slice(0, this.allMetabaseUrls.length) // Load only available URLs
      .filter(iframe => iframe.element); // Remove null elements

    let loadedCount = 0;
    const totalIframes = iframeElements.length;

    iframeElements.forEach(({ element, property, availabilityProperty }, index) => {
      let cleanUrl = this.allMetabaseUrls[index].split('#')[0]; // Remove any existing fragment

      try {
        const url = new URL(cleanUrl);
        // Set required query parameters
        url.searchParams.set("bordered", "false");
        url.searchParams.set("titled", "false");
        element.setAttribute('src', url.toString()); // Assign modified URL
      } catch (error) {
        console.error("Invalid URL:", cleanUrl, error);
      }

      element.addEventListener('load', () => {
        this.onIframeLoad(property, ++loadedCount, totalIframes);
      });

      // Update iframe availability based on the number of URLs
      this[availabilityProperty] = this.allMetabaseUrls.length > index;
    });

    if (totalIframes === 0) {
      this.showLoader = false;
    }
  }

  onIframeLoad(property: string, loadedCount: number, totalIframes: number) {
    this[property] = true;
    if (loadedCount === totalIframes) {
      this.showLoader = false; // Hide loader only after all iframes are fully loaded
    }
  }


  resetToDefault() {
    const iframeCount = 4;
    for (let i = 1; i <= iframeCount; i++) {
      this[`isIframe${i}Loaded`] = false;
      this[`isIframe${i}Available`] = false;
    }

    this.isGetMonthButtonClicked = false;
    this.isGetAnalyzeButtonClicked = false;

    this.allMetabaseUrls = this.vendorsOptions = this.selectedVendorAccountsOptions = this.invoiceOptions = [];
    this.selectedVendor = this.selectedVendorLabel = this.selectedVendorAccount = this.selectedVendorAccountLabel = this.selectedInvoice = this.selectedInvoiceLabel = null;
  }

  backToCompanion() {
    this.showMetabaseUi = !this.showMetabaseUi;
    this.resetToDefault();

    this.showLoader = true;
    this.isIframe2Loaded = this.isIframe1Loaded = this.isIframe3Loaded = this.isIframe1Loaded = false;
    this.isIframe1Available = this.isIframe2Available = this.isIframe3Available = this.isIframe4Available = false;

    if (!this.showMetabaseUi) {
      ["metabase-iframe-1", "metabase-iframe-2", "metabase-iframe-3", "metabase-iframe-4"].forEach(id => {
        const iframe = document.getElementById(id) as HTMLIFrameElement;
        if (iframe) iframe.src = "";
      });
    }

  }


  formatBillingMonth(dateString: string): string {
    if (!dateString) return '';
    // Extract year and month from "YYYY MM (Month)" format
    const parts = dateString.split(" ");
    if (parts.length < 3) return dateString; // Return as-is if format is unexpected
    const year = parts[0]; // First part is year
    const month = parts[2].replace("(", "").replace(")", ""); // Third part is month (removing parentheses)
    return `${month} ${year}`; // Format as "Month YYYY"
  }

}