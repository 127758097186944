import {_deepClone} from '../utils/ObjectUtils';

let isTestEnvironment = false;

const ACCOUNT_USER = 'ACCOUNT_USER';
const ACCOUNT_ADMIN = 'ACCOUNT_ADMIN';
const ACCOUNT_HOLDER = 'ACCOUNT_HOLDER';
const NO_ACCESS_USER = 'NO_ACCESS_USER';

const userRoles = [NO_ACCESS_USER, ACCOUNT_USER, ACCOUNT_ADMIN, ACCOUNT_HOLDER];
const userRoleLevels = {};

/*
userRoles.forEach(function (item, index) {
  userRoleLevels[item] = index - 1;
});
*/
userRoleLevels[NO_ACCESS_USER] = 999;
userRoleLevels[ACCOUNT_USER] = 7;
userRoleLevels[ACCOUNT_ADMIN] = 6;
userRoleLevels[ACCOUNT_HOLDER] = 5;

const MISO_ADMIN = 'MISO_ADMIN';

const AUTH = {
  LOGIN_OPTIONS: {},
  EMAIL_LINK_OPTIONS: {},
  USER: userRoles,
  USER_LEVELS: userRoleLevels,
  ACCOUNT_USER: ACCOUNT_USER,
  ACCOUNT_ADMIN: ACCOUNT_ADMIN,
  ACCOUNT_HOLDER: ACCOUNT_HOLDER,
  NO_ACCESS_USER: NO_ACCESS_USER
};

/**
 * Sets as test environment to run some postman tests correctly.
 * @returns null
 */
function setAsTestEnvironment() {
  isTestEnvironment = true;
}

/**
 * Another type of constructor to get around typescript construction of generics
 * @param userData {object} mongoose UserSchema data
 * @returns {UserSecurity}
 */
function createSecureUser(userData) {
  return new UserSecurity(userData);
}

/**
 * The class which helps verify authenticated users and their access.
 * @class SecureUser
 * @param userData {object} mongoose UserSchema data
 * @example
 * import User  from '../../app/models/user.model';
 * import {AUTH} from "../config/constants";
 *
 * var secureUser = User.getSecureUser(12345);
 * console.log(secureUser.isSuperUser());
 * // output > true
 * console.log(secureUser.hasTenantAccess('generic-tenant', AUTH.ACCOUNT_HOLDER));
 * // output > true
 */
class UserSecurity implements ISecureUser{

  public _rawData: any;
  private userData;

  constructor(inputData: any = {}) {
    this.userData = {
      ..._deepClone(inputData),
      UserTenantProfile: inputData.UserTenantProfile || [],
      microTenants: inputData.microTenants || []
    };
    this._rawData = this.userData;
  }

  /**
   * Check if the user is a super user.
   * @returns {boolean} is the user a super user.
   */
  isSuperUser() {
    //console.log("isSuperUser", this.userData);
    if(this.isV2() && this.userData.role) {
      return true;
    }
    return this.userData.isSuperUser || false;
  }


  /**
   * Check if hte user is activated.
   * @returns {boolean}
   */
  isV2(): boolean {
    return this.userData.isV2 || false;
  }

  /**
   * Check if hte user is activated.
   * @returns {boolean}
   */
  isActivated() {
    if(this.isV2()) {
      return true;
    }
    return this.userData.activated;
  }

  canCreateTenants() {
    this.userData.isSuperUser;
  }

  /**
   * Check if hte user has a password reset token.
   * @returns {string}
   */
  getResetPasswordToken() {
    return this.userData.reset_password_token || null;
  }

  /**
   * Check if this user has the specified security level on a certain domain
   * @param tenant {number} tenant domain prefix
   * @param securityLevel {string} one of the AUTH.User types
   * @returns {boolean}
   * @example
   * secureUser.hasTenantAccess('generic-tenant', AUTH.ACCOUNT_HOLDER
   */
  hasTenantAccess(tenant, securityLevel) {
    //console.log("hasTenantAccess AUTH.USER_LEVELS", AUTH.USER_LEVELS);
    //console.log("hasTenantAccess securityLevel", securityLevel);
    let canAccess = false;

    if (!tenant || !securityLevel) {
      throw new Error('securityLevel must be set in UserSecurity.canAccess');
    }

    // log('UserTenantProfile? %I', userData.UserTenantProfile);

    if (this.isSuperUser()) {
      canAccess = true;
    }
    else {
      //canAccess = this.tenantAccessLevel(tenant) >= AUTH.USER_LEVELS[securityLevel];
      canAccess = this.tenantAccessLevel(tenant) <= AUTH.USER_LEVELS[securityLevel];
      //console.log("hasTenantAccess this.tenantAccessLevel(tenant)", this.tenantAccessLevel(tenant));
      //console.log("hasTenantAccess canAccess", canAccess);
    }

    return canAccess;
  }

  /**
   * Outputs a number correlating to this users access level. Helps compare access levels
   * @param tenant {string} tenant domain prefix
   * @returns {number}
   * @example
   * secureUser.tenantAccessLevel('generic-tenant') >= secondUser.tenantAccessLevel('generic-tenant');
   */
  tenantAccessLevel(tenant) {
    //console.log("tenantAccessLevel AUTH.USER_LEVELS[this.tenantAccessRole(tenant)]", AUTH.USER_LEVELS[this.tenantAccessRole(tenant)]);
    return AUTH.USER_LEVELS[this.tenantAccessRole(tenant)];
  }

  /**
   * Retrieve which AUTH.role this user has for this tenant
   * @param tenant {string] tenant domain
   * @returns {string} the AUTH.role of that user for that tenant
   * @example
   * console.log(secureUser.tenantAccessRole('generic');
   * // output > 'ACCOUNT_ADMIN'
   * console.log(secureUser.tenantAccessRole('generic2');
   * // output > 'NO_ACCESS_USER'
   */
  tenantAccessRole(tenant) {
    if (!tenant) {
      throw new Error('tenant must be set in UserSecurity.tenantAccessRole');
    }

    let role = AUTH.NO_ACCESS_USER;

    if (this.isSuperUser()) {
      role = AUTH.ACCOUNT_HOLDER;
    } else if(this.isV2()) {
      const matchingNewTenant = this.userData.microTenants.find(microTenant => microTenant.id === tenant || microTenant.domain.toLowerCase() === tenant.toLowerCase());
      
      if(matchingNewTenant) {
        //console.log("secureUser matchingNewTenant", matchingNewTenant);
        //role = AUTH.ACCOUNT_HOLDER;
        let roleNumber = matchingNewTenant.role;

        switch(roleNumber) {
          case 999: role = AUTH.NO_ACCESS_USER; break;
          case 7: role = AUTH.ACCOUNT_USER; break;
          case 6: role = AUTH.ACCOUNT_ADMIN; break;
          case 5: role = AUTH.ACCOUNT_HOLDER; break;
        }
      }

    } else {

      const matchingTenant = this.userData.UserTenantProfile.find(userTenantProfile => userTenantProfile.tenant === tenant);
      if (matchingTenant) {
        role = matchingTenant.role;
      }
    }
    //console.log("secureUser return role", role);
    return role;
  }

  hasTermsAndConditionsAccepted(tenant) {
    if (!tenant) {
      throw new Error('tenant must be set in UserSecurity.tenantAccessRole');
    }

    if (this.isSuperUser()) {
      return true;
    } else {  /* If user is not Super admin or Admin or normal user */
     
      const matchingNewTenant = this.userData.microTenants.find(microTenant => microTenant.id === tenant || microTenant.domain.toLowerCase() === tenant.toLowerCase());
      
      if(matchingNewTenant) {
        return !!matchingNewTenant.terms_and_conditions_accepted_on_utc;
      }

    }

    return false;
  }

  /**
   * Get all tenants that this user has access to at a certain AUTH.ROLE level
   * @param role {string} Any of the AUTH.role constants
   * @returns {Array} an array of tenant domains with that level of access
   * @example
   * console.log(secureUser.getTenantNamesByRole(AUTH.ACCOUNT_USER));
   * // output > ['generic-tenant', 'another-tenant'];
   */

  getTenantNamesByRole(role = AUTH.NO_ACCESS_USER) {
    return this.getTenantProfilesByRole(role).map(profile => profile.tenant);
  }

  /**
   * Get all tenants that this user has access to at a certain AUTH.ROLE level
   * @param role {string} Any of the AUTH.role constants
   * @returns {Array} an array of tenant domains with that level of access
   * @example
   * console.log(secureUser.getTenantProfilesByRole(AUTH.ACCOUNT_USER));
   * // output > [{}];
   */

  getTenantProfilesByRole(role = AUTH.NO_ACCESS_USER) {

    if (role === AUTH.NO_ACCESS_USER) {
      throw new Error('role cannot be NO_ACCESS_USER in UserSecurity.getTenantProfilesByRole');
    }
    else if (this.userData.isSuperUser) {
      throw new Error('can not query a super admin with UserSecurity.getTenantProfilesByRole');
    }

    const requestedLevel = AUTH.USER_LEVELS[role];
    const profilesWithRole = this.userData.UserTenantProfile.filter((profile) => {
      return this.tenantAccessLevel(profile.tenant) >= requestedLevel;
    });

    return profilesWithRole;
  }

  /**
   * Decides if one user can edit another user
   * @param otherUser {SecureUser} the other user this user wishes to edit
   * @param tenant {string} thenant domain
   * @returns {boolean}
   * @example
   * secureUser1.canEditUser(secondUser, 'generic');
   */
  canEditUser(otherUser: UserSecurity, tenant) {
    let canEdit = false;
    if (otherUser.isSuperUser()) {
      //only a super user can edit another super user.
      canEdit = this.isSuperUser();
    }
    else if (tenant) {
      //console.log("canEditUser this.tenantAccessLevel(tenant)", this.tenantAccessLevel(tenant));
      //console.log("canEditUser otherUser.tenantAccessLevel(tenant)", otherUser.tenantAccessLevel(tenant));
      //log('testing: %i, %i', tenant, AUTH.ACCOUNT_ADMIN);
      //canEdit = this.hasTenantAccess(tenant, AUTH.ACCOUNT_ADMIN) && this.tenantAccessLevel(tenant) >= otherUser.tenantAccessLevel(tenant);
      canEdit = this.hasTenantAccess(tenant, AUTH.ACCOUNT_ADMIN) && this.tenantAccessLevel(tenant) <= otherUser.tenantAccessLevel(tenant);
    }
    return canEdit;
  }

  /**
   * Gets a users color
   * @returns {string} hex number of the color
   */
  getColor() {
    return this.userData.color || '7fc3af';
  }

  viewed_shortcut_tutorial() {
    if(this.isV2()) {
      return true;
    }
    return this.userData.viewed_shortcut_tutorial;
  }

  getPrivacyVersion(): string {
    if(this.isV2()) {
      return this.userData.agreement_version;
    }
    return '';
  }

  getHasAcceptedOrRejectedTFA(): boolean {
    return this.userData.has_accepted_or_rejected_tfa;
  }

  getUserId(): string {
    return this.userData.id;
  }

  getUserEmail(): string {
    return this.userData.email;
  }

  getUserMobile(): string {
    return this.userData.mobile_phone;
  }

  getFullName(): string {
    return this.userData.first_name + ' ' + this.userData.last_name;
  }

  getAccessibleBUIds(): string[] {
    const accessibleBUs = this.userData.microTenants.filter(bu => bu.role < 999);
    const onlyIds = accessibleBUs.map(bu=>bu.id);
    return onlyIds;
  }

  isAllAccessUserOfTenant(tenant): boolean {
    const accessibleBUs = this.userData.microTenants.filter(bu => bu.role < 999);
    const isAllAccessUser = accessibleBUs.find(bu => bu.id === tenant);
    return !!isAllAccessUser;
  }

  shouldSeePricing(tenant): boolean {
    if(this.isSuperUser()) {
      return true;
    } else if (this.isAllAccessUserOfTenant(tenant)) {
      return true;
    }

    return false;
  }

  /**
   * Gets data which is safe to return from ajax
   * @returns {Object} object of the safe data to return
   */
  getSafeData() {
    const safeData = [
      '_id',
      'last_name',
      'first_name',
      'email',
      'isSuperUser',
      'activated',
      'color',
      'tfa_enabled',
      'has_accepted_or_rejected_tfa',
      'UserTenantProfile',
      'safe_phone_number',
      'viewed_shortcut_tutorial',
      'isV2',
      'agreement_version'
    ];

    if (isTestEnvironment) {
      safeData.push('activation_token');
    }

    return safeData.reduce((map, key) => {
      map[key] = this.userData[key];
      return map;
    }, {});
  }

  getIdentifyData() {
    const identifyData = [
      "agreement_version",
      "created_date",
      "email",
      "first_name",
      "id",
      "last_name",
      "mi_miso_owner_id",
      "mi_miso_role",
      "mobile_phone",
      "office_phone",
      "role"
    ];

    return identifyData.reduce((map, key) => {
      map[key] = this.userData[key];
      return map;
    }, {});
  }
}


interface ISecureUser {
  _rawData: any;
  isSuperUser(): boolean;
  isActivated(): boolean;
  hasTenantAccess(tenant: string, securityLevel: string): boolean;
  tenantAccessLevel(tenant: string): number;
  tenantAccessRole(tenant: string): string;
  hasTermsAndConditionsAccepted(tenant: string): boolean;
  getTenantNamesByRole(role: string): boolean;
  getTenantProfilesByRole(rol: string): boolean;
  viewed_shortcut_tutorial(): boolean;
  getSafeData(): any;
  getIdentifyData(): any;
  canEditUser(otherUser: ISecureUser, tenant: string): boolean;
  isV2(): boolean;
  getPrivacyVersion(): string;
  getHasAcceptedOrRejectedTFA(): boolean;
  getUserId(): string;
  getFullName(): string;
  getUserEmail(): string;
  getUserMobile(): string;
  getAccessibleBUIds(): string[];
  isAllAccessUserOfTenant(tenant: string): boolean;
  shouldSeePricing(tenant: string): boolean;
}

export {
  UserSecurity,
  createSecureUser,
  ISecureUser,
  AUTH,
  MISO_ADMIN,
  setAsTestEnvironment
};
