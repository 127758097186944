import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { ServiceV2Response, ServiceV2ResponseData, tenantBridgeCustomFieldsResponse, tenantBridgesResponse } from "src/shared/models/ServiceV2";
import { SectionDataTableColumn } from "src/shared/ng-models/SectionDataColumn.interface";
import { NO_DATA } from "src/shared/ng-models/SectionDataTable.model";
import { SortableTemplatesEnum } from "src/shared/ng-models/SortableTableColumn.interface";
import { NOTIFICATION_SEVERITY } from "src/shared/utils/notificationSeverity";
import * as ServiceV2Reducer from '../../../../shared/state/service-v2/reducers';
import { CustomFieldsV2Service } from "src/libs/api2/service-v2/custom-fields-v2.service";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import { TagsService } from "src/libs/api2/tags/tags.service";
import { TagDefinition } from "src/shared/models/Tags";

@Component({
  selector: 'mi-advanced-search-service-results-table-v2',
  templateUrl: './advanced-search-service-results-table-v2.component.html',
  styleUrls: ['./advanced-search-service-results-table-v2.component.scss']
})

export class AdvancedSearchServiceResultsTableV2Component {
  hits$: ServiceV2ResponseData[];
  serviceColumns: SectionDataTableColumn[] = [];
  tempServiceColumns: SectionDataTableColumn[] = [];

  @ViewChild('mrcTmpl', {static: true}) mrcTmpl: TemplateRef<any>;
  @ViewChild('nextEventTmpl', {static: true}) nextEventTmpl: TemplateRef<any>;
  @ViewChild('serviceStatusTmpl', {static: true}) serviceStatusTmpl: TemplateRef<any>;
  @ViewChild('orderTmpl', {static: true}) orderTmpl: TemplateRef<any>;
  @ViewChild('financialTmpl', {static: true}) financialTmpl: TemplateRef<any>;
  @ViewChild('buTmpl', {static: true}) buTmpl: TemplateRef<any>;
  @ViewChild('customFieldHeaderTpl', {static: true}) customFieldHeaderTpl: TemplateRef<any>;
  @ViewChild('tagHeaderTpl', {static: true}) tagHeaderTpl: TemplateRef<any>;
  @ViewChild('divisionTmpl', {static: true}) divisionTmpl: TemplateRef<any>;
  @ViewChild('teamTmpl', {static: true}) teamTmpl: TemplateRef<any>;
  @ViewChild('groupTmpl', {static: true}) groupTmpl: TemplateRef<any>;
  @ViewChild('parentNameTmpl', {static: true}) parentNameTmpl: TemplateRef<any>;
  @ViewChild('validationFlagTmpl', {static: true}) validationFlagTmpl: TemplateRef<any>;
  @ViewChild('inTermTpl', {static: true}) inTermTpl: TemplateRef<any>;
  @ViewChild('dataIntegrityTpl', {static: true}) dataIntegrityTpl: TemplateRef<any>;

  error: any = '';
  tenantId: string = "";
  allCustomFields: tenantBridgeCustomFieldsResponse[] = [];
  allTags: TagDefinition[] = [];
  customFieldById: any = {};
  tagById: any = {};
  noCustomField: boolean = false;
  @Input() serviceData: ServiceV2Response;
  parentGroupName: any[];

  readonly NO_DATA: string = NO_DATA;
  readonly ARCHIVED: string = NOTIFICATION_SEVERITY.ARCHIVED;

  constructor(
    private store: Store<ServiceV2Reducer.State>,
    private customFieldsV2Service: CustomFieldsV2Service,
    private tagsService: TagsService
  ) {}

  ngOnInit(): void {
    //this.store.dispatch(new ServiceV2Actions.SetSearchLoading(this.serviceData));
    this.tenantId = FrontEndSettingsUtil.getDomain();
    this.getTags();
    //this.getCustomFields();
    //this.setup();
  }

  getTags(): void {
    this.tagsService.getTags(this.tenantId)
    .subscribe(res => {
      this.allTags = res;
      
      this.populateTags();
    });
  }

  populateTags(): void {
    let tagColumns = this.allTags.map(tag => {
      this.tagById[tag.tagId] = tag.tagName;

      return {
        name: tag.tagId,
        //prop:'custom_data',
        prop: "miso_service.tags",
        headerTemplate: this.tagHeaderTpl,
        cellTemplate: SortableTemplatesEnum.tag,
        sortable: false,
        hidden: true
      }
    });

    console.log("tagById", this.tagById);
    console.log("tagColumns", tagColumns);

    //this.setup();

    //this.serviceColumns.push(...tagColumns);
    this.tempServiceColumns.push(...tagColumns);

    this.getCustomFields();
  }

  filterCustomFields(allCustomFields: tenantBridgeCustomFieldsResponse[]): tenantBridgeCustomFieldsResponse[] {
    let uniqueCustomFieldIds: string[] = [];
    let filteredCustomFields: tenantBridgeCustomFieldsResponse[] = [];
    allCustomFields.map(cf => {
      if(uniqueCustomFieldIds.indexOf(cf.id) == -1) {
        uniqueCustomFieldIds.push(cf.id);
        filteredCustomFields.push(cf);
      }
      else {
        console.log("duplicate", cf);        
      }
    });
    //console.log("filteredCustomFields", filteredCustomFields);

    return filteredCustomFields;
  }

  populateCustomFields(): void {
    console.log("this.allCustomFields.length", this.allCustomFields.length);
    this.allCustomFields = this.filterCustomFields([...this.allCustomFields]);
    if(this.allCustomFields.length) {
      let customFieldColumns = this.allCustomFields.map(cf => {
        this.customFieldById[cf.id] = cf.customFieldLabel;
        return {
          name: cf.id,
          //prop:'custom_data',
          prop: "miso_service.custom_fields",
          headerTemplate: this.customFieldHeaderTpl,
          cellTemplate: SortableTemplatesEnum.customField,
          sortable: false,
          hidden: true
        }
      });
      this.setup();
      //console.log("customFieldColumns", customFieldColumns);
      //this.serviceColumns.push(...customFieldColumns);
      this.tempServiceColumns.push(...customFieldColumns);
      this.serviceColumns.push(...this.tempServiceColumns);
      this.getServiceData();
      //this.store.dispatch(new ServiceV2Actions.SetSearchLoaded(this.serviceData));
    }
    else {
      //this.setup();
      this.getServiceData();
      //this.store.dispatch(new ServiceV2Actions.SetSearchLoaded(this.serviceData));
    }    
  }

  filterTenantBridges(tenantBridges: tenantBridgesResponse[]): tenantBridgesResponse[] {
    let filteredTenantBridges: tenantBridgesResponse[] = [];
    let uniqueTenantBridgeIds: string[] = [];
    
    tenantBridges.map(tb => {
      if(uniqueTenantBridgeIds.indexOf(tb.bridgeId) == -1) {
        uniqueTenantBridgeIds.push(tb.bridgeId);
        filteredTenantBridges.push(tb);
      }
      else {
        console.log("duplicate", tb);        
      }
    });

    return filteredTenantBridges;
  }

  getCustomFields(): void {
    this.customFieldsV2Service.getTenantBridges(this.tenantId)
    .toPromise()
    .then((tenantBridges: tenantBridgesResponse[]) => {
      if(tenantBridges.length == 0) {
        this.noCustomField = true;
        this.setup();
        return;
        //this.store.dispatch(new ServiceV2Actions.SetSearchLoaded(this.serviceData));
      }
      console.log("tenant bridges", tenantBridges);
      tenantBridges = this.filterTenantBridges([...tenantBridges]);
      let bridgeCount = 0;
      tenantBridges.forEach((tenantBridge: tenantBridgesResponse) => {
        this.customFieldsV2Service.getTenantBridgeCustomFields(this.tenantId, tenantBridge.bridgeId, tenantBridge.vendorId)
        .toPromise()
        .then((customFields: tenantBridgeCustomFieldsResponse[]) => {
          bridgeCount++;
          customFields.forEach((customField: tenantBridgeCustomFieldsResponse) => {
            customField.vendorName = tenantBridge.vendorName;
          });
          this.allCustomFields.push(...customFields);
          if(bridgeCount == tenantBridges.length) {
            this.populateCustomFields();
          }
        });
      });
    });
  }

  getServiceData() {
    this.store.select(ServiceV2Reducer.getServiceV2Data)
      .subscribe(
        data => {
          console.log("store data", data);
          // this.hits$ = data.results.data;
          this.hits$ = data.results.data.map(item => ({
            ...item,
            parent_group_name: item.miso_service.general.parent_service_group_name?.split(" ")[0]
          }));
        }
      );
  }

  setup(): void {
    console.log("setup cols");
    this.serviceColumns = [
      {
        name: "Parent Group",
        prop:'parent_group_name',
        sortable: true
      },
      {
        //name: "P-cMRC",
        name: "P-CMRC",
        prop:'miso_service.general.pcmrc',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: false
      },
      {
        name: "MISO ID",
        prop:'miso_service.general.service_display_id',
        cellTemplate: SortableTemplatesEnum.idLink,
        sortable: false
      },
      {
        name: "Vendor (Purchasing)",
        prop:'miso_service.vendor.purchasing_vendor_name',
        sortable: true
      },
      {
        name: "Vendor (Manufacturer)",
        prop:'miso_service.vendor.manufacturing_vendor_name',
        sortable: true,
        hidden: true
      },
      {
        name: "Order Date",
        prop:'miso_service.vendor.order_date',
        cellTemplate: SortableTemplatesEnum.shortDate,
        sortable: false,
        hidden: true
      },
      {
        name: "Order Number",
        prop:'miso_service.vendor.order_number',
        sortable: true,
        hidden: true
      },
      {
        name: "Product",
        prop:'miso_service.service_data.product_name',
        sortable: true
      },
      {
        name: "Service Category",
        prop:'miso_service.service_data.product_category_name',
        sortable: false,
        hidden: false
      },
      {
        name: "Service ID",
        prop:'miso_service.service_data.service_identifier',
        sortable: true
      },
      {
        name: "Account ID",
        prop:'miso_service.vendor.account_id_label',
        sortable: true
      },
      {
        name: "Currency",
        prop:'miso_service.service_data.currency',
        sortable: true
      },
      {
        name: "Quantity",
        prop:'miso_service.service_data.quantity',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: false,
        hidden: false
      },
      {
        name: "Unit Recurring Cost",
        prop:'miso_service.service_data.unit_recurring_cost',
        cellTemplate: SortableTemplatesEnum.serviceCurrency,
        sortable: true
      },
      {
        name: "Unit Non-Recurring Cost",
        prop:'miso_service.service_data.unit_non_recurring_cost',
        cellTemplate: SortableTemplatesEnum.serviceCurrency,
        sortable: false
      },
      {
        name: "Billing Frequency",
        prop:'miso_service.service_data.billing_frequency',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: false
      },
      // {
      //   name: "Unit Non-Recurring Cost",
      //   prop:'miso_service.service_data.unit_non_recurring_cost',
      //   cellTemplate: SortableTemplatesEnum.serviceCurrency,
      //   sortable: false,
      //   hidden: false
      // },
      {
        //name: "cMRC",
        name: "CMRC",
        prop:'miso_service.service_data.cmrc',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: true
      },
      {
        name: "Location A",
        prop:'miso_service.dates_and_locations.location_a_name',
        sortable: true
      },
      {
        name: "Location B",
        prop:'miso_service.dates_and_locations.location_b_name',
        sortable: true
      },
      {
        name: "Start Date",
        prop:'miso_service.dates_and_locations.initial_start_date',
        cellTemplate: SortableTemplatesEnum.shortDate,
        sortable: true
      },
      {
        name: "End of Term",
        prop:'miso_service.dates_and_locations.end_of_term_date',
        cellTemplate: SortableTemplatesEnum.shortDate,
        sortable: true
      },
      {
        name: "Current Term",
        prop:'miso_service.service_data.current_term_length',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: true
      },
      {
        name: "Termination Date",
        prop:'miso_service.service_data.termination_date',
        cellTemplate: SortableTemplatesEnum.shortDate,
        sortable: true
      },
      {
        name: "Event Date",
        prop:'miso_service.events_and_service_status.event_date',
        cellTemplate: SortableTemplatesEnum.shortDate,
        sortable: false,
        hidden: true
      },
      {
        name: "Days to Event",
        prop:'miso_service.events_and_service_status.days_to_event',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: false,
        hidden: false
      },
      {
        name: "Next Event",
        prop:'miso_service.events_and_service_status.next_event',
        cellTemplate: this.nextEventTmpl,
        sortable: false,
        hidden: true
      },
      // {
      //   name: "Next Event Length",
      //   prop:'current_notification.notification_term_length',
      //   cellTemplate: SortableTemplatesEnum.number,
      //   sortable: false
      // },
      {
        name: "Next Event Value",
        prop:'miso_service.events_and_service_status.next_event_value',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: false,
        hidden: true
      },
      {
        name: "Number of Auto Renew",
        prop:'miso_service.events_and_service_status.number_of_auto_renew',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: false,
        hidden: false
      },
      // {
      //   name: "Renewal Length (Months)",
      //   prop:'current_financial.service_term_v2.auto_renew_length',
      //   cellTemplate: SortableTemplatesEnum.number,
      //   sortable: false
      // },
      {
        name: "Data Integrity",
        prop:'miso_service.events_and_service_status.data_integrity_status',
        cellTemplate: this.dataIntegrityTpl,
        sortable: false
      },
      {
        name: "Service Status",
        prop:'miso_service.events_and_service_status.service_status',
        cellTemplate: this.serviceStatusTmpl,
        sortable: false,
        hidden: true
      },
      /*
      {
        name: "M3 Owner",
        prop:'miso_service.service_data.m3_owner_email',
        sortable: true,
        hidden: false
      },
      */
      {
        //name: "Service Owner",
        name: "Accountable Executive",
        prop:'miso_service.service_data.service_owner_email',
        sortable: true,
        hidden: false
      },
      {
        name: "Subscriber",
        prop:'miso_service.service_data.subscriber_email',
        sortable: true,
        hidden: false
      },
      {
        name: "Mi-MiSO Owner",
        prop:'miso_service.service_data.mi_miso_user_email',
        sortable: true,
        hidden: false
      },
      {
        name: "Division",
        prop:'miso_service.service_data.division_name',
        cellTemplate: this.divisionTmpl,
        sortable: false,
        hidden: true
      },
      {
        name: "Team",
        prop:'miso_service.service_data.team',
        cellTemplate: this.teamTmpl,
        sortable: false,
        hidden: true
      },
      /*
      {
        name: "Service Group",
        prop:'miso_service.service_data.service_group',
        cellTemplate: this.groupTmpl,
        sortable: false
      },
      */
      {
        name: "Validation Flag",
        prop:'miso_service.service_data.validation_flag',
        cellTemplate: this.validationFlagTmpl,
        sortable: false
      },
      {
        name: "Days Since Last Update",
        prop:'miso_service.service_data.days_since_last_update',
        sortable: false
      },
      {
        name: "In-Term",
        prop:'miso_service.service_data.in_term',
        cellTemplate: this.inTermTpl,
        sortable: false
        
      },
      {
        name: "Liability",
        prop:'miso_service.service_data.remaining_liability',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: false
      },
      {
        name: "Activity Alert",
        prop:'miso_service.service_data.active_usage',
        sortable: false
      },
      {
        name: "Price Increase %",
        prop:'miso_service.service_data.price_increase_percentage',
        sortable: false
      }
    ];

    console.log("after cols setup, getting store data");

    if(this.noCustomField) {
      setTimeout(() => {
        this.store.select(ServiceV2Reducer.getServiceV2Data)
        .subscribe(
          data => {
            console.log("store data", data);
            this.hits$ = data.results.data;
          }
        );
      }, 500);
    }
    else {
      this.store.select(ServiceV2Reducer.getServiceV2Data)
      .subscribe(
        data => {
          console.log("store data", data);
          this.hits$ = data.results.data;
        }
      );
    }
  }

  onFilterClick() {

  }
}