import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import * as userReducer from '../../user-store/state/reducers';
import * as userActions from '../../user-store/state/actions';
import {AUTH} from '../../../common/security/secureUser';
import * as linkFormatter from '../../../common/utils/linkFormatter';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {catchError, map} from "rxjs/operators";
import {UserStoreService} from "../../user-store/user-store.service";
import FrontEndSettingsUtil from '../../../shared/utils/frontEndSettings.util';

const log = createLogger(LOG_LEVELS.AUTH_GUARD);

@Injectable()
export class TenantUserAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userStoreService: UserStoreService,
    private store: Store<userReducer.State>
  ) {
  }

  canActivate(): Observable<boolean> {
    // debugger;
    return this.userStoreService.getLoggedInService()
      .pipe(
        map((res) => {
          const domain = FrontEndSettingsUtil.get() && FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN
          ? FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN
          : linkFormatter.getSubdomainFromHost(window.location.host);
          // lets keep the user store updated:
          this.store.dispatch(new userActions.UserVerifySuccessAction(res));

          let mayAccess = res.secureUser && res.secureUser.hasTenantAccess(domain, AUTH.ACCOUNT_USER);


          //debugger;
          if (!mayAccess) {
            // if this person is not able to navigation we must send him to 404
            this.router.navigate(['/403']);
          } else if (mayAccess && !res.secureUser.isSuperUser()) {
            //but does this domain have a plan?
            if (!res.secureUser.hasTermsAndConditionsAccepted(domain)) {
              this.router.navigate(['/accept-terms-and-conditions']);
            }
          }

          return mayAccess;
        }),
        catchError((error: any) => {
          log('this person was not logged in');
          window.location.href = linkFormatter.getLoginLink(window.location.href);
          return of(false);
        })
      );
  }
}
