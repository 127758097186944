import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {TenantUserManagementService} from '../../../libs/api/tenant/tenantUserManagement.service';
import {emailValidator} from '../../../shared/shared.validators';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import * as userReducer from '../../../libs/user-store/state/reducers';
import * as alertActions from '../../../shared/state/alerts/actions';
import {Miso3baseService} from '../../../libs/api2/miso3base-v2/miso3base.service';
import {RoleV2} from '../../../libs/api2/types/RolesV2.interface';
import {UserManagement} from '../../../shared/models/UserManagement.interface';
import {DivisionV2Service} from '../../../libs/api2/division-v2/division-v2.service';
import {BusinessUnitBaseV2} from '../../../libs/api2/types/BusinessUnitBaseV2.interface';
import {TenantV2Service} from '../../../libs/api2/tenant-v2/tenant-v2.service';
import { filterRole } from 'src/common/utils/UserAccess';
import { TenantUser, TenantUserBUTree, TenantUserAccess } from 'src/libs/api2/types/TenantUser.interface';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { PageAlertAddAction } from 'src/shared/state/page-alert/actions';
import { ToastrService } from 'ngx-toastr';
import { TelephoneSimpleInputComponent } from 'src/libs/form-components/telephone-simple-input/telephone-simple-input.component';

export enum CreateEditUmMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

enum BusinessUnitFormTypes {
  DIVISION,
  SITE
}

@Component({
  selector: 'mi-user-management-form',
  templateUrl: './users-management-form.component.html',
  styleUrls: ['./users-management-form.component.scss']
})

export class UsersManagementFormComponent extends AbstractPageComponent implements OnInit {
  @Input() activeLocked: boolean = false;
  @Input() canEditRoleOnBu: boolean = true;
  @Input() formType: BusinessUnitFormTypes = null;
  @Input() businessUnitId: string = null;
  @Input() division: string = null;
  @Input() site: string = null;
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() userUpdated: EventEmitter<TenantUser> = new EventEmitter<TenantUser>();
  @Output() formClosed = new EventEmitter<void>();
  @ViewChild(TelephoneSimpleInputComponent, { static: false }) telephoneSimpleInputComponent!: TelephoneSimpleInputComponent;

  //new
  tenantId: string;
  tenantUser: TenantUser;
  tenantUserBUTree : TenantUserAccess;
  // @Input() _editId: string;
  @Input() editId: string = '';

  mode: CreateEditUmMode;

  form: FormGroup = null;
  // resetForm: FormGroup;
  businessUnitFormTypes = BusinessUnitFormTypes;

  loading: boolean = false;
  showResetPass: boolean = false;
  owner: string = null;

  loadError: any;
  submitError: string = '';
  createUpdateLabel: string = '';

  businessUnits: BusinessUnitBaseV2[] = [];
  divisions: BusinessUnitBaseV2[] = [];
  teams: BusinessUnitBaseV2[] = [];

  successMessage: string = null;

  roles: RoleV2[] = [];
  showButtonContainer: boolean = true;

  password: string = null;
  passwordConfirm: string = null;
  email: string = null;

  userData: UserManagement;

  selectedDivision: string = null;
  selectedTeam: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private tenantUserManagementService: TenantUserManagementService,
    private store: Store<userReducer.State>,
    private miso3baseService: Miso3baseService,
    private contactV2Service: ContactV2Service,
    private tenantV2Service: TenantV2Service,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {

    const fetchTenant = this.store.pipe(
      select(appDataReducer.getAppData)
    );

    const fetchRoles = this.miso3baseService.getRoles();

    combineLatest(fetchTenant, fetchRoles)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        ([tenant, roles]) => {
          this.owner = tenant.tenantData.domain;
          this.roles = filterRole(roles, tenant.tenantData && tenant.tenantData.v2? tenant.tenantData.v2.miso_business_unit : false);
          this.getUser();
        },
        (e) => {
          this.loadError = e;
          this.successMessage = null;
        }
      );
  }

  getUser(){
    if(this.editId){
      this.contactV2Service.getTenantUser(this.editId)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((user) => {
          this.startAsEditMode(user);
        },
        (e) => {
          this.loadError = e;
          this.successMessage = null;
        }
      );
    } else {
      this.startAsCreateMode();
    }
  }

   getBusinessUnits() {
      this.tenantV2Service.getBusinessUnits({ owner: this.owner })
        .subscribe(
          (businessUnits) => {
            this.businessUnits = businessUnits;

            if (this.businessUnits === null || this.businessUnits === undefined) {
              this.businessUnits = [];
            }

            this.divisions = this.businessUnits.filter(bu => bu.parent_id && bu.parent_id === this.owner);

            if (this.divisions === null || this.divisions === undefined) {
              this.divisions = [];
            }

            this.loading = true;
          },
          (error) => {
            this.loadError = error;
            this.successMessage = null;
          });
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
    // this.close(true);
  }

  resetForm() {
    this.form.reset({
      first_name: null,
      last_name: null,
      email: null,
      office_phone: null,
      mobile_phone: null,
      is_active: true
    });
    this.editId = null;
    if(this.telephoneSimpleInputComponent){
      this.telephoneSimpleInputComponent.clearValue();
    }
    // this.createForm(null)
  }

  closeForm() {
    this.formClosed.emit();
    this.resetForm();
  }

  startAsCreateMode(): void {
    this.mode = CreateEditUmMode.CREATE;
    this.createUpdateLabel = 'Create Employee';
    this.createForm({ is_active: true });
  }

  startAsEditMode(user: TenantUser): void {
    this.mode = CreateEditUmMode.EDIT;
    this.createUpdateLabel = 'Update User';
    this.editId = user.id;
    this.loading = true;
    this.loadError = null;
    this.successMessage = null;
    this.createForm(user);
  }

  createForm(userData: any = {}): void { 
    let {
      first_name,
      last_name,
      email,
      office_phone,
      mobile_phone,
      is_active
    } = userData;

    this.userData = userData;
    this.form = this.formBuilder.group({
      first_name: [first_name, Validators.required],
      last_name: [last_name, Validators.required],
      is_active:  [is_active, Validators.required],
      email: [email, Validators.compose([Validators.required, emailValidator])],
      mobile_phone: [mobile_phone, Validators.compose([])],
      office_phone: [office_phone, Validators.compose([])],
    });

    this.loading = false;
  }

  onFormSubmit(formValue, $event): void {
    this.loading = true;
    $event.preventDefault();
    if (this.mode === CreateEditUmMode.CREATE) {
      this.createUser(formValue);
    } else if (this.mode === CreateEditUmMode.EDIT) {
      this.updateUser(formValue);
    }
    $event.stopPropagation();
  }

  createUser(data: any = {}): void {
    data.tenant_id = this.owner;

    this.contactV2Service.saveTenantUser(data)
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(user => {
      // this.updateRecentUsers(user);
      this.toastr.success(`The user ${user.first_name} ${user.last_name} created successfully`);
      this.successMessage = null;
      
      // this.successMessage = `The user,${user.email}, has been successfully created!`;
      /*
      this.store.dispatch(
        new PageAlertAddAction({
          message: this.successMessage,
          alertType: "SUCCESS"
        })
      );
      */
      this.userUpdated.emit(user);
      this.loading = false;
      this.resetForm();
    }
    ,error => {
      this.loadError = error;
      // this.successMessage = null;
      this.toastr.success(`We are facing issue while creating a user, please try again.` );
      this.loading = false;
    });
  }

  updateUser(data: any = {}): void {
    data.id = this.editId;
    data.tenant_id = this.owner;

    this.contactV2Service.saveTenantUser(data)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(user => {
        // this.updateRecentUsers(user);
        this.toastr.success(`The user ${user.first_name} ${user.last_name} has been successfully updated!`);
        this.successMessage = null;
        //this.successMessage = `The user,${user.email}, has been successfully updated!`;
        /*
        this.store.dispatch(
          new PageAlertAddAction({
            message: this.successMessage,
            alertType: "SUCCESS"
          })
        );
        this.store.dispatch(new alertActions.LoadAlertsAction(null));
        */
        this.userUpdated.emit(user);
      }
      ,error => {
        this.loadError = error;
        this.successMessage = null;
        this.loading = false;
      });
  }

  updateRecentUsers(user) {
    this.tenantUserManagementService.save(user)
      .subscribe((data) => {
        this.loading = false;
      });
  }

  onPasswordSubmit(formData): void {
    this.loadError = null;
    this.successMessage = null;
    this.password = formData.password;
    this.passwordConfirm = formData.password_confirm;
    if (this.password !== this.passwordConfirm) {
      this.loadError = `Passwords don't match`;
      return;
    } else {
      this.loading = true;
      const data = {
        business_unit_id: this.businessUnitId,
        confirm_password: this.passwordConfirm,
        email: this.userData.email,
        password: this.password
      };
      this.tenantUserManagementService.changePassword(data)
        .toPromise()
        .then(user => {
          this.loading = false;
          this.successMessage = 'Password has been successfully changed!';
        })
        .catch((error) => {
          this.loadError = error;
          this.successMessage = null;
          this.loading = false;
        });
    }
  }

  showResetPassword(e) {
    this.showResetPass = true;
  }

  changeDivision(id) {
    this.selectedDivision = id;
    this.teams = this.businessUnits.filter(bu => bu.parent_id === id);
  }

  changeTeam(id) {
    this.selectedTeam = id;
  }
}
