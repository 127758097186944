import {NotFoundComponent} from '../shared/pages/not-found/not-found.component';
import {TenantUserAuthGuard} from '../libs/auth-guards/guards/TenantUserAuth.guard';
import {ForbiddenComponent} from '../shared/pages/forbidden/forbidden.component';
import {MiServiceDetailComponent} from './pages/services/detail/detail-miservice.component';
import {ContactDetailComponent} from './pages/contacts/instance-wrap/vendor-contact/detail/contact-details.component';
import {CreateEditContactsComponent} from './pages/contacts/instance-wrap/vendor-contact/create-edit/create-edit-contacts.component';
import {EventsListPageComponent} from './pages/events/events-list-page/events-list-page.component';
import {LocationSearchEventsComponent} from './pages/locations/instance-wrap/search-events/location-search-events.component';
import {LocationSearchMiServicesComponent} from './pages/locations/instance-wrap/search-mi-services/location-search-mi-services.component';
import {LocationDetailComponent} from './pages/locations/instance-wrap/detail/location-details.component';
import {LocationInstanceWrapComponent} from './pages/locations/instance-wrap/location-instance-wrap.component';
import {LocationsEditComponent} from './pages/locations/instance-wrap/edit/locations-edit.component';
import {ContactInstanceWrapComponent} from './pages/contacts/instance-wrap/contact-instance-wrap.component';
import {ContactSearchEventsComponent} from './pages/contacts/instance-wrap/vendor-contact/search-events/contact-search-events.component';
import {ContactSearchMiServicesComponent} from './pages/contacts/instance-wrap/vendor-contact/search-mi-services/contact-search-mi-services.component';
import {ContactEditComponent} from './pages/contacts/instance-wrap/vendor-contact/edit/contact-edit.component';
import {VendorAccountIdInstanceWrapComponent} from './pages/vendor-account-ids/instance-wrap/vendor-account-id-instance-wrap.component';
import {VendorAccountIdDetailsComponent} from './pages/vendor-account-ids/instance-wrap/detail/vendor-account-id-details.component';
import {VendorAccountIdSearchEventsComponent} from './pages/vendor-account-ids/instance-wrap/search-events/vendor-account-id-search-events.component';
import {VendorAccountIdSearchMiServicesComponent} from './pages/vendor-account-ids/instance-wrap/search-mi-services/vendor-account-id-search-mi-services.component';
import {VendorAccountIdEditComponent} from './pages/vendor-account-ids/instance-wrap/edit/vendor-account-id-edit.component';
import {VendorAccountIdCreateComponent} from './pages/vendor-account-ids/instance-wrap/create/vendor-account-id-create.component';
import {RequestAQuoteComponent} from './pages/services/request-a-quote/request-a-quote.component';
import {EmailDetailComponent} from './pages/emails/details/email-detail.component';
import {ServicesCreateComponent} from './pages/services/create/services-create.component';
import {ServiceOrderCreateComponent} from './pages/services/orders/create/service-order-create.component';
import {ServiceOrderCreateFormComponent} from './pages/services/orders/create/service-segments/service-order-create-form.component';
import {AccountLockedComponent} from './pages/account-locked/account-locked.component';
import {VendorDefinedFieldsComponent} from './pages/vendor-defined-fields/vendor-defined-fields.component';
import {ServiceOrderSummaryComponent} from './pages/services/orders/summary/service-order-summary.component';
import {ServiceOrderAddServiceComponent} from './pages/services/orders/add-service/service-order-add-service.component';
import {ServiceOrderEditComponent} from './pages/services/orders/service-order-edit/service-order-edit.component';
import {VendorProductCreateComponent} from './pages/vendor-products/vendor-product-create.component';
import {ProductEditComponent} from './pages/products/instance-wrap/edit/product-edit.component';
import {ProductInstanceWrapComponent} from './pages/products/instance-wrap/product-instance-wrap.component';
import {ProductDetailComponent} from './pages/products/instance-wrap/detail/product-detail.component';
import {ProductSearchEventsComponent} from './pages/products/instance-wrap/search-events/product-search-events.component';
import {ProductSearchMiServicesComponent} from './pages/products/instance-wrap/search-mi-services/product-search-mi-services.component';
import {ParentServicesCreateComponent} from './pages/parent-services/create/parent-services-create.component';
import {ParentServiceDetailPageComponent} from './pages/parent-services/detail/detail-miparent-service.component';
import {ParentServiceDetailsComponent} from './pages/parent-services/detail/details/parent-service-details.component';
import {ParentServiceEditComponent} from './pages/parent-services/detail/edit/parent-service-edit.component';
import {ParentServiceAddServiceComponent} from './pages/parent-services/detail/add-service/parent-service-add-service.component';
import {ParentServiceGetQuoteComponent} from './pages/parent-services/detail/get-quote/parent-service-get-quote.component';
import {VendorAccountIdDeleteComponent} from './pages/vendor-account-ids/instance-wrap/delete/vendor-account-id-delete.component';
// import {TanentProductSearchTestComponent} from './pages/test/product-search-test.component';
import {ContactDeleteComponent} from './pages/contacts/delete/contact-delete.component';
import {LocationDeleteComponent} from './pages/locations/instance-wrap/delete/location-delete.component';
import {ServiceTermInstanceWrapComponent} from './pages/service-term/instance-wrap/service-term-instance-wrap.component';
import {TermAndConditionCreateComponent} from './pages/service-term/instance-wrap/create/terms-and-conditions-create.component';
import {ServiceTermSearchMiServicesComponent} from './pages/service-term/instance-wrap/search-mi-services/service-term-search-mi-services.component';
import {ServiceTermSearchEventsComponent} from './pages/service-term/instance-wrap/search-events/service-term-search-events.component';
import {ServiceTermDetailsComponent} from './pages/service-term/instance-wrap/detail/service-term-details.component';
import {ParentServiceDeleteComponent} from './pages/parent-services/detail/delete/parent-service-delete.component';
import {AssociatedDocumentsInstanceWrapComponent} from './pages/associated-documents/instance-wrap/associated-documents-instance-wrap.component';
import {AssociatedDocumentsCreateComponent} from './pages/associated-documents/instance-wrap/create/associated-documents-create.component';
import {AssociatedDocumentsEditComponent} from './pages/associated-documents/instance-wrap/edit/associated-documents-edit.component';
import {AssociatedDocumentsSearchEventsComponent} from './pages/associated-documents/instance-wrap/search-events/associated-documents-search-events.component';
import {AssociatedDocumentsDetailsComponent} from './pages/associated-documents/instance-wrap/detail/associated-documents-details.component';
import {ProductDeleteComponent} from './pages/products/instance-wrap/delete/product-delete.component';
import {AssociatedDocDeleteComponent} from './pages/associated-documents/instance-wrap/delete/associated-documents-delete.component';
import {RequestMissingDataComponent} from './pages/services/request-missing-data/request-missing-data.component';
import {DownloadsComponent} from 'src/shared/pages/downloads/downloads.component';
import {MissingOpportunitiesVendorComponent} from './pages/missing-opportunities/missing-opportunities-vendor/missing-opportunities-vendor.component';
import {MiServiceDeleteComponent} from './pages/services/delete/service-delete.component';
import {ParentServiceRemoveServiceComponent} from './pages/parent-services/detail/remove-service/parent-service-remove-service.component';
import {VendorEquipmentCreateComponent} from './pages/vendor-equipment-create/vendor-equipment-create.component';
import {AccountRepEmailComponent} from './pages/services/account-representatitve-email/account-representatitve-email.component';
import {ParentServiceAccountRepEmailComponent} from './pages/parent-services/detail/account-representatitve-email/account-representatitve-email.component';
import {ServiceRequestorEmailComponent} from './pages/services/service-requestor-email/service-requestor-email.component';
import {ReAssessmentEmailComponent} from './pages/services/re-assessment-email/re-assessment-email.component';
import {CancellationReqeustEmailComponent} from './pages/services/cancellation-request-email/cancellation-request-email.component';
import {ReQuoteEmailComponent} from './pages/emails/re-quote/re-quote.component';
import {QuoteMisoMarketplaceComponent} from './pages/services/request-a-quote-miso-marketplace/quote-miso-marketplace.component';
import {EmailSentComponent} from './pages/emails/sent/email-sent.component';
import {ParentRequestMissingDataComponent} from './pages/parent-services/detail/request-missing-data/request-missing-data.component';
import {ParentRequestAQuoteComponent} from './pages/parent-services/detail/request-a-quote/request-a-quote.component';
import {ParentQuoteMisoMarketplaceComponent} from './pages/parent-services/detail/request-a-quote-miso-marketplace/quote-miso-marketplace.component';
import {ParentReAssessmentEmailComponent} from './pages/parent-services/detail/re-assessment-email/re-assessment-email.component';
import {ParentRequestorEmailComponent} from './pages/parent-services/detail/parent-requestor-email/parent-requestor-email.component';
import {ModalCancellationComponent} from './pages/services/detail/modal-cancellation-instruction/modal-cancellation-instruction.component';
import {ParentModalCancellationComponent} from './pages/parent-services/detail/modal-cancellation-instruction/modal-cancellation-instruction.component';
import {ParentCancellationReqeustEmailComponent} from './pages/parent-services/detail/cancellation-request-email/cancellation-request-email.component';
import {DataImportComponent} from './pages/data-import/data-import.component';
import {ApiIntegrationComponent} from './pages/data-import/api-integration/api-integration.component';
import {UploadDocumentComponent} from './pages/data-import/upload-document/upload-document.component';
import {ContactListComponent} from './pages/contacts/contact-list/contact-list.component';
import {ContactSearchEquipmentComponent} from './pages/contacts/instance-wrap/vendor-contact/search-equipment/contact-search-equipment.component';
import { LocationSearchEquipmentComponent } from './pages/locations/instance-wrap/search-equipment/location-search-equipment.component';
import { AcceptTermsAndConditionsComponent } from './pages/accept-terms-and-conditions/accept-terms-and-conditions.component';
import { UserAndEmployeeListComponent } from './pages/contacts/user-and-employee-list/user-and-employee-list.component';
import { CreateEditEmployeeComponent } from './pages/contacts/employees/create-edit/create-edit-employee.component';
import { UserAndEmployeeListEndUsersComponent } from './pages/contacts/user-and-employee-list/user-and-employee-list-end-user.component';
import { UserAndEmployeeListMisoManagerComponent } from './pages/contacts/user-and-employee-list/user-and-employee-list-miso-manager.component';
import { UserAndEmployeeServiceOwnerListComponent } from './pages/contacts/user-and-employee-list/user-and-employee-list-service-owner.component';
import { EmployeeSearchEventsComponent } from './pages/contacts/instance-wrap/employee/search-events/employee-search-events.component';
import { EmployeeSearchMiServicesComponent } from './pages/contacts/instance-wrap/employee/search-mi-services/employee-search-mi-services.component';
import { EmployeeSearchEquipmentComponent } from './pages/contacts/instance-wrap/employee/search-equipment/employee-search-equipment.component';
import { EmployeeInstanceWrapComponent } from './pages/contacts/instance-wrap/employee-instance-wrap.component';
import { DocumentsUploadComponent } from './pages/documents/documents-upload/documents-upload.component';
//import { VendorCustomFieldsComponent } from './pages/tenant-configure/vendor-custom-fields/vendor-custom-fields.component';
import { MiServiceListV2Component } from './pages/services/list-v2/mi-service-list-v2.component';
import { SubscriberSearchMiServicesV2Component } from './pages/contacts/instance-wrap/employee/search-mi-services-v2/subscriber-search-mi-services-v2.component';
import { ServiceOwnerSearchMiServicesV2Component } from './pages/contacts/instance-wrap/employee/search-mi-services-v2/service-owner-search-mi-services-v2.component';
import { M3OwnerSearchMiServicesV2Component } from './pages/contacts/instance-wrap/employee/search-mi-services-v2/m3-owner-search-mi-services-v2.component';
import { AssociatedDocumentsSearchMiServicesV2Component } from './pages/associated-documents/instance-wrap/search-mi-services-v2/associated-documents-search-mi-services-v2.component';
import { Miso3LandingPageComponent } from './pages/landing-page/landing-page.component';
import { TenantReportComponent } from './pages/tenant-reports/tenant-reports.component';
import { DocumentManagerComponent } from './pages/documents/documents-manager/documents-manager.component';
import { VarianceReportComponent } from './pages/variance-report/variance-report.component';
import { UnpaidInvoiceReportComponent } from './pages/unpaid-invoice-report/unpaid-invoice-report.component';
import { AccountingAnalysisComponent } from './pages/accounting-analysis/accounting-analysis.component';
import { CostChangeAnalysisComponent } from './pages/cost-change-analysis/cost-change-analysis.component';
import { VarianceSnapshotsComponent } from './pages/variance-snapshots/variance-snapshots.component';
import { CompanyInformationComponent } from './pages/company-information/company-information.component';
import { PlatformInformationComponent } from './pages/platform-information/platform-information.component';
import { UsersComponent } from './pages/users-wrapper/users/users.component';
import { MonthlySpendDistributionComponent } from './pages/monthly-spend-distribution/monthly-spend-distribution.component';

const appRoutes = [
  { path: 'dashboard', component: Miso3LandingPageComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },
  { path: 'tenant-report', component: TenantReportComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },
  // { path: 'report-summary', component: ReportSummaryComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },
  // { path: 'cost-variance-report', component: CostVarianceReportComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },
  { path: 'unpaid-invoice', component: UnpaidInvoiceReportComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },
  { path: 'accounting-analysis', component: AccountingAnalysisComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]  },
  { path: 'cost-change-analysis', component: CostChangeAnalysisComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]  },
  { path: 'monthly-spend-distribution', component: MonthlySpendDistributionComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]  },
  { path: 'variance-report', component: VarianceReportComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },  
  { path: 'variance-snapshots', component: VarianceSnapshotsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },  
  // { path: 'unalign-services', component: UnAlignServicesComponent, pathMatch: 'full', canActivate: [TenantAlignAuthGuard] },
  // { path: 'test-product-search', component: TanentProductSearchTestComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },
  // { path: 'fix-data-integrity', component: TanentProductSearchTestComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },
  { path: 'company-information', component: CompanyInformationComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },  
  { path: 'platform-information', component: PlatformInformationComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },  
  { path: 'users', component: UsersComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard] },  
  {
    path: '',
    //component: DashboardPageComponent,
    redirectTo: 'dashboard',
    pathMatch: 'full',
    //canActivate: [TenantUserAuthGuard],
    //data: {pageTitle: 'Dashboard - MISO3'}
  },
  {
    path: 'missing-opportunities',
    canActivate: [TenantUserAuthGuard],
    children: [
      {path: '', redirectTo: 'vendors', pathMatch: 'full'},
      {path: 'vendors', component: MissingOpportunitiesVendorComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]}
    ]
  },
  {
    path: 'data-connectors',
    loadChildren: () => import('./pages/data-connectors/data-connectors.module').then(m => m.DataConnectorsModule)
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./pages/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
  },
  {
    path: 'associated-documents',
    loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule)
  },
  // {
  //   path: 'currency',
  //   loadChildren: () => import('./pages/currency/currency.module').then(m => m.CurrencyModule)
  // },
  /* Associated Documents */
  {
    path: 'associated-documents/:display_id',
    component: AssociatedDocumentsInstanceWrapComponent,
    canActivate: [TenantUserAuthGuard],
    children: [
      {path: '', redirectTo: 'details', pathMatch: 'full'},
      {path: 'details', component: AssociatedDocumentsDetailsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]},
      {path: 'events', component: AssociatedDocumentsSearchEventsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]},
      {
        path: 'services',
        //component: AssociatedDocumentsSearchMiServicesComponent,
        component: AssociatedDocumentsSearchMiServicesV2Component,
        pathMatch: 'full',
        canActivate: [TenantUserAuthGuard]
      },
      {path: 'edit', component: AssociatedDocumentsEditComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]},
      {path: 'delete', component: AssociatedDocDeleteComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]}
    ]
  },
  {
    path: 'vendors/:vendor_display_id/associated-documents/create/:type',
    component: AssociatedDocumentsCreateComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard]
  },
  {
    path: 'documents-manager',
    component: DocumentManagerComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard]
  },
  {
    path: 'upload-associated-documents',
    component: DocumentsUploadComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard]
  },
  /* service-term */
  {
    path: 'service-term/:display_id',
    component: ServiceTermInstanceWrapComponent,
    canActivate: [TenantUserAuthGuard],
    children: [
      {path: '', redirectTo: 'details', pathMatch: 'full'},
      {path: 'details', component: ServiceTermDetailsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]},
      {path: 'events', component: ServiceTermSearchEventsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard]},
      {
        path: 'services',
        component: ServiceTermSearchMiServicesComponent,
        pathMatch: 'full',
        canActivate: [TenantUserAuthGuard]
      },
    ]
  },
  {
    path: 'vendors/:vendor_display_id/terms-and-conditions/create',
    component: TermAndConditionCreateComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard]
  },
  /* ACCOUNT IDs */
  {
    path: 'vendor-account-ids/:display_id',
    component: VendorAccountIdInstanceWrapComponent,
    canActivate: [TenantUserAuthGuard],
    children: [
      {path: '', redirectTo: 'details', pathMatch: 'full'},
      {
        path: 'details', component: VendorAccountIdDetailsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'ITEMNAME - Account DISPLAYID - VENDORNAME - MISO3 DELAYED'}
      },
      {
        path: 'events', component: VendorAccountIdSearchEventsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Events - Account DISPLAYID - VENDORNAME - MISO3 DELAYED'}
      },
      {
        path: 'services',
        component: VendorAccountIdSearchMiServicesComponent,
        pathMatch: 'full',
        canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Services - Account DISPLAYID - VENDORNAME - MISO3 DELAYED'}
      },
      {
        path: 'edit', component: VendorAccountIdEditComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Edit - Account DISPLAYID - VENDORNAME - MISO3 DELAYED'}
      },
      {
        path: 'delete', component: VendorAccountIdDeleteComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'DELAYED'}
      }
    ]
  },
  {
    path: 'vendors/:vendor_display_id/account-id/create',
    component: VendorAccountIdCreateComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard],
    data: {pageTitle: 'DELAYED'}
  },
  /* ADDRESSES & LOCATIONS */
  {
    path: 'locations/:display_id',
    component: LocationInstanceWrapComponent,
    canActivate: [TenantUserAuthGuard],
    children: [
      {path: '', redirectTo: 'details', pathMatch: 'full'},
      {
        path: 'details', component: LocationDetailComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Location DISPLAYID - MISO3'}
      },
      {
        path: 'events', component: LocationSearchEventsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Events - Location DISPLAYID - MISO3'}
      },
      {
        path: 'equipment', component: LocationSearchEquipmentComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Equipments - Location DISPLAYID - MISO3'}
      },
      {
        path: 'services', component: LocationSearchMiServicesComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Services - Location DISPLAYID - MISO3'}
      },
      {
        path: 'edit', component: LocationsEditComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Edit - Location DISPLAYID - MISO3'}
      },
      {
        path: 'delete', component: LocationDeleteComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Delete - Location DISPLAYID - MISO3'}
      }
    ]
  },
  /* CONTACTS */
  {
    path: 'contacts',
    canActivate: [TenantUserAuthGuard],
    children: [
      {
        path: '',
        component: ContactListComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          pageTitle: 'Contacts - TENANTNAME - MISO3'
        }
      },
      {
        path: 'create',
        component: CreateEditContactsComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          pageTitle: 'Create - Contacts - TENANTNAME - MISO3'
        }
      },
      {
        path: 'edit/:display_id',
        component: CreateEditContactsComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          template: 'half-page',
          pageTitle: 'Edit - Contacts DISPLAYID - MISO3'
        }
      },
      {
        path: ':display_id',
        component: ContactInstanceWrapComponent,
        canActivate: [TenantUserAuthGuard],
        children: [
          {path: '', redirectTo: 'services', pathMatch: 'full'},
          {
            path: 'details', component: ContactDetailComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Details - Contacts DISPLAYID - MISO3'}
          },
          {
            path: 'events', component: ContactSearchEventsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Events - Contacts DISPLAYID - MISO3'}
          },
          {
            path: 'services', component: ContactSearchMiServicesComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Services - Contacts DISPLAYID - MISO3'}
          },
          {
            path: 'equipment', component: ContactSearchEquipmentComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Equipments - Contacts DISPLAYID - MISO3'}
          },
          {
            path: 'edit', component: ContactEditComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Edit - Contacts DISPLAYID - MISO3'}
          },
          {
            path: 'delete', component: ContactDeleteComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Delete - Contacts DISPLAYID - MISO3'}
          }
        ]
      },
    ]
  },
  {
    path: 'employees',
    canActivate: [TenantUserAuthGuard],
    children: [
      {path: '', redirectTo: 'all', pathMatch: 'full'},
      {
        path: 'all',
        component: UserAndEmployeeListComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          pageTitle: 'Employees - TENANTNAME - MISO3'
        }
      },
      {
        path: 'end-users',
        component: UserAndEmployeeListEndUsersComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          pageTitle: 'Employees - TENANTNAME - MISO3'
        }
      },
      {
        path: 'miso-manager',
        component: UserAndEmployeeListMisoManagerComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          pageTitle: 'Employees - TENANTNAME - MISO3'
        }
      },
      {
        path: 'service-owner',
        component: UserAndEmployeeServiceOwnerListComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          pageTitle: 'Employees - TENANTNAME - MISO3'
        }
      },
      {
        path: 'create',
        component: CreateEditEmployeeComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          pageTitle: 'Create - Employees - TENANTNAME - MISO3'
        }
      },
      // {
      //   path: 'edit/:display_id',
      //   component: CreateEditEmployeeComponent,
      //   canActivate: [TenantUserAuthGuard],
      //   data: {
      //     template: 'half-page',
      //     pageTitle: 'Edit - Employees DISPLAYID - MISO3'
      //   }
      // },
      {
        path: ':display_id',
        component: EmployeeInstanceWrapComponent,
        canActivate: [TenantUserAuthGuard],
        children: [
          {path: '', redirectTo: 'end-user', pathMatch: 'full'},
          {
            path: 'events', component: EmployeeSearchEventsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Events - Employees DISPLAYID - MISO3'}
          },
          {
            path: 'services', component: EmployeeSearchMiServicesComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Services - Employees DISPLAYID - MISO3'}
          },
          {
            //path: 'end-user', component: EndUserSearchMiServicesComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            path: 'end-user', component: SubscriberSearchMiServicesV2Component, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Subscriber - Employee Details - MISO3'}
          },
          {
            //path: 'miso-manager', component: MisoManagerSearchMiServicesComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            path: 'miso-manager', component: M3OwnerSearchMiServicesV2Component, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'M3 Owner - Employees DISPLAYID - MISO3'}
          },
          {
            //path: 'service-owner', component: ServiceOwnerSearchMiServicesComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            path: 'service-owner', component: ServiceOwnerSearchMiServicesV2Component, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Service Owner - Employees DISPLAYID - MISO3'}
          },
          {
            path: 'equipment', component: EmployeeSearchEquipmentComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Equipments - Employees DISPLAYID - MISO3'}
          },
          {
            path: 'edit', component: CreateEditEmployeeComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
            data: {pageTitle: 'Edit - Employees DISPLAYID - MISO3'}
          },
          // {
          //   path: 'delete', component: ContactDeleteComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
          //   data: {pageTitle: 'Delete - Employees DISPLAYID - MISO3'}
          // }
        ]
      },
    ]
  },
  /* EVENTS */
  {
    path: 'events',
    component: EventsListPageComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard],
    data: {pageTitle: 'Events - TENANTNAME - MISO3'}
  },
  {
    path: 'vendor-defined-fields',
    component: VendorDefinedFieldsComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard],
    data: {
      pageTitle: 'Configure - TENANTNAME - MISO3'
    }
  },
  /* EMAILS */
  {
    path: 'emails', redirectTo: 'services', pathMatch: 'full'
  },
  {
    path: 'emails/:_id',
    component: EmailDetailComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard],
    data: {
      template: 'half-page',
      pageTitle: 'DELAYED'
    }
  },
  /* PRODUCTS */
  {
    path: 'products/:display_id',
    component: ProductInstanceWrapComponent,
    canActivate: [TenantUserAuthGuard],
    children: [
      {path: '', redirectTo: 'details', pathMatch: 'full'},
      {
        path: 'details', component: ProductDetailComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Details - Product DISPLAYID - MISO3'}
      },
      {
        path: 'edit', component: ProductEditComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Edit - Product DISPLAYID - MISO3'}
      },
      {
        path: 'events', component: ProductSearchEventsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Events - Product DISPLAYID - MISO3'}
      },
      {
        path: 'services', component: ProductSearchMiServicesComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Services - Product DISPLAYID - MISO3'}
      },
      {
        path: 'delete', component: ProductDeleteComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Delete - Product DISPLAYID - MISO3'}
      }
    ]
  },
  {
    path: 'vendors/:vendor_display_id/products/create',
    component: VendorProductCreateComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard],
    data: {pageTitle: 'DELAYED'}
  },
  {
    path: 'vendors/:vendor_display_id/equipment/create',
    component: VendorEquipmentCreateComponent,
    pathMatch: 'full',
    canActivate: [TenantUserAuthGuard],
    data: {pageTitle: 'DELAYED'}
  },
  /* REALIZED SAVINGS */
  {
    path: 'realized-savings',
    loadChildren: () => import('./pages/realized-savings/realized-savings.module').then(m => m.RealizedSavingsModule)
  },
  /* REPORTS */
  /*
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
  },
  */
  /* SERVICES */
  {
    path: 'services',
    canActivate: [TenantUserAuthGuard],
    data: {pageTitle: 'Services - TENANTNAME - MISO3'},
    children: [
      {
        path: '',
        //component: MiServiceListComponent,
        component: MiServiceListV2Component,
        canActivate: [TenantUserAuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'create',
        component: ServicesCreateComponent,
        canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Services Create - TENANTNAME - MISO3'},
      },
      {
        path: 'file-upload/create',
        redirectTo: '/data-import'
      },
      {
        path: 'orders',
        redirectTo: 'orders/create',
        pathMatch: 'full'
      },
      {
        path: 'orders/create',
        component: ServiceOrderCreateComponent,
        canActivate: [TenantUserAuthGuard],
        children: [
          {
            path: '',
            component: ServiceOrderCreateFormComponent,
            data: {pageTitle: 'Orders Create - Services - TENANTNAME - MISO3'},
          }
        ]
      },
      {
        path: 'orders/summary/:display_id',
        component: ServiceOrderSummaryComponent,
        canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Summary - Orders DISPLAYID - Services - TENANTNAME - MISO3'},
      },
      {
        path: 'orders/summary/:display_id/add-service',
        component: ServiceOrderAddServiceComponent,
        canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Add Service - Orders DISPLAYID - Services - TENANTNAME - MISO3'},
      },
      {
        path: 'orders/summary/:display_id/edit',
        component: ServiceOrderEditComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          template: 'half-page',
          pageTitle: 'Edit - Orders DISPLAYID - Services - TENANTNAME - MISO3'
        }
      },
      /*
      {
        path: 'single/create',
        component: ServiceSimpleCreateComponent,
        canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Create Single - Services - TENANTNAME - MISO3'},
      
        // children: [
        //   {
        //     path: '',
        //     component: VendorProductComponent,
        //     pathMatch: 'full',
        //     canActivate: [TenantUserAuthGuard],
        //     data: {pageTitle: 'Create Single - Services - TENANTNAME - MISO3'},
        //   }
        // ]
      },*/
      // {
      //   path: 'single/create',
      //   component: ServiceSingleCreateComponent,
      //   canActivate: [TenantUserAuthGuard],
      //   children: [
      //     {path: '', redirectTo: 'vendor-and-product', pathMatch: 'full'},
      //     {
      //     //   path: 'vendor-and-product', component: VendorProductComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
      //     //   data: {pageTitle: 'Vendor and Product - Create Single - Services - TENANTNAME - MISO3'},
      //     // },
      //     // {
      //     //   path: 'financials', component: ServiceFinancialsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
      //     //   data: {pageTitle: 'Add Financials - Create Single - Services - TENANTNAME - MISO3'},
      //     // },
      //     // {
      //     //   path: 'ownership', component: ServicesDetailsComponent, pathMatch: 'full', canActivate: [TenantUserAuthGuard],
      //     //   data: {pageTitle: 'Ownership - Create Single - Services - TENANTNAME - MISO3'},
      //     // },
      //     {
      //       path: 'terms-and-conditions',
      //       component: TermsAndConditionsComponent,
      //       pathMatch: 'full',
      //       canActivate: [TenantUserAuthGuard],
      //       data: {pageTitle: 'Terms and Conditions - Create Single - Services - TENANTNAME - MISO3'},
      //     },
      //   ]
      // },
      {
        path: ':display_id', component: MiServiceDetailComponent, canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Details - Service DISPLAYID - MISO3'},
        children: [
          {path: 'cancellation-instructions', component: ModalCancellationComponent}
        ]
      },
      {
        path: ':display_id/start-missing-data-compose',
        redirectTo: ':display_id/account-rep/request-missing-data',
        pathMatch: 'full'
      },
      {
        path: ':display_id/start-re-assessment-compose',
        redirectTo: ':display_id/requestor/re-assessment',
        pathMatch: 'full'
      },
      {
        path: ':display_id/start-quote-compose',
        redirectTo: ':display_id/re-quote',
        pathMatch: 'full',
      },
      {
        path: ':display_id/request-missing-data', component: RequestMissingDataComponent, canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Missing Data - Email - Service DISPLAYID - MISO3'}
      },
      {
        path: ':display_id/re-quote', component: ReQuoteEmailComponent, canActivate: [TenantUserAuthGuard],
        data: {
          template: 'half-page',
          pageTitle: 'Quote - Pre Email - Service DISPLAYID - MISO3'
        },
      },
      {
        path: ':display_id/miso-quote', component: QuoteMisoMarketplaceComponent, canActivate: [TenantUserAuthGuard],
        data: {
          template: 'half-page',
          pageTitle: 'Miso Quote - Email - Service DISPLAYID - MISO3'
        },
      },
      {
        path: ':display_id/:email-type/email-sent',
        data: {
          template: 'half-page',
          pageTitle: 'Email Sent - Service DISPLAYID - MISO3'
        },
        component: EmailSentComponent,
        canActivate: [TenantUserAuthGuard]
      },
      {
        path: ':display_id/request-a-quote', component: RequestAQuoteComponent, canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Request A Quote - Email - Service DISPLAYID - MISO3'},
      },
      {
        path: ':display_id/request-a-quote/:next-route', component: RequestAQuoteComponent, canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Request A Quote - Email - Service DISPLAYID - MISO3'},
      },
      {
        path: ':display_id/re-assessment', component: ReAssessmentEmailComponent, canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Re Assessment - Email - Service DISPLAYID - MISO3'},
      },
      {
        path: ':display_id/cancellation-request', component: CancellationReqeustEmailComponent, canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Cancellation Request - Email - Service DISPLAYID - MISO3'},
      },
      {
        path: ':display_id/account-rep/:type',
        data: {
          template: 'half-page',
          pageTitle: 'Account Rep - Pre Email - Service DISPLAYID - MISO3'
        },
        component: AccountRepEmailComponent,
        canActivate: [TenantUserAuthGuard]
      },
      {
        path: ':display_id/requestor/:type',
        data: {
          template: 'half-page',
          pageTitle: 'Requestor - Pre Email - Service DISPLAYID - MISO3'
        },
        component: ServiceRequestorEmailComponent,
        canActivate: [TenantUserAuthGuard]
      },
      {
        path: ':display_id/delete', component: MiServiceDeleteComponent, canActivate: [TenantUserAuthGuard], // SuperUserAuthGuard
        data: {pageTitle: 'Delete - Service DISPLAYID - MISO3'},
      }
    ]
  },
  /* PARENT SERVICES */
  {
    path: 'parent-services',
    canActivate: [TenantUserAuthGuard],
    data: {pageTitle: 'Parent Service - MISO3'},
    children: [
      {
        path: '',
        redirectTo: '/services',
        pathMatch: 'full'
      },
      {
        path: 'create',
        component: ParentServicesCreateComponent,
        canActivate: [TenantUserAuthGuard],
        data: {pageTitle: 'Create - Parent Service - MISO3'},
      },
      {
        path: ':display_id',
        component: ParentServiceDetailPageComponent,
        children: [
          {
            path: 'cancellation-instructions',
            component: ParentModalCancellationComponent,
            data: {pageTitle: 'Cancellation Instructions - Parent Service DISPLAYID - MISO3'},
          },
          {
            path: '',
            redirectTo: 'details',
            pathMatch: 'full'
          },
          {
            path: 'add-service',
            component: ParentServiceAddServiceComponent,
            canActivate: [TenantUserAuthGuard],
            pathMatch: 'full',
            data: {pageTitle: 'Add Service - Parent Service DISPLAYID - MISO3'},
          },
          {
            path: 'delete',
            component: ParentServiceDeleteComponent,
            canActivate: [TenantUserAuthGuard],
            pathMatch: 'full',
            data: {pageTitle: 'Delete - Parent Service DISPLAYID - MISO3'},
          },
          {
            path: 'details',
            component: ParentServiceDetailsComponent,
            canActivate: [TenantUserAuthGuard],
            pathMatch: 'full',
            data: {pageTitle: 'Details - Parent Service DISPLAYID - MISO3'},
          },
          {
            path: 'edit',
            component: ParentServiceEditComponent,
            canActivate: [TenantUserAuthGuard],
            pathMatch: 'full',
            data: {pageTitle: 'Edit - Parent Service DISPLAYID - MISO3'},
          },
          {
            path: 'get-quote',
            component: ParentServiceGetQuoteComponent,
            canActivate: [TenantUserAuthGuard],
            pathMatch: 'full',
            data: {pageTitle: 'Get Quote - Parent Service DISPLAYID - MISO3'},
          },
          {
            path: 'remove-service',
            component: ParentServiceRemoveServiceComponent,
            canActivate: [TenantUserAuthGuard],
            pathMatch: 'full',
            data: {pageTitle: 'Remove Service - Parent Service DISPLAYID - MISO3'},
          }
        ]
      },
      {
        path: ':display_id/start-missing-data-compose',
        redirectTo: ':display_id/account-rep/request-missing-data',
        pathMatch: 'full',
      },
      {
        path: ':display_id/start-re-assessment-compose',
        redirectTo: ':display_id/requestor/re-assessment',
        pathMatch: 'full',
      },
      {
        path: ':display_id/start-quote-compose',
        redirectTo: ':display_id/re-quote',
        pathMatch: 'full',
      },
      {
        path: ':display_id/account-rep/:type',
        component: ParentServiceAccountRepEmailComponent,
        data: {
          template: 'half-page',
          pageTitle: 'Account Rep - Pre Email - Parent Service DISPLAYID - MISO3'
        },
        canActivate: [TenantUserAuthGuard],
        pathMatch: 'full',
      },
      {
        path: ':display_id/request-missing-data',
        component: ParentRequestMissingDataComponent,
        data: {
          template: 'half-page',
          pageTitle: 'Missing Data - Email - Parent Service DISPLAYID - MISO3'
        },
        canActivate: [TenantUserAuthGuard],
        pathMatch: 'full',
      },
      {
        path: ':display_id/re-quote',
        component: ReQuoteEmailComponent,
        data: {
          template: 'half-page',
          pageTitle: 'Quote - Pre Email - Parent Service DISPLAYID - MISO3'
        },
        canActivate: [TenantUserAuthGuard],
        pathMatch: 'full',
      },
      {
        path: ':display_id/request-a-quote',
        component: ParentRequestAQuoteComponent,
        data: {
          template: 'half-page',
          pageTitle: 'Request A Quote - Email - Parent Service DISPLAYID - MISO3'
        },
        canActivate: [TenantUserAuthGuard],
        pathMatch: 'full',
      },
      {
        path: ':display_id/:email-type/email-sent',
        data: {
          template: 'half-page',
          pageTitle: 'Email Sent - Parent Service DISPLAYID - MISO3'
        },
        component: EmailSentComponent,
        canActivate: [TenantUserAuthGuard]
      },
      {
        path: ':display_id/request-a-quote/:next-route',
        component: ParentRequestAQuoteComponent,
        data: {
          template: 'half-page',
          pageTitle: 'Request A Quote - Email - Parent Service DISPLAYID - MISO3'
        },
        canActivate: [TenantUserAuthGuard],
        pathMatch: 'full',
      },
      {
        path: ':display_id/miso-quote',
        component: ParentQuoteMisoMarketplaceComponent,
        data: {
          template: 'half-page',
          pageTitle: 'Miso Quote - Email - Parent Service DISPLAYID - MISO3'
        },
        canActivate: [TenantUserAuthGuard],
        pathMatch: 'full',
      },
      {
        path: ':display_id/requestor/:type',
        data: {
          template: 'half-page',
          pageTitle: 'Requestor - Pre Email - Parent Service DISPLAYID - MISO3'
        },
        component: ParentRequestorEmailComponent,
        canActivate: [TenantUserAuthGuard]
      },
      {
        path: ':display_id/re-assessment',
        component: ParentReAssessmentEmailComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          pageTitle: 'Re Assessment - Email - Parent Service DISPLAYID - MISO3'
        },
      },
      {
        path: ':display_id/cancellation-request',
        component: ParentCancellationReqeustEmailComponent,
        canActivate: [TenantUserAuthGuard],
        data: {
          pageTitle: 'Cancellation Request - Email - Parent Service DISPLAYID - MISO3'
        }
      }
    ]
  },
  /* STYLE GUIDE */
  {
    path: 'style-guide',
    loadChildren: () => import('./pages/style-guide/style-guide.module').then(m => m.StyleGuideModule),
    data: {
      pageTitle: 'Style Guide - MISO3'
    }
  },
  /* SERVICE GROUP*/
  {
    path: 'groups',
    loadChildren: () => import('./pages/service-groups/service-groups.module').then(m => m.ServiceGroupsModule)
  },
  /* VENDORS */
  {
    path: 'vendor',
    redirectTo: 'vendors',
    pathMatch: 'full'
  },
  {
    path: 'business-units',
    loadChildren: () => import('./pages/business-units/business-units.module').then(m => m.BusinessUnitsModule)
  },
  {
    path: 'vendors',
    loadChildren: () => import('./pages/vendor/vendor.module').then(m => m.VendorModule)
  },
  {
    path: 'addresses',
    loadChildren: () => import('./pages/addresses/addresses.module').then(m => m.AddressesModule)
  },
  {
    path: 'bulk-uploads',
    loadChildren: () => import('./pages/bulk-uploads/bulk-uploads.module').then(m => m.BulkUploadsModule)
  },
  // {
  //   path: 'bulk-uploads-v2',
  //   loadChildren: () => import('./pages/bulk-uploads-v2/bulk-uploads.module').then(m => m.BulkUploadsModuleV2)
  // },
  {
    path: 'manage',
    loadChildren: () => import('./pages/manage/manage.module').then(m => m.ManageModule)
  },
  {
    path: 'data-integrity',
    loadChildren: () => import('./pages/data-Integrity/data-integrity.module').then(m => m.DataIntegrityModule)
  },

  
  /* RESOLUTION */
  // {
  //   path: 'normalization',
  //   loadChildren: () => import('./pages/resolution/resolution.module').then(m => m.ResolutionModule)
  // },
  /* DATA IMPORT */
  {
    path: 'data-import',
    component: DataImportComponent,
    canActivate: [TenantUserAuthGuard],
    data: {pageTitle: 'Data Import - TENANTNAME - MISO3'},
    pathMatch: 'full',
  },
  {
    canActivate: [TenantUserAuthGuard],
    component: ApiIntegrationComponent,
    data: {pageTitle: 'API Integration - TENANTNAME - MISO3'},
    path: 'data-import/api-integration',
    pathMatch: 'full'
  },
  {
    canActivate: [TenantUserAuthGuard],
    component: UploadDocumentComponent,
    data: {pageTitle: 'Document Uploads - TENANTNAME - MISO3'},
    path: 'data-import/upload',
    pathMatch: 'full'
  },
  /* ALERTS */
  /*
  {
    path: 'alerts',
    loadChildren: () => import('./pages/alerts/alert.module').then(m => m.AlertModule)
  },
  */
  /* AUDIT */
  {
    path: 'audit',
    loadChildren: () => import('./pages/audit/audit.module').then(m => m.AuditModule)
  },
  
  // {
  //   path: 'billing',
  //   loadChildren: () => import('./pages/miso3-account/miso3-account.module').then(m => m.Miso3AccountModule)
  // },

  {
    path: 'tag',
    loadChildren: () => import('./pages/tag-manager/tag-manager.module').then(m => m.TagManagerModule)
  },
  
  /* SUNDRY */
  {path: 'account-locked', component: AccountLockedComponent, data: {show_footer: false}},
  {path: 'external-download', component: DownloadsComponent, data: {show_footer: false}},
  {path: '404', component: NotFoundComponent, data: {show_footer: false}},
  {path: '403', component: ForbiddenComponent, data: {show_footer: false}},
  {path: 'accept-terms-and-conditions', component: AcceptTermsAndConditionsComponent, data: {show_footer: false}},
  {path: '**', redirectTo: '/404'}
];

export {appRoutes};
