import {Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { passwordValidator, passwordMatchValidator } from 'src/shared/shared.validators';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { Miso3baseService } from 'src/libs/api2/miso3base-v2/miso3base.service';
import { ModalRef } from '@independer/ng-modal';
import { takeUntil } from 'rxjs/operators';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { combineLatest } from 'rxjs';
import { RoleV2 } from 'src/libs/api2/types/RolesV2.interface';
import { filterRole } from 'src/common/utils/UserAccess';
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import { Store, select } from '@ngrx/store';
import { TenantUserAccess } from 'src/libs/api2/types/TenantUser.interface';
import { TenantUserManagementService } from 'src/libs/api/tenant/tenantUserManagement.service';
import { ToastrService } from 'ngx-toastr';
import * as userReducer from 'src/libs/user-store/state/reducers';
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";

export enum CreateEditUmMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

@Component({
  templateUrl: './users-access-form-modal.component.html',
  styleUrls: ['./users-access-form-modal.component.scss']
})

export class UsersAccessFormModalComponent extends AbstractPageComponent implements OnInit {
  title: string = 'Access Management';
  userId: string = '';
  canEditRoleOnBu: boolean = true;
  resetForm: FormGroup;
  editId: string = '';
  loading: boolean = false;
  loadError: any;
  successMessage: string = null;
  tenantUserBUTree: any;
  roles: RoleV2[] = [];
  noneAccessId: any = {};
  userAccess: TenantUserAccess;
  showResetPass: boolean = false;
  password: string = null;
  passwordConfirm: string = null;
  email: string = null;
  userData: any;
  mode: CreateEditUmMode = CreateEditUmMode.EDIT;
  roleByName: any = {};
  cost_center_id: string = null;
  mi_access_level: number = 0;
  accessLevelOptions = [
    { value: '0', label: 'Level 0', disable: false },
    { value: '1', label: 'Level 1', disable: false },
    // { value: '2', label: 'Level 2', disable: true },
    { value: '3', label: 'Level 3', disable: false }
    // { value: '4', label: 'Level 4', disable: true },
  ];
  superAdminDisabled: boolean = true;
  restrictAccess: boolean = false;

  constructor(
    private miso3baseService: Miso3baseService,
    private contactV2Service: ContactV2Service,
    private tenantUserManagementService: TenantUserManagementService,
    private formBuilder: FormBuilder,
    private modal: ModalRef,
    private store: Store<appDataReducer.State>,
    private toastr: ToastrService
    ) {
      super();
    }

  ngOnInit(): void {

    this.resetForm = this.formBuilder.group({
      'password': [null, Validators.compose([Validators.required, passwordValidator])],
      'password_confirm': [null, Validators.compose([Validators.required, passwordValidator])]
    }, {
      validator: passwordMatchValidator.matchPassword // your validation method
    });
    const fatchuserAccess = this.contactV2Service.getTenantUserAccess(this.editId);
    const fetchRoles = this.miso3baseService.getRoles();
    const fetchTenant = this.store.pipe(
      select(appDataReducer.getAppData)
    );
    const currentUser = this.store.pipe(
      select(userReducer.getUserState)
    );

    combineLatest(fatchuserAccess, fetchRoles, fetchTenant, currentUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([userAccess, roles, tenant, currentUser]) => {
        roles.map(role => {
          this.roleByName[role.role_name] = role.id;
        })
        
        this.userAccess = userAccess;
        if(!currentUser.secureUser.isSuperUser() && (roles.find(role => role.id == this.userAccess.tenant.role_id).role_number < currentUser.secureUser.tenantAccessLevel(FrontEndSettingsUtil.getDomain()))) {
          this.restrictAccess = true;
        }
        if(currentUser.secureUser.isSuperUser() || currentUser.secureUser.tenantAccessLevel(FrontEndSettingsUtil.getDomain()) == 5) {
          this.superAdminDisabled = false;
        }
        this.roles = filterRole(roles, tenant.tenantData && tenant.tenantData.v2? tenant.tenantData.v2.miso_business_unit : false);
        this.noneAccessId = this.roles.find(role => role.role_name === "None");
        this.tenantUserBUTree = userAccess.tenant;
        this.cost_center_id = userAccess.cost_center_id;
        this.canEditRoleOnBu = true;
        this.mi_access_level = userAccess.tenant.mi_access_level ?  userAccess.tenant.mi_access_level : 0
      },
      (error) => {
        this.loadError = error;
        this.successMessage = null;
      });
  }

  close(cancelled: boolean = false) {
    this.modal.close({
      error: !cancelled ? this.loadError : null,
      message: !cancelled ? this.successMessage : null,
      updated:!cancelled ? true : false
    });
  }

  onFormSubmit(): void {
    this.loading = true;
    let newTenantAccess = {
      ...this.userAccess,
      cost_center_id: this.cost_center_id,
      tenant: this.tenantUserBUTree
    }

    this.contactV2Service.saveTenantUserAccess(newTenantAccess)
      .pipe(takeUntil(this.destroy$))
      .subscribe(access =>{
        this.successMessage = `The user access has been successfully updated`;
        this.toastr.success(`${this.userData.user.first_name} ${this.userData.user.last_name} access level has been successfully updated!`);
        this.close();
      },
      (error) => {
        this.loadError = error;
        this.successMessage = null;
      });
  }

  accessChangeHandler(event: any){
    this.mi_access_level = event
    this.tenantUserBUTree.mi_access_level = event
  }

  onPasswordSubmit(formData): void {
    this.loadError = null;
    this.successMessage = null;
    this.password = formData.password;
    this.passwordConfirm = formData.password_confirm;
    if (this.password !== this.passwordConfirm) {
      this.loadError = `Passwords don't match`;
      return;
    } else {
      this.loading = true;
      const data = {
        business_unit_id: null,
        confirm_password: this.passwordConfirm,
        //email: this.userData.email,
        email: this.userData.user.email,
        password: this.password
      };
      this.tenantUserManagementService.changePassword(data)
        .toPromise()
        .then(user => {
          this.loading = false;
          this.successMessage = 'Password has been changed successfully!';
          this.toastr.success(`Password has been changed successfully!`);
          this.showResetPass = false;
        })
        .catch((error) => {
          this.loadError = error;
          this.successMessage = null;
          this.loading = false;
        });
    }
  }

  // showResetPassword(e) {
  //   if(this.editId && this.userAccess.tenant.id){
  //     this.contactV2Service.getTenantUser(this.editId, this.userAccess.tenant.id)
  //       .pipe(
  //         takeUntil(this.destroy$)
  //       )
  //       .subscribe((user) => {
  //         //this.userData = user;
  //         this.userData.user = user;
  //         this.showResetPass = true;  
  //       },
  //       (e) => {
  //         this.loadError = e;
  //         this.successMessage = null;
  //       }
  //     );
  //   } 
  // } Commentted it out because the set pwd button is removed.

  checkboxChange(evt){
    if((this.superAdminDisabled == true) && (this.tenantUserBUTree.role_id===this.roleByName['Client Super Admin'])) {
      return;
    }
    if(
      this.tenantUserBUTree.role_id===this.roleByName['Client Super Admin']||
      this.tenantUserBUTree.role_id===this.roleByName['Client Admin'] ){
      this.tenantUserBUTree.divisions = this.tenantUserBUTree.divisions.map(div => {
        div.role_id = this.roleByName['Client Admin'];
        div.sites = div.sites.map(site => {
          site.role_id = this.roleByName['Client Admin'];
          return site;
        })
        return div;
      })
    } else
    if(this.tenantUserBUTree.role_id===this.roleByName['Client User'] ){
      this.tenantUserBUTree.divisions = this.tenantUserBUTree.divisions.map(div => {
        if(div.role_id !== this.roleByName['Client Admin']){
          div.role_id = this.tenantUserBUTree.role_id;
        };
        div.sites = div.sites.map(site => {
          if(site.role_id !== this.roleByName['Client Admin']){
            site.role_id = div.role_id;
          }
          return site;
        })
        return div;
      })
    } 
    else {
      this.tenantUserBUTree.divisions = this.tenantUserBUTree.divisions.map(div => {
        if( div.role_id !== this.roleByName['None']){
          div.sites = div.sites.map(site => {
            if(site.role_id !== this.roleByName['Client Admin']){
              site.role_id = div.role_id;
            }  
            return site;
          })
        }
        return div;
      })
    }

  }

}
