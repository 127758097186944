import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'custom-pager',
  template: `
    <nav *ngIf="totalPages > 1">
      <ul class="pagination">
        <!-- First and Previous buttons -->
        <li class="page-item" [class.disabled]="page === 1 || loading">
          <a class="page-link" (click)="setPage(1)" [class.disabled]="loading">&laquo;&laquo;</a>
        </li>
        <li class="page-item" [class.disabled]="page === 1 || loading">
          <a class="page-link" (click)="prevPage()" [class.disabled]="loading">&laquo;</a>
        </li>

        <!-- Page number buttons -->
        <li *ngFor="let p of getPageNumbers()" class="page-item" [class.active]="p === page" [class.disabled]="loading">
          <a class="page-link" (click)="setPage(p)" [class.disabled]="loading">{{ p }}</a>
        </li>

        <!-- Next and Last buttons -->
        <li class="page-item" [class.disabled]="page === totalPages || loading">
          <a class="page-link" (click)="nextPage()" [class.disabled]="loading">&raquo;</a>
        </li>
        <li class="page-item" [class.disabled]="page === totalPages || loading">
          <a class="page-link" (click)="setPage(totalPages)" [class.disabled]="loading">&raquo;&raquo;</a>
        </li>
      </ul>
    </nav>
  `,
  styles: [`
    .pagination {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .page-item {
      margin: 0 0px;
    }

    .page-item.disabled .page-link {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }

    .page-item.active .page-link {
      background-color: #007bff;
      border-color: #007bff;
      color: white;
    }

    .page-link {
      position: relative;
      display: block;
      padding: 6px;
      margin-left: 1px;
      line-height: 1;
      color: #007bff;
      background-color: #fff;
      border: 1px solid #dee2e6;
      text-decoration: none;
    }

    .page-link:hover {
      background-color: #e9ecef;
    }

    .disabled .page-link {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `]
})
export class CustomPagerComponent implements OnChanges {
  @Input() perPage: number;
  @Input() totalItems: number;
  @Input() page: number;
  @Output() pageChange = new EventEmitter<number>();

  totalPages: number = 0;
  loading: boolean = false; // State to track loading

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalItems || changes.perPage) {
      // Compute totalPages correctly
      this.totalPages = Math.ceil(this.totalItems / this.perPage);
      // Ensure the current page is within the bounds of the total pages
      if (this.page > this.totalPages) {
        this.setPage(this.totalPages);
      }

      this.cdr.detectChanges(); // Trigger change detection if needed
    }
  }

  setPage(page: number): void {
    if (page >= 1 && page <= this.totalPages && !this.loading) {
      this.loading = true; // Disable all buttons while loading
      this.page = page;
      this.pageChange.emit(this.page);

      // Simulating a page change delay
      setTimeout(() => {
        this.loading = false; // Re-enable the buttons after page change
      }, 1000); // Adjust the delay as necessary
    }
  }

  prevPage(): void {
    if (this.page > 1) {
      this.setPage(this.page - 1);
    }
  }

  nextPage(): void {
    if (this.page < this.totalPages) {
      this.setPage(this.page + 1);
    }
  }

  getPageNumbers(): number[] {
    const pages = [];
    const startPage = Math.max(1, this.page - 2);
    const endPage = Math.min(this.totalPages, this.page + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }
}
