<div class="service-details-header">

  <div class="header-item">
    <mi-service-details-header-vendor>
    </mi-service-details-header-vendor>
  </div>

  <div class="header-item">
    <mi-service-details-group-economics activeServiceCount="{{activeServiceCount}}">
    </mi-service-details-group-economics>
  </div>

  <div class="header-item"
    [ngClass]="{ 'greyOut': serviceDetails.general.family_designation === 'Child' && serviceDetails.events_and_service_status.next_event === 'TERMINATED' && serviceDetails.events_and_service_status.service_status === 'INACTIVE' }">
    <mi-service-details-header-group-events>
    </mi-service-details-header-group-events>
  </div>

</div>
