import { Link } from 'src/shared/ng-models/Link.interface';
import { Component } from '@angular/core';
import { ITenantConfig } from 'src/libs/api/settings/settings.service';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { TenantMiServiceService } from 'src/libs/api/tenant/tenantMiService.service';
import { ToastrService } from 'ngx-toastr';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import { BusinessUnitContactV2 } from 'src/libs/api2/types/BussinessUnitContactV2.interface';
/* import { TenantUserManagementService } from 'src/libs/api/tenant/tenantUserManagement.service'; */

@Component({
  selector: 'mi-variance-snapshots',
  template: `
    <div *ngIf="metaBaseDashboardId; else loading">
      <mi-page-header 
        title="Variance Snapshots" 
        [breadcrumbs]="breadCrumbs" 
        [links]="headerLinks">
      </mi-page-header>
        <mi-opacity-loader [ready]="!showLoader">
          <iframe 
            class="mt-3 iframe-container" 
            [id]="'metabase-iframe-' + metaBaseDashboardId" 
            src="" 
            title="metabase">
          </iframe>
        </mi-opacity-loader>
    </div>
    <ng-template #loading>
      <mi-loading-animation></mi-loading-animation>
    </ng-template>
  `,
  styles: [
    `
      .iframe-container {
        width: 100%;
        border: 0.1px solid lightgray;
        height: 99vh;
        padding: 0;
        border-radius: 5px;
      }
    `,
  ],
})
export class VarianceSnapshotsComponent {
  metaBaseDashboardId = null;
  showLoader = true;
  tenantId = null;
  frontEndSettings = FrontEndSettingsUtil.get()?.TENANT_REPORTS || [];
  headerLinks: Link[] = [];
  selectedOptionConfigId = null;
  /* tenant2Name: string; */
  tenantConfig: ITenantConfig[] = [];
  allTenants: BusinessUnitContactV2[];

  breadCrumbs: Link[] = [
    { label: 'Dashboard', url: '/', internal: true },
    { label: 'Variance Snapshots', url: '/variance-snapshots', internal: true },
  ];

  constructor(
    private tenantMiServiceService: TenantMiServiceService,
    private toastr: ToastrService,
    private reportV2Service: ReportV2Service,
    /* private TenantUserManagementService: TenantUserManagementService */
  ) { }

  ngOnInit() {
    const report = this.frontEndSettings.find(({ reportName }) => reportName === 'Variance Summary');

    if (report) {
      this.metaBaseDashboardId = report.metabaseDashboardId;
      this.tenantId = report.tenantId;
      this.getMatchingTenantsDetails();
    } else {
      console.warn("Report with name 'Variance Summary' not found.");
    }
  }

  /* getAllTenants() {
     this.TenantUserManagementService
       .getBusinessUnits()
       .toPromise()
       .then((response) => {
         this.allTenants = response;
         this.getMatchingTenantsDetails();
       })
       .catch((e) => console.warn("Facing issue while fetching tenants"));
   } */

  getMatchingTenantsDetails() {
    this.tenantMiServiceService.getAlignTenantConfig(this.tenantId).subscribe(
      ({ configurations }) => {
        this.tenantConfig = configurations;
        this.selectedOptionConfigId = configurations[0]?.config_id || null;
        /*  this.tenant2Name = this.allTenants.filter((tenant) => tenant.id === configurations[0]?.tenant_id2)[0].name;
          */

        this.getMetaBaseURL();
      },
      (error) => this.toastr.error(`${error}`)
    );
  }

  getMetaBaseURL() {
    this.showLoader = true;
    this.reportV2Service
      .getVarianceSnapshotResponse(this.selectedOptionConfigId, this.metaBaseDashboardId)
      .then((res) => {
        const iframe = document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`);
        iframe?.setAttribute(
          'src',
          res.replace('#bordered=true', '#bordered=false').replace('&titled=true', '&titled=false')
        );
        iframe?.addEventListener('load', () => (this.showLoader = false));
      });
  }
}
