import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { ServiceListingColumnsCustom, ServiceListingColumnsInitial, ServiceV2Filter, ServiceV2Request, ServiceV2Response, ServiceV2ResponseData } from "src/shared/models/ServiceV2";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as ServiceV2Reducer from '../../../../shared/state/service-v2/reducers';
import * as ServiceV2Actions from '../../../../shared/state/service-v2/actions';
import { ServiceV2Service } from "src/libs/api2/service-v2/service-v2.service";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import * as DocumentV2Reducer from '../../../../shared/state/document-v2/reducers';
import * as DocumentV2Actions from '../../../../shared/state/document-v2/actions';
import { DocumentsV2ListRequest, DocumentsV2ListResponse } from "src/shared/models/DocumentV2";
import { DocumentV2Service } from "src/libs/api2/document-v2/document-v2.service";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'mi-advanced-search-header-v2',
  templateUrl: './advanced-search-header-v2.component.html',
  styleUrls: ['./advanced-search-header-v2.component.scss']
})

export class AdvancedSearchHeaderV2Component extends AbstractPageComponent {
  @Input() saveSearch: boolean = false;
  @Input() searchTypeTitle: string = 'Search Services';
  @Input() showTotalMrc: boolean = true;
  @Input() searchType: string = 'SERVICE';
  @Input() showButton: boolean = false;
  @Input() buttonLabel: string;
  @Input() buttonUrl: string;
  @Input() showDownloadButton: boolean = true;
  serviceData: ServiceV2Response;
  activeButton: boolean = false;
  activeButtonSelected: boolean = false;
  total = 0;
  total_mrc = 0;
  loading = true;
  q: string = '';
  quantityLabel: string = "";
  cmrcLoaded: boolean = false;
  totalLoaded: boolean = false;

  serviceRequest: ServiceV2Request = {
    pageSize: 0,
    page_number: 0,
    tenant_id: "",
    search: "",
    sort: {},
    filters: []
  };

  documentData: DocumentsV2ListResponse;
  documentRequest: DocumentsV2ListRequest = {
    business_unit_id: ""
  };

  @ViewChild('searchInputSVC', { static: false }) searchInputSVC!: ElementRef;
  private filterSvc: Subject<string> = new Subject<string>();
  showSVCclearButton: boolean = false;
  @Input() showSVCSearch: boolean = true;

  constructor(
    private serviceV2Service: ServiceV2Service,
    private documentV2Service: DocumentV2Service,
    private store: Store<any>
  ) {
    super();
  }

  ngOnInit(): void {
    this.filterSvc.pipe(debounceTime(500)).subscribe(searchValue => {      
      this.svcFilterSearch(searchValue);
    });

    switch(this.searchType) {
      case "SERVICE":
        this.getServiceData();
        break;
      case "DOCUMENT":
        this.getDocumentData();
        break;
    }
    //console.log("data", this.data);    
  }

  getServiceData(): void {
    this.store.select(ServiceV2Reducer.getServiceV2Data)
    .subscribe(
      data => {
        console.log("store data", data);
        this.serviceData = data.results;
        console.log("serviceData.total_mrc", this.serviceData.total_mrc);
        if(this.serviceData.total_mrc != null && this.serviceData.total_mrc != undefined) {
          console.log("has total mrc");
          this.total_mrc = Math.floor(this.serviceData.total_mrc);
          this.cmrcLoaded = true;
        }
        else {
          this.cmrcLoaded = false;
        }
        
        if(this.serviceData.total != null && this.serviceData.total != undefined) {
          console.log("has total count");
          this.total = this.serviceData.total;
          this.totalLoaded = true;
        }
        else {
          this.totalLoaded = false;
        }
        if(this.total == 0) {
          this.quantityLabel = "No Services Found";
        }
        else if(this.total == 1) {
          this.quantityLabel = "1 Service Found";
        }
        else {
          this.quantityLabel = this.total.toLocaleString() + " Services Found";
        }        

        this.activeButton = true;
        let serviceStatusFound: boolean = false;

        if(this.serviceData.filters) {
          this.serviceData.filters.forEach(filter => {
            if(filter.field_name == "service_status" && filter.values.indexOf("active") != -1) {
              this.activeButtonSelected = true;
              serviceStatusFound = true;
            }
          })
        }

        if(!serviceStatusFound) {
          this.activeButtonSelected = false;
        }
        
        this.q = this.serviceData.search;
        const svcSearch = this.serviceData.filters.find((filter)=> filter.field_name === "parent_service_group_search");
        if(svcSearch === undefined) {
          this.showSVCclearButton = false;
          if (this.searchInputSVC) {
            this.searchInputSVC.nativeElement.value = "";
          }
        }
        

        this.loading = false;
      }
    );
  }

  getDocumentData(): void {
    this.store.select(DocumentV2Reducer.getDocumentV2Data)
    .subscribe(
      data => {
        console.log("store data doc", data);
        this.documentData = data.results;
        this.total = this.documentData.total;

        if(this.total == 0) {
          this.quantityLabel = "No Documents Found";
        }
        else if(this.total == 1) {
          this.quantityLabel = "1 Document Found";
        }
        else {
          this.quantityLabel = this.total.toLocaleString() + " Documents Found";
        }
        
        this.q = this.documentData.search;

        this.loading = false;
      }
    );
  }

  setDefaultServiceRequestParams(): void {
    this.serviceRequest.pageSize = 25;
    this.serviceRequest.page_number = 0;
    this.serviceRequest.tenant_id = FrontEndSettingsUtil.getDomain();
    this.serviceRequest.sort = this.serviceData.sort;
    this.serviceRequest.filters = this.serviceData.filters;
    this.serviceRequest.search = this.serviceData.search;
    //this.serviceRequest.cursor = 0;
    this.serviceRequest.columns = ServiceListingColumnsInitial;
  }

  setDefaultDocumentRequestParams(): void {
    this.documentRequest.size = 25;
    this.documentRequest.page_number = 0;
    this.documentRequest.business_unit_id = FrontEndSettingsUtil.getDomain();
    //this.request.sort = this.documentData.sort;
    //this.request.filters = this.documentData.filters;
    this.documentRequest.search = "";
    this.documentRequest.types = this.documentData.types;
  }

  clearSearch(): void {
    setTimeout(() => {
      switch(this.searchType) {
        case "SERVICE":
          this.setDefaultServiceRequestParams();
          this.serviceRequest.search = "";
          this.updateServices(this.serviceRequest);
          break;
        case "DOCUMENT":
          this.setDefaultDocumentRequestParams();
          this.documentRequest.search = "";
          this.updateDocuments(this.documentRequest);
          break;
      }    
    }, 1000);
  }

  onSearchSubmit(searchValue): void {
    console.log("searchValue", searchValue);
    switch(this.searchType) {
      case "SERVICE":
        this.setDefaultServiceRequestParams();
        this.serviceRequest.search = searchValue;
        this.updateServices(this.serviceRequest);
        break;
      case "DOCUMENT":
        this.setDefaultDocumentRequestParams();
        this.documentRequest.search = searchValue;
        this.updateDocuments(this.documentRequest);
        break;
    }    
  }

  svcFilterSearch(searchValue: string): void {
    // if (searchValue.trim() === "") {
    //   this.setDefaultServiceRequestParams();
    //   this.updateServices(this.serviceRequest);
    //   return;
    // }

    // console.log('here in the searchValue', searchValue);
    
  
    switch (this.searchType) {
      case "SERVICE":
        // console.log('here in the service');
        // console.log('here in the service');
        // console.log('here in the new searchValue', searchValue);

        if (searchValue) {
          this.setDefaultServiceRequestParams();
          let newFilter = {
            field_name: "parent_service_group_search",
            type: "option",
            is_custom_field: false,
            is_tag: false,
            is_location: false,
            values: ['SVC-' + searchValue]
          };
          const isParentServiceGroupFilterAvailable = this.serviceRequest.filters.some((filter) =>
            filter.field_name == "parent_service_group_search"
          )
          if (isParentServiceGroupFilterAvailable) {
            this.serviceRequest.filters.forEach((filter) => {
              if (filter.field_name === "parent_service_group_search") {
                filter.values = ['SVC-' + searchValue];
              }
            });
          } else {
            this.serviceRequest.filters.push(newFilter);
          }
        } else {
          // console.log('here in the else console');
          // this.serviceRequest.filters.forEach((filter) => {
          //   if (filter.field_name === "parent_service_group_search") {
          //     filter.values = ['SVC-' + ''];
          //   } 
          // });
          this.serviceRequest.filters = this.serviceRequest.filters.filter(
            (filter) => filter.field_name !== "parent_service_group_search"
          );

        }

        console.log('here svcFilterSearch before update service call', this.serviceRequest);
        this.updateServices(this.serviceRequest);
        break;

      case "DOCUMENT":
        this.setDefaultDocumentRequestParams();
        this.documentRequest.search = searchValue;
        this.updateDocuments(this.documentRequest);
        break;
    }
  }
  

  onSearchBySVC(searchValue: string): void {
    console.log('here onSearchBySVC searchValue', searchValue);
    if(searchValue.length >= 1) {
      this.filterSvc.next(searchValue);
      this.onSearchInputChange(searchValue);
    } else {
      this.clearSVCSearch();
    }
  }

  onSearchInputChange(event: any): void {
    this.showSVCclearButton = event.length > 0;
  }
  

  clearSVCSearch(): void {
    switch (this.searchType) {
      case "SERVICE":
        this.setDefaultServiceRequestParams();
        this.serviceRequest.filters = this.serviceRequest.filters.filter(
          (filter) => filter.field_name === "service_status" || filter.field_name !== "parent_service_group_search"
        );
        if (this.searchInputSVC) {
          this.searchInputSVC.nativeElement.value = "";
        }
        this.filterSvc.next('');
        // this.updateServices(this.serviceRequest);
        this.showSVCclearButton = false;
      break;
      case "DOCUMENT":
        this.setDefaultDocumentRequestParams();
        this.documentRequest.search = "";
        this.updateDocuments(this.documentRequest);
        break;
    }
  }  

  setLoadMoreStatus(data: any[]): void {
    if(data.length > 0) {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(true));
    }
    else {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(false));
    }
  }

  combineData(data1: ServiceV2ResponseData[], data2: ServiceV2ResponseData[]): any {
    let combinedData: ServiceV2ResponseData[] = [];
   
   data1.forEach((data, index) => {
    data.miso_service.custom_fields = data2[index].miso_service.custom_fields;
    data.miso_service.tags = data2[index].miso_service.tags;
   });

   return data1;
  }

  formatData(data: ServiceV2ResponseData[]): any {
    //console.log("formatData", data);

    data.map(service => {
      if(service.miso_service.tags.length > 0) {
        let spliceList: number[] = [];
        
        for(let i=0; i<service.miso_service.tags.length; i++) {
          let tagId = service.miso_service.tags[i].tag_id;
          for(let j=0; j<service.miso_service.tags.length; j++) {
            let comparedTagId = service.miso_service.tags[j].tag_id;
            //console.log("formatData tagId", tagId);
            //console.log("formatData comparedTagId", comparedTagId);
            if(j == i) continue;
            if((tagId == comparedTagId) && (spliceList.indexOf(i) == -1)) {
              service.miso_service.tags[i].tag_value += ", " + service.miso_service.tags[j].tag_value;
              spliceList.push(j);
            }
          }
        }

        //console.log("formatData spliceList", spliceList);

        spliceList.map(spliceIndex => {
          service.miso_service.tags.splice(spliceIndex, 1);
        });
      }
    });

    return data;
  }

  updateServices(request: ServiceV2Request): void {
    console.log("UPDATING SERVICES");
    this.store.dispatch(new ServiceV2Actions.SetSearchLoading(this.serviceData));
    this.serviceV2Service.getServices(request)
    .toPromise()
    .then((res: ServiceV2Response) => {
      console.log("res", res);
      this.setLoadMoreStatus([...res.data]);
      this.serviceData = res;
      this.serviceData.filters = request.filters;
      this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));
      this.store.dispatch(new ServiceV2Actions.SetSearchLoaded(this.serviceData));
      this.loading = false;
      this.serviceV2Service.getTotalCmrc(request)
      .toPromise()
      .then((totalCmrc: any) => {
        this.serviceData.total_mrc = totalCmrc.total_cmrc;
        this.serviceData.total = totalCmrc.total_service_count;
        this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));

        request.columns = ServiceListingColumnsCustom;
        this.serviceV2Service.getServices(request)
        .toPromise()
        .then((response: ServiceV2Response) => {
          let combinedData = this.combineData([...this.serviceData.data], [...response.data]);
          //console.log("combinedData", combinedData);
          let formattedData = this.formatData([...combinedData]);
          console.log("formattedData", formattedData);
          this.serviceData.data = formattedData;
          this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));
        })
        .catch(error => {
          console.log("error while getServices_2", error);
        });

      })
      .catch(error => {
        console.log("error while getCmrc", error);
      });
    })
    .catch(err => {

    });
  }

  updateDocuments(request: DocumentsV2ListRequest): void {
    console.log("UPDATING SERVICES");
    this.store.dispatch(new DocumentV2Actions.SetSearchLoading(this.documentData));
    this.documentV2Service.getDocuments(request)
    .toPromise()
    .then((res: DocumentsV2ListResponse) => {
      console.log("res", res);
      this.documentData = res;
      this.store.dispatch(new DocumentV2Actions.SetDocumentResults(this.documentData));
      this.store.dispatch(new DocumentV2Actions.SetSearchLoaded(this.documentData));
      this.loading = false;      
    })
    .catch(error => {
      console.log("error while getDocuments", error);
    });
  }

  setActiveFilter(): void {
    let newFilter: ServiceV2Filter = {
      field_name: "service_status",
      type: "option",
      is_custom_field: false,
      is_tag: false,
      is_location: false,
      values: [
        "active"
      ]
    };

    this.serviceRequest.filters.push(newFilter);
  }

  activeOnlyCheckbox(evt): void {
    this.loading = true;
    this.setDefaultServiceRequestParams();
    if (evt.target.checked) {
      this.setActiveFilter();
      //this.store.dispatch(new ASActions.AsFilterUpdate({active_status: ['ACTIVE']}));
    } 
    else {
      this.serviceRequest.filters.splice(this.serviceRequest.filters.indexOf(this.serviceRequest.filters.find(f => f.field_name == "service_status")), 1);
      //this.store.dispatch(new ASActions.AsFilterUpdate({active_status: []}));
    }
    this.updateServices(this.serviceRequest);
  }
}