<mi-page-header title="{{title}}" [breadcrumbs]="breadcrumbs" *ngIf="title">
</mi-page-header>

<div>
    <div class="tab-content-wrap">
        <!-- <mi-opacity-loader [ready]="isLoading"> -->
        <ng-container>
            <mi-search-header searchPlaceholder="Search User" (searchParamChanged)="searchParamChangeHandler($event)"
                [total]="totalRow" searchType="TENANT_USER" (clearSearch)="onClearSearch()" class="card" (userCreated)="onUserCreated($event)">
            </mi-search-header>
            <div class="results card mt-2">
                <mi-opacity-loader [ready]="isLoading && resultsLoaded">
                    <mi-sortable-table [isServerSideSortEnable]="true"
                        (serverSideSortHandler)="sortChangeHandler($event)" *ngIf="hits && hits.length" [rows]="hits"
                        [columns]="contactsColumns" searchType="TENANT_USER">
                    </mi-sortable-table>
                </mi-opacity-loader>
                <ng-container *ngIf="resultsLoaded">
                    <ng-container *ngIf="!hits || !hits.length">
                        <p>No matching results found.</p>
                    </ng-container>
                </ng-container>

                <ng-container>
                    <mi-pagination totalRow="{{ totalRow }}" pageNumber="{{ pageNumber }}" totalPages="{{totalPages}}"
                        (paginationChangeHandler)="this.paginationChangeHandler($event)">
                    </mi-pagination>
                </ng-container>
            </div>
        </ng-container>

    <!-- </mi-opacity-loader> -->
    </div>
</div>

<ng-template #mrcTmpl let-row="row" let-value="value">
    {{row.service_mrc_total | round | currency:'USD':'symbol':'1.2-2'}}
</ng-template>

<ng-template #mobileTmpl let-row="row" let-value="value">
    {{row.user.mobile_phone ? row.user.mobile_phone : "-"}}
</ng-template>

<ng-template #costCenterTmpl let-row="row" let-value="value">
    {{row.user.cost_center ? row.user.cost_center : "-"}}
</ng-template>

<ng-template #miMisoAccessTmpl let-row="row" let-value="value">
    Level {{ row.user.mi_access_level > 0 ? row.user.mi_access_level : 0}}
</ng-template>

<ng-template #statusTmpl let-row="row" let-value="value">
    {{row.user.is_active === true ? "Active": "Inactive"}}
</ng-template>

<ng-template #vendorTmpl let-row="row" let-value="value">
    <ng-container *ngIf="value">{{value.vendor.vendor_name}}</ng-container><br>
    <span *ngIf="value" class="second-line-value">
        {{'CONTACT_ROLES.'+value.role | translate}}
    </span>
</ng-template>

<ng-template #contactTmpl let-row="row" let-value="value">
    <ng-container>
        <div class="text-capitalize">{{row.user.first_name + " " + row.user.last_name}}</div>
    </ng-container>
</ng-template>

<ng-template #userAccessTmpl let-row="row" let-value="value">
    <div class="button-container">
        <button (click)="openUserAccessModal(row.user.id, row)" mi-button buttonTheme="dark-theme">
            Edit Access
        </button>
        <button (click)="openUserEditModal(row.user.id)" mi-button buttonTheme="dark-theme">
            Edit Profile
        </button>
    </div>
</ng-template>