<mi-opacity-loader [ready]="loaded">
  <div class="appliedSearchFilters card mt-1" [ngClass]="{'hidden': filters && filters.length == 0}">

    <div class="appliedSearchFilters--header note-info">
      <h2>{{'GLOBAL.SEARCH_FILTERS.FILTERS_TITLE'|translate}}</h2>
      <!-- <a class="removeAll"
        (click)="onRemoveAllClick()">
        {{'GLOBAL.SEARCH_FILTERS.REMOVE_ALL_FILTERS' | translate}}
      </a> -->
      <a class="removeAll" (click)="onRemoveAllClick()">
        {{'Reset Filters'}}
      </a>
    </div>

    <div class="appliedSearchFilters--filters">
      <ng-container *ngIf="filters.length">
        <mi-applied-search-filter-item-v2
          *ngFor="let filter of filters"
          [filter]="filter"
          [customField]="customFieldsByIDs"
          [tagById]="tagById"
          [businessUnits]="businessUnitsByIDs"
          [accountIdsByIDs]="accountIdsByIDs"
          [serviceGroupByIDs]="serviceGroupByIDs"
          [searchInputSVC]="searchInputSVC"
          [contactsByIDs]="contactsByIDs"
          (remove)="onRemoveClick($event)">
        </mi-applied-search-filter-item-v2>
      </ng-container>
    </div>

  </div>
</mi-opacity-loader>
