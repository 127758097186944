import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {AssociatedDocument} from '../../../shared/models/AssociatedDocument';
import {Observable} from 'rxjs';
import {TypeReferenceEnum} from '../../../shared/ng-models/TypeReference.enum';
import {BulkUpload} from '../../../shared/models/BulkUpload.interface';
import { map } from 'rxjs/operators'; 
@Injectable()
export class TenantBulkUploadService {

  private apiUrlUpload = '/{tenant}/bulk-upload';
  private apiUrlUploadGetByType = '/{tenant}/bulk-upload/get-list-by-type';
  private getBulkUploadRequestAPI = '_MICRO_SERVICE_/buquery/get-bulk-upload-requests';
  private getBulkUploadFileStatusRequestAPI = '_MICRO_SERVICE_/buquery/get-bulk-upload-statuses';
  private getBulkUploadFileServicesRequestAPI = '_MICRO_SERVICE_/buquery/get-bulk-upload-services';
  private getBulkUploadFileServicesErrorsRequestAPI = '_MICRO_SERVICE_/buquery/get-bulk-upload-ingestion-errors';
  private getBulkUploadFileIngestionPendingErrorsRequestAPI = '_MICRO_SERVICE_/buquery/get-pending-resolutions';
  private getBulkUploadFileIngestionErrorsRequestAPI = '_MICRO_SERVICE_/buquery/get-bulk-upload-miservice-errors';
  private getBulkUploadFileIResolutionErrorsRequestAPI = '_MICRO_SERVICE_/buquery/get-bulk-upload-resolution-errors';
  private getBulkUploadFileStatusDropdownAPI = '_MICRO_SERVICE_/buquery/get-all-status-count';


  private getBulkUploadPropertiesRequestAPI = '_MICRO_SERVICE_/buquery/get-bulk-upload-properties';
  private getBulkUploadServicesCustomFieldsRequestAPI = '_MICRO_SERVICE_/buquery/get-bulk-upload-request-cfs';
  private cancelBulkUploadPropertiesRequestAPI = '_MICRO_SERVICE_/buquery/cancel-bulk-upload';
  private cancelBulkUploadBulkCancelRequestAPI = '_MICRO_SERVICE_/buquery/cancel-bulk-uploads';
  private getAllMIcroServiceStepsRequestAPI = '_MICRO_SERVICE_/buquery/get-all-miservice-steps';
  private getAllResolutionStepsRequestAPI = '_MICRO_SERVICE_/buquery/get-all-resolution-steps';
  private updateExtractionDatesRequestAPI = '_MICRO_SERVICE_/document/update-extraction-date';
  private addInvoiceNumberRequestAPI = '_MICRO_SERVICE_/document/update-invoice-number';

  private resumeBulkUploadPropertiesRequestAPI = '_MICRO_SERVICE_/buresolution/resume-bulk-upload-resolutions';
  private reProcessBulkUploadPropertiesRequestAPI = '_MICRO_SERVICE_/streaming/re-process-bulk-upload-request'
  private calculatePop = '_MICRO_SERVICE_/calculation/bu-run';

  
  constructor(private http: HttpClient) {

  }

  upload(file_upload: AssociatedDocument, type_reference: TypeReferenceEnum): Observable<BulkUpload> {

    return this.http
      .post(this.apiUrlUpload, {file_upload, type_reference})
      .map((response: any) => response.data.bulk_upload as BulkUpload);

  }

  getListByType(type_reference: TypeReferenceEnum, start: number = 0, skip: number = 20): Observable<BulkUpload[]> {

    let params = new HttpParams({
      fromString: `start=${start}&skip=${skip}`
    });

    return this.http
      .get(`${this.apiUrlUploadGetByType}/${type_reference}`, {params})
      .map((response: any) => response.data.bulk_uploads);

  }

  getDetails(_id: string): Observable<BulkUpload> {
    return this.http
      .get(`${this.apiUrlUpload}/${_id}`)
      .map((response: any) => response.data.bulk_upload as BulkUpload);
  }

  reprocess(_id: string): Observable<BulkUpload> {
    return this.http
      .get(`${this.apiUrlUpload}/${_id}/reprocess`)
      .map((response: any) => response.data.bulk_upload as BulkUpload);
  }

  updateRow(_id: string, newDoc: any): Observable<any> {
    return this.http
      .put(`${this.apiUrlUpload}/${_id}/update-row`, newDoc)
      .map((response: any) => response.data.row);
  }

  exportForBulkImport(_id: string, row_status: string){
    return this.http
      .get(`${this.apiUrlUpload}/${_id}/download/${row_status}`)
      .map((response: any) => response.data);
  }


  getBulkUploadRequest(pageNumber: number = 0, pageSize: number = 10, tenantId: any,filterByStatus: string, genIdFilter: string, docIdFilter: string, commentFilter: string, vendorFilter: string, reprocessedGenIdFilter: string, templateCodeFilter: string, hideCancelled: boolean= true, hideReprocessed: boolean = true ): Observable<any> {
    let url = `${this.getBulkUploadRequestAPI}?pageNumber=${pageNumber}&pageSize=${pageSize}&hideCancelled=${hideCancelled}&hideReprocessed=${hideReprocessed}`;
    if (tenantId != null && tenantId.length > 0) {
      url += `&tenantId=${tenantId}`;
    }

    if (commentFilter  != null && commentFilter.length > 0) {
      url += `&comment=${commentFilter}`;
    }

    if (vendorFilter  != null && vendorFilter.length > 0) {
      url += `&vendorId=${vendorFilter}`;
    }

    if (reprocessedGenIdFilter  != null && reprocessedGenIdFilter.length > 0) {
      const filterValue = 'GEN-'+reprocessedGenIdFilter
      url += `&reprocessedGenId=${filterValue}`;
    }
    
    if (templateCodeFilter  != null && templateCodeFilter.length > 0) {
      url += `&templateCode=${templateCodeFilter}`;
    }

    if (genIdFilter  != null && genIdFilter.length > 0) {

      console.log("here genIdFilter", genIdFilter)

      const filterValue = 'GEN-'+genIdFilter
      url += `&genId=${filterValue}`;
    }

    if (docIdFilter  != null && docIdFilter.length > 0 && tenantId != null && tenantId.length > 0) {
      const docIdFilterValue = 'DOC-'+docIdFilter

      url += `&docId=${docIdFilterValue}`;
    }


    if (filterByStatus  != null && filterByStatus.length > 0) {
      url += `&status=${filterByStatus}`;
    }

    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }


  getBulkUploadCancelRequest(bulkUploadRequestId: string): Observable<string> {
  return this.http
    .post(`${this.cancelBulkUploadPropertiesRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}`, {})
    .pipe(
      map((response: string) => {
        console.log("here response getBulkUploadCancelRequest", response);
        return response;  // Ensure the response is returned
      })
    );
  }

  getBulkUploadBulkCancelRequest(payload: string[]): Observable<string> {
    return this.http
      .post(`${this.cancelBulkUploadBulkCancelRequestAPI}`, payload)
      .pipe(
        map((response: string) => {
          return response;
        })
      );
    }


  getBulkUploadResumeRequest(bulkUploadRequestId: string): Observable<any> {
    // return this.http
    // .post(`${this.resumeBulkUploadPropertiesRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}`, {})
    // .map((response: any) => response);

    return this.http
    .post(`${this.resumeBulkUploadPropertiesRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}`, {})
    .pipe(
      map((response: any) => response)
    );

  }

  getBulkUploadReProcessRequest(bulkUploadRequestId: string): Observable<any> {
    return this.http
    .post(`${this.reProcessBulkUploadPropertiesRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}`, {})
    .map((response: any) => response);
  }

  calculationPop(bulkUploadRequestId: string): Observable<any> {
    const url = `${this.calculatePop}?bulkUploadRequestId=${bulkUploadRequestId}`;
  
    return this.http
      .post(url, {})
      .map((response: any) => response);
  }


 updateExtractionDateRequest(document_id: string, extraction_date: string): Observable<any> {
 const  payload = {
  document_id: document_id,
  extraction_date: extraction_date
  }
    return this.http
    .put(`${this.updateExtractionDatesRequestAPI}`, payload)
    .map((response: any) => response);
  }

  addInvoiceNumberRequest(document_id: string, invoice_number: string): Observable<any> {
    const payload = {
      document_id: document_id,
      invoice_number: invoice_number
    }
    return this.http
      .put(`${this.addInvoiceNumberRequestAPI}`, payload)
      .map((response: any) => response);
  }


  getBulkUploadFileStatusRequest(bulkUploadRequestId: string): Observable<any> {
    let url = `${this.getBulkUploadFileStatusRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }

  getBulkUploadFileServicesRequest(bulkUploadRequestId: string, pageSize: number = 10 , pageNumber: number= 0 ): Observable<any> {
    let url = `${this.getBulkUploadFileServicesRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }

  getBulkUploadFileServicesErrorsRequest(bulkUploadRequestId: string ): Observable<any> {
    let url = `${this.getBulkUploadFileServicesErrorsRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }

  getBulkUploadFileResolutionErrorsRequest(bulkUploadRequestId: string ): Observable<any> {
    let url = `${this.getBulkUploadFileIResolutionErrorsRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }

  getBulkUploadFileIngestionPendingErrorsRequest(bulkUploadRequestId: string, resolutionType:string ): Observable<any> {
    let url = `${this.getBulkUploadFileIngestionPendingErrorsRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}&resolutionType=${resolutionType}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }

  getBulkUploadFileIngestionErrorsRequest(bulkUploadRequestId: string ): Observable<any> {
    let url = `${this.getBulkUploadFileIngestionErrorsRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }

  getBulkUploadPropertiesRequest(bulkUploadServiceId: string, pageSize: number = 500 , pageNumber: number= 0 ): Observable<any> {
    let url = `${this.getBulkUploadPropertiesRequestAPI}?bulkUploadServiceId=${bulkUploadServiceId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }


  getBulkUploadServicesCustomFieldsRequest(bulkUploadRequestId: string ): Observable<any> {
    let url = `${this.getBulkUploadServicesCustomFieldsRequestAPI}?bulkUploadRequestId=${bulkUploadRequestId}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }

  getAllMIcroServiceStepsRequest(): Observable<any> {
    let url = `${this.getAllMIcroServiceStepsRequestAPI}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }

  getBulkUploadFileStatusDetailsRequest(hideCancelled: boolean, hideReprocessed: boolean): Observable<any> {
    let url = `${this.getBulkUploadFileStatusDropdownAPI}?hideCancelled=${hideCancelled}&hideReprocessed=${hideReprocessed}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }

  getAllResolutionStepsRequest(): Observable<any> {
    let url = `${this.getAllResolutionStepsRequestAPI}`;
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response);
  }


}
