<div>
  <mi-page-header
    title="{{ 'TENANT.MISERVICE_DETAILS_TITLE' | translate:{display_id: serviceDetails ? serviceDetails.general.service_display_id : ''} }}"
    [breadcrumbs]="breadcrumbs"  [type]="'service_details'" [validationFlag]="validationFlag" [daysSinceLastUpdate]="daysSinceLastUpdate">
    <!-- <div class="sub-title" *ngIf="lastupdated" showSubTitle>
      <div class="label">Last Updated:</div>{{lastupdated | date:'MM/dd/yyyy hh:mm:ss a'}}
    </div> -->
  </mi-page-header>
  <mi-server-error [error]="error"></mi-server-error>
  <ng-container *ngIf="!pageLoading && serviceDetails && serviceCalculationDetails; else loading">
  <mi-page-alert></mi-page-alert>
    <mi-service-details-header activeServiceCount="{{activeServiceCount}}"></mi-service-details-header>
    <mi-service-summary (updateServiceDetailsStore)="updateServiceDetailsStore()"></mi-service-summary>
    <input type="hidden" id="changeTab" value="" (change)="onTabChange($event.target.value)">
    <div class="sections">
      <div class="note-info">
      <h2>Service Navigation</h2>
      </div>
      <div class="card mt-3">
      <ng-container [ngSwitch]="error">
        <mi-tabs-container #detailTabs [wrap]="true" [full]="true" externalClasses="service-detail-page-wide-tab reverse-color"
          (indexChanged)="tabChangeHandler($event)">
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="('TENANT.VENDOR_AND_PRODUCT_TITLE' | translate)"
            [active]="selectedTab == 0">
            <section id="vendor" class='data-section'>
              <mi-subsection-vendor-and-product>
              </mi-subsection-vendor-and-product>
            </section>
          </mi-tab>
          <mi-tab *ngIf="serviceDetails.general.parent_service_display_id" [withPane]="false" [noBorder]="true"
            [tabTitle]="'Parent'" [active]="selectedTab == 1">
            <ng-container>
              <section id="parent_service_group" class='data-section'>
                <mi-subsection-parent-details title="{{'TENANT.EQUIPMENT_TITLE' | translate}}" (dataUpdate)="updateStore($event)"
                  (setGetParentService)="setGetParentService($event)"
                  (updateServiceDetailsStore)="updateServiceDetailsStore()">
                </mi-subsection-parent-details>
              </section>
            </ng-container>
          </mi-tab>
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="('TENANT.ORDER_DETAILS_TITLE' | translate)"
            [active]="selectedTab == 2">
            <section id="service_id" class='data-section'>
              <mi-subsection-order-details title="{{'TENANT.ORDER_DETAILS_TITLE' | translate}}"
                (dataUpdate)="updateStore($event)" (updateServiceDetailsStore)="updateServiceDetailsStore()"
                [editable]="serviceCalculationDetails.service_status !== SERVICE_STATUS.SERVICE_ARCHIVED">
              </mi-subsection-order-details>
            </section>
          </mi-tab>
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Locations'" [active]="selectedTab == 3">
            <section id="locations" class='data-section'>
              <mi-subsection-address-details title="{{'TENANT.ADDRESS_TITLE' | translate}}"
                (dataUpdate)="updateStore($event, true)" (updateServiceDetailsStore)="updateServiceDetailsStore()"
                [editable]="serviceCalculationDetails.service_status !== SERVICE_STATUS.SERVICE_ARCHIVED">
              </mi-subsection-address-details>
            </section>
          </mi-tab>
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Ownership'" [active]="selectedTab == 4">
            <section id="assignee" class='data-section'>
              <mi-subsection-ownership-details title="{{'TENANT.OWNERSHIP_TITLE' | translate}}" (dataUpdate)="updateStore($event)"
                [editable]="serviceCalculationDetails.service_status !== SERVICE_STATUS.SERVICE_ARCHIVED" (updateServiceDetailsStore)="updateServiceDetailsStore()">
              </mi-subsection-ownership-details>
            </section>
          </mi-tab>
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Economics'" [active]="selectedTab == 5">
            <section id="rate" class='data-section'>
              <mi-subsection-economic-details title="{{'TENANT.ECONOMIC_DETAILS_TITLE' | translate}}"
                (dataUpdate)="updateStore($event)" (updateServiceDetailsStore)="updateServiceDetailsStore()"
                [editable]="serviceCalculationDetails.service_status !== SERVICE_STATUS.SERVICE_ARCHIVED">
              </mi-subsection-economic-details>
            </section>
          </mi-tab>
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="('TENANT.TERMS_TITLE' | translate)"
            [active]="selectedTab == 6">
            <section id="end_date" class='data-section'>
              <mi-subsection-terms-details title="{{'TENANT.TERMS_TITLE' | translate}}"
                (updateServiceDetailsStore)="updateServiceDetailsStore()" (dataUpdate)="updateStore($event)"
                [editable]="serviceCalculationDetails.service_status !== SERVICE_STATUS.SERVICE_ARCHIVED">
              </mi-subsection-terms-details>
            </section>
          </mi-tab>
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Terms'" [active]="selectedTab == 7">
            <section id="end_of_term" class='data-section'>
              <mi-subsection-service-term-details
                title="{{'TENANT.SERVICE_TERMS_TITLE' | translate}}"
                (updateServiceDetailsStore)="updateServiceDetailsStore()"
                [editable]="serviceCalculationDetails.service_status !== SERVICE_STATUS.SERVICE_ARCHIVED">
              </mi-subsection-service-term-details>
            </section>
          </mi-tab>
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Vendor Defined Fields'" [active]="selectedTab == 8">
            <section id="custom_fields" class='data-section wrappable'>
              <mi-subsection-custom-fields title="{{'TENANT.VENDOR_DEFINED_FIELDS_TITLE' | translate}}"
                (dataUpdate)="updateStore($event)"
                [editable]="serviceCalculationDetails.service_status !== SERVICE_STATUS.SERVICE_ARCHIVED">
              </mi-subsection-custom-fields>
            </section>
          </mi-tab>
          <!-- <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Contacts'">
            <section id="contacts" class='data-section'>
              <mi-subsection-contact-details title="{{'TENANT.SERVICE_CONTACT_TITLE' | translate}}" (dataUpdate)="updateStore($event)"
                [editable]="serviceCalculationDetails.service_status !== SERVICE_STATUS.SERVICE_ARCHIVED">
              </mi-subsection-contact-details>
            </section>
          </mi-tab> -->
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="('TENANT.ATTACHMENTS_TITLE' | translate)"
            [active]="selectedTab == 9">
            <section id="associated_documents" class='data-section'>
              <mi-subsection-associated-documents title="{{'TENANT.ATTACHMENTS_TITLE' | translate}}"
                (dataUpdate)="updateStore($event)"
                [editable]="serviceCalculationDetails.service_status !== SERVICE_STATUS.SERVICE_ARCHIVED">
              </mi-subsection-associated-documents>
            </section>
          </mi-tab>
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Service Audit'" [active]="selectedTab == 10">
            <section id="service_audit" class='data-section'>
              <mi-subsection-service-audit-container>
              </mi-subsection-service-audit-container>
            </section>
          </mi-tab>
          <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Tags'" [active]="selectedTab == 11">
            <section id="tags" class='data-section'>
              <mi-subsection-tags [title]="'Tags'" [editable]="true">
              </mi-subsection-tags>
            </section>
          </mi-tab>
        </mi-tabs-container>
      </ng-container>
      </div>
      </div>
    </ng-container>

  <ng-template #loading>
  <ng-container *ngIf="pageLoading">
    <mi-loading-animation></mi-loading-animation>
  </ng-container>
  </ng-template>
  <!-- <mi-modal id="{{modalId}}">
    <mi-service-details-data-integrity-overlay *ngIf="serviceCalculationDetails"
      [data]="serviceCalculationDetails.data_integrity" [modalId]="this.modalId" class="for-modal">
    </mi-service-details-data-integrity-overlay>
  </mi-modal> -->
  <router-outlet></router-outlet>
</div>