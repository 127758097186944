import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { DropdownItemData } from 'src/shared/models/DropdownItem.interface';
import linkFormatter, { getProfileLink } from 'src/common/utils/linkFormatter';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import * as userReducer from '../../../../libs/user-store/state/reducers';
import {settings} from '../../../../common/utils/SettingsUtil';
import * as appDataReducer from '../../../state/app-data/reducers';
import { Tenant } from 'src/shared/models/Tenant';
// import * as tenantBillingAccountReducer from '../../../state/tenant-billing-account/reducers';
// import { TenantBillingAccount } from 'src/shared/models/Billing';
import { getDualPlatformAccessV2 } from 'src/libs/user-store/state/auth-cookie';


export const APP_TYPES: any = {
  MIYAGI: new URL(settings.MIYAGI_URL).host,
  ACCOUNTS: new URL(settings.ACCOUNTS_URL).host,
  TENANT: 'TENANT'
};

@Component({
  selector: 'mi-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent extends AbstractPageComponent implements OnInit, OnDestroy{

  @Input() structure: any = {
    shortCuts: {
      leftLinks: [],
      rightLinks: []
    },
    defaultLinks: []
  };
  @Input() isNavPath: boolean = false;
  @Input() showDashboardButton: boolean = false;
  user: any;
  tenant: Tenant;
  isBranded: boolean = false;
  logoUrl: string;
  isMiyagi: boolean = /^https?:\/\/miyagi/.test(window.location.href);
  isSuperUser: boolean = false;

  dropdownItems: DropdownItemData[] = [
    {
      title: 'Profile',
      externalLink:true,
      url: getProfileLink()
    },
    {
      title: 'Help',
      isDownload:true,
      url: 'https://miso3.com/help'
    },
    {
      title: 'Logout',
      externalLink:true,
      url: linkFormatter.getLogoutLink()
    }];
  
  // tenantBillingAccount: TenantBillingAccount;
  // trialDaysLeft: number;

  constructor(
    private store: Store<userReducer.State>,
    private baseAppDataStore: Store<appDataReducer.State>
  ) {
    super();
    if (getDualPlatformAccessV2() == "true") {
      this.dropdownItems.splice(2, 0,  {
        title: 'Switch to Mi-MiSO',
        isDownload: true,
        url: linkFormatter.getLoginLink()
      });
    }
  }

  ngOnInit() {

    this.store.select(userReducer.getUserState).subscribe(
      state => {
        if (state.secureUser) {
          this.user = state.secureUser.getSafeData();
          this.isSuperUser = state.secureUser.isSuperUser();
        }
      });

      this.baseAppDataStore.select(appDataReducer.getAppData).subscribe(
        state => {
          switch(state.appType){
            case APP_TYPES.TENANT:
              this.tenant = state.tenantData;
              this.isBranded = !!this.tenant && !!this.tenant.logo && !!this.tenant.logo.full_path;
            case APP_TYPES.MIYAGI:
              this.logoUrl = '/';
              break;
            case APP_TYPES.ACCOUNTS:
              this.logoUrl =  '/accounts';
              break;
          }
        }
      );

      // this.store.select(tenantBillingAccountReducer.getTenantBillingAccountData).subscribe(state => {
      //   this.tenantBillingAccount = state.result;
      //   console.log("TOPNAV tenantBillingAccount", this.tenantBillingAccount);
      //   if(this.tenantBillingAccount.trial) {
      //     let today = new Date();
      //     let trialEndDate = new Date(this.tenantBillingAccount.trialEndDate);
      //     if(trialEndDate > today) {
      //       let timeDiff = trialEndDate.getTime() - today.getTime();
      //       let daysDiff = timeDiff / (1000 * 3600 * 24);
      //       this.trialDaysLeft = Math.ceil(daysDiff);
      //     }
      //     else {
      //       this.trialDaysLeft = 0;
      //     }          
      //   }
      // });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
