import {FileUpload} from './FileUpload'
export class Vendor {
  _id:string;
  id:string;
  vendor_name: string;
  vendor_url: string;
  display_id: string;
  status?:string;
  logo?: FileUpload;
  account_ids?: Array<any>;
  contacts?: Array<any>;
  owner: string;
  created_at: any;
  updated_at: any;
  actual_vendor_id?: string;
  updated_user_name?: string;
  service_mrc?: number;
  service_count?: number;
  service_id_hint?: string;
  normalized: boolean;
  service_term?: string;
  resolution_id?: string;
  is_resolved?: boolean;
  is_deleted?: boolean;
  is_ignored?: boolean;
  tenant_id?: string;
  tenant_vendor_id?: string;
  vendor?: any;
  service_term_id?: string;
}

export class TenantVendorSearchData {
  display_id: string;
  id: string;
  logo: string;
  resolution_id: string;
  vendor_name: string;
  vendor_url: string;
  tenant_vendor_id: string;
  tenant_id?: string;
}

export class TenantVendorSearch {
  data: TenantVendorSearchData[];
  pageNumber: number;
  pageSize: number;
  total: number;
  moreAvailable: boolean;
}

export class TenantVendor {
  vendor: Vendor;
  id: string;
  tenant_id: string;
  tenant_name: string;
  display_id: string;
  service_term_id: string;
  is_deleted: boolean;
  accountid_display_ids: any[];
  product_display_ids: any[];
  mrc: number;
  service_count: number;
  dependents: string
}

export class ITenantVendorDetails {
  accountid_display_ids: string[];
  auto_renew_length: number | null;
  created_on_utc: Date | null;
  days_to_notify: number | null;
  dependents: any;
  display_id: string;
  id: string;
  is_deleted: boolean;
  mrc: number;
  product_display_ids: string[];
  service_count: number;
  service_term_event_type: string | null | any;
  service_term_id: string | null;
  tenant_id: string;
  tenant_name: string | null;
  mi_miso_user_id: string;
  mi_miso_user_first_name: string;
  mi_miso_user_last_name: string;
  vendor: IVendorDetails
  poor_data_integrity: PoorDataIntegrity
  
}

export interface GetDataIntegrity {
  cmrc_for_all_services: string;
  cmrc_for_completed_services: string;
  cmrc_integritydata_total: string;
  data_integrity: string;
  miso_service_ids_total: string[];
  parent_miso_service_count_total: number;
  vendor_id: string;
  vendor_name: string;
  tenant_vendor_id: string;
}

export interface PoorDataIntegrity{
  service_count: number,
  accountid_count: number,
  cmrc: number,
  service_count_dates: string
}

export interface IVendorSourceData {
  vendor_id: string,
  vendor_name: string,
  vendor_dsp_id: string,
  vendor_owner: string,
  mi_miso_user_id?: string,
  mi_miso_user: string,
  account_qty: number
}


export interface IVendorTermFormatted {
  vendor_id: string,
  vendor_name: string,
  vendor_dsp_id: string
  vendorTerm: ITenantVendorDetails | string | any,
  tenantServiceTerms?: ITenantServiceTerm[],
  account_qty: number,
}


export interface ITenantServiceTerm {
  id: string;
  tenant_id: string;
  display_id: string;
  event_type: string;
  auto_renew_length: number;
  days_to_notify: number;
}

export interface ITenantVendorRetermSettings {
  content: IContent[];
  pageable:IPageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: ISort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface IVendorDetails {
  id: string;
  is_deleted: boolean;
  is_ignored: boolean;
  is_resolved: boolean;
  logo: string | null;
  owner: string;
  resolution_id: string;
  vendor_name: string;
  vendor_url: string;
}

export interface IVendorPaginatedData {
  columns : [];
  data: ITenantVendorDetails[];
  metaData :  null;
  moreAvailable : boolean;
  pageNumber : number;
  pageSize : number;
  total : number
}


export interface IContent {
  auto_request_activated: boolean;
  copied_tenant_user_email: string;
  copied_tenant_user_first_name: string;
  copied_tenant_user_id: string;
  copied_tenant_user_last_name: string;
  created_at: string;
  created_by: string;
  is_deleted: boolean;
  modified_at: string;
  modified_by: string;
  tenant_reterm_settings_id: string;
  tenant_vendor_id: string;
}

export interface IPageable  {
  sort: ISort;
  offset: number;
  pageSize: number;
  pageNumber: number;
  unpaged: boolean;
  paged: boolean;
};

export interface ISort{
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
};

export enum IEventTypeEnum {
  EVENT_TYPE_AUTO_RENEW = 'EVENT_TYPE_AUTO_RENEW',
  EVENT_TYPE_AUTO_CANCELLATION = 'EVENT_TYPE_AUTO_CANCELLATION',
}

export class IAccount  {
  deleted: boolean;
  id: string;
  label: string;
  display_id: string;
  contact_id: string | null;
  service_term_id: string | null;
  vendor_id: string;
  tenant_vendor_id: string;
  owner: string;
  mi_miso_user_id: string;
  mi_miso_user_updated_by: string;
  mi_miso_user_updated_on_utc: string;
}

export class IVendorAccountDetails  {
  account: IAccount;
  mrc: number | null;
  service_count: number | null;
  service_term_id: string | null;
  service_term_event_type: string | null;
  auto_renew_length: number | null;
  mi_miso_user_first_name: string;
  mi_miso_user_last_name: string;
  mi_miso_user_email: string;
  days_to_notify: number | null;
  created_on_utc: string | null;
  mi_miso_user_id: string;
  contacts: any; // You might want to create a more specific interface for contacts
}
