import { Component, OnInit } from '@angular/core';
import { Link } from 'src/shared/ng-models/Link.interface';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { ITenantReport } from 'src/libs/api/settings/settings.service';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';

@Component({
  selector: 'mi-monthly-spend-distribution',
  styles: [
    `
      .iframe-container {
        width: 100%;
        border: 0.1px solid lightgray;
        height: 100vh;
        padding: 0px;
        border-radius: 5px;
      }
    `
  ],
  template: `
    <div *ngIf="metaBaseDashboardId; else loading">
      <mi-page-header 
        title="Cost Change Analysis" 
        [breadcrumbs]="breadCrumbs" 
        [links]="headerLinks">
      </mi-page-header>
      <div class="iframe-container mt-3">
      <mi-opacity-loader [ready]="!showLoader">
          <iframe class="iframe-container" id="metabase-iframe-{{metaBaseDashboardId}}" src="" title="metabase"></iframe>
      </mi-opacity-loader>
    </div>
    </div>
    <ng-template #loading>
      <mi-loading-animation></mi-loading-animation>
    </ng-template>
  `
})
export class MonthlySpendDistributionComponent implements OnInit {
  metaBaseDashboardId: number;
  tenantId: string = null;
  showLoader: boolean = true;

  breadCrumbs: Link[] = [
    { label: "Dashboard", url: "/", internal: true },
    { label: "Monthly Spend Distribution", url: "/monthly-spend-distribution", internal: true }
  ];

  frontEndSettings: ITenantReport[] = FrontEndSettingsUtil.get().TENANT_REPORTS || [];
  headerLinks: Link[] = [];

  constructor(
    private reportV2Service: ReportV2Service
  ) { }

  ngOnInit(): void {

    const report = this.frontEndSettings.find(report => report.reportName === "Monthly Spend Distribution");

    if (report) {
      this.metaBaseDashboardId = report.metabaseDashboardId;
      this.tenantId = report.tenantId;
    } else {
      console.warn("Report with name 'Monthly Spend Distribution' not found.");
    }

    this.loadData();
  }

  loadData(): void {
    this.showLoader = true;

    this.reportV2Service.getMetabaseResponse(this.tenantId, this.metaBaseDashboardId).then((res: string) => {
      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).setAttribute("src", res.replace("#bordered=true", "#bordered=false").replace("&titled=true", "&titled=false"))
      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).addEventListener('load', () => {
        this.showLoader = false;
      });
    })

  }

}
