<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="no-footer-modal user-modal">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content class="p-0">
      <mi-user-management-form
        [editId]="userId"
        (canceled)="close()"
        (userUpdated)="onUserUpdated($event)"
        [canEditRoleOnBu]='canEditRoleOnBu'
      >
      </mi-user-management-form>

  </modal-content>
</modal>
