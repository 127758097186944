import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-all-filter',
  template: `
    <input 
      type="any"
      class="form-control" 
      (input)="onFilter($event)" 
      [value]="query"
      placeholder="Search">
  `,
})
export class AllFilterComponent implements OnInit, OnChanges {
  @Output() filter = new EventEmitter<string>();
//   @Input() genIdFilter: any;
  @Input() rowData: any;
  query: string = '';

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes['genIdFilter']) {
    //   this.query = this.genIdFilter || '';
    // }    
  }

  onFilter(event: any) {
    const input = event.target.value;
    console.log('here the input', input);
    
    // Only emit the value if it is a valid number (including an empty string)
    // if (/^\d*$/.test(input)) {
      this.query = input;      
      this.filter.emit(this.query);
    // }
  }
}
