<mi-page-header title="{{title}}" [breadcrumbs]="breadcrumbs" *ngIf="title">
</mi-page-header>

<div class="d-flex justify-content-between align-items-center note note-info mb-1">
    <div>
        <h3>Platform Information</h3>
    </div>
    <div class="d-flex align-items-center">
        <div *ngIf="canRecalculate">
            <button class="login-button btn btn-primary btn-sm mr-2" (click)="openModal()">Recalculate
                Services
            </button>
        </div>
        <ng-container *ngIf="$isSuperUser | async">
            <div class="d-flex align-items-center">
                <ng-container *ngIf="isActive">
                    <button class="btn btn-danger btn-sm" (click)="onStatusChangeHandler(false)">
                        Deactivate
                    </button>
                </ng-container>
                <ng-container *ngIf="!isActive">
                    <button class="btn btn-success btn-sm" (click)="onStatusChangeHandler(true)">
                        Activate
                    </button>
                </ng-container>
                <!-- <span class="ml-2" [ngClass]="{'text-success': isActive, 'text-danger': !isActive}">
                    {{ isActive ? 'Active' : 'Inactive' }}
                </span> -->
            </div>
        </ng-container>
    </div>
</div>

<div class="tab-content-wrap card-container card p-3" [class.disabled]="disableCardContainer">
    <mi-server-error [error]="error"></mi-server-error>

    <mi-opacity-loader [ready]="!isLoading">
        <ng-container *ngIf="loaded; else loading">
            <mi-alert-item *ngIf="success" [alertType]="'SUCCESS'">{{success}}</mi-alert-item>
            <div class="domain-info">
                <div class="mb-4">
                    <div>Tenant MiSO3 Domain</div>
                    <div>URL: <strong>https://{{ buData.domain }}.miso3.com</strong></div>
                </div>
                <div class="mb-4">
                    <div>MISO3 details for your vendor portal access</div>
                    <div>Name: <strong>Mike Miso</strong></div>
                    <div>Email:
                        <strong>
                            <a href="mailto:{{ buData.domain }}@miso3.net">
                                {{ buData.domain }}@miso3.net
                            </a>
                        </strong>
                    </div>
                </div>
                <div class="mb-4">
                    <div>Location to manually email invoices into MiSO3</div>
                    <div>Email: 
                        <strong>
                            <a href="mailto:{{ buData.domain }}@miso3.email">
                                {{ buData.domain }}@miso3.email
                            </a>
                        </strong>
                    </div>
                </div>
                <div class="mb-4">
                    <div>All currencies will be resolved to:</div>
                    <div>Default Currency: <span><b>{{ getSelectedCurrency()?.name }} ({{ getSelectedCurrency()?.code
                            }})</b></span></div>

                </div>
                <div class="mb-4">
                    <strong>Start Date</strong>
                    <div><span>{{ createdOnUtc?.created_on_utc }}</span></div>
                </div>
            </div>
        </ng-container>
    </mi-opacity-loader>
</div>

<modal #confirmModal class="recalculate-modal">
    <modal-header>
        <h4>Recalculate Confirmation</h4>
    </modal-header>
    <modal-content>
        <div>Do you want to recalculate all services for this tenant? This may take a few minutes. You can continue
            browsing
            the site.</div>
    </modal-content>
    <modal-footer>
        <div>
            <button class="btn btn-danger mr-2" (click)="closeModal()">Cancel</button>
            <button class="btn btn-primary" (click)="confirmRecalculation()">Confirm</button>
        </div>
    </modal-footer>
</modal>

<modal #activeInactiveModal class="active-inactive-modal" (closed)="closeActiveModal()">
    <modal-header>
        <h4>{{ modalTitle }}</h4>
    </modal-header>
    <modal-content>
        <div>Do you want to <span>{{ isActive ? 'deactivate' : 'activate' }}</span> <b> {{name}}</b>?</div>
    </modal-content>
    <modal-footer>
        <div>
            <button class="btn btn-danger mr-2" (click)="closeActiveModal()">Cancel</button>
            <button class="btn btn-primary" (click)="onSubmit($event)">
                Confirm
            </button>
        </div>
    </modal-footer>
</modal>


<ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
</ng-template>