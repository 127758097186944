import { LocalDataSource } from 'ng2-smart-table';
import { Subject } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { Router } from "@angular/router";
import { DocumentV2Service } from 'src/libs/api2/document-v2/document-v2.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import { map, takeUntil } from 'rxjs/operators';
import { Link } from '../../../../shared/ng-models/Link.interface';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { CurrencyService } from 'src/libs/api2/currency-v2/currency.service';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { DocumentCmrcComponent } from '../document-cmrc/document-cmrc.component';
import { DocumentIconComponent } from '../document-Icon/document-Icon.component';
import { CustomPagerComponent } from '../pagination.component';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';
import { AllFilterComponent } from '../all-filters.component';


@Component({
  selector: 'document-view',
  templateUrl: './documents-manager.component.html',
  styleUrls: ['./documents-manager.component.scss']
})

export class DocumentManagerComponent extends AbstractPageComponent implements OnInit {

  breadCrumbs = [
    {
      label: 'Dashboard',
      url: '/',
      internal: true
    },
    {
      label: 'Documents Manager',
      url: '/documents-manager',
      internal: true
    },
  ];

  headerLinks: Link[];
  isSearchEmptySVC: boolean = true;
  isSearchEmptyServiceIdentifier: boolean = true;
  isDataLoaded: boolean = false
  filteredDataRowCounts: any
  appliedFilteredCounts: any
  currencyDetails: any
  svcSearchParam: Subject<string> = new Subject<string>();
  svcInputParam: string;
  serviceIdentifierSearchParam: Subject<string> = new Subject<string>();
  searchByServiceDisplayId: string = ''
  searchByServiceIdentifier: string = ''
  source: LocalDataSource = new LocalDataSource();
  documents: any;
  formattedData: any[] = [];
  pageSize: number = 10
  pageNumber: number = 0
  vendors: any[] = []
  isMoreDocumentsLoading: boolean = false
  totalNumberOfDocuments: number
  tenantData: any = null;
  @ViewChild('searchInputSVC') searchInputSVC: ElementRef;
  @ViewChild('searchInputServiceIdentifier') searchInputServiceIdentifier: ElementRef;
  settings = {
    pager: {},
    actions: {
      add: false,
      delete: false,
      edit: false,
      position: 'right',
    },
    noDataMessage: "Loading...",
    rowClassFunction: (row) => 'cursor-pointer',
    columns: {}
  }
  filterChange$ = new Subject<any>();
  searchByDocId: string = '';
  searchByVendorName: string = '';
  searchByAccountNumber: string = '';
  searchByInvoiceNumber: string = '';
  selectedPageNumber: number = 1;

  selectedColumnOrder: string;
  sortByColumn: string;
  vendorsList: any[] = [];
  vendorNamesList: any[] = [];

  tableInitiated = false;
  isLoading: boolean = false;

  constructor(
    public router: Router,
    public documentV2Service: DocumentV2Service,
    private currencyService: CurrencyService,
    private tenantV2Service: TenantV2Service,
    private store: Store<any>,
    private tenantVendorList: TenantVendors,
    private decimalPipe: DecimalPipe
  ) {
    super();

    this.svcSearchParam.pipe(debounceTime(500))
      .subscribe(searchParam => {
        this.svcInputParam = searchParam
        if (searchParam.length > 0) {
          this.searchByServiceDisplayId = 'SVC-' + searchParam
        } else {
          this.searchByServiceDisplayId = ''
        }

        this.getTenantDocuments()
      });

    this.serviceIdentifierSearchParam.pipe(debounceTime(500))
      .subscribe(searchParam => {
        this.searchByServiceIdentifier = searchParam
        this.getTenantDocuments()
      });

  }

  ngOnInit(): void {

    this.store.pipe(
      select(appDataReducer.getAppData),
      takeUntil(this.destroy$),
      map((appData) => {
        this.tenantData = appData.tenantData;
        console.log("here tenantData", this.tenantData)
        // this.businessUnitId = appData.tenantData.domain;
        // this.getBusinessUnit(appData.tenantData.domain);
        this.getBusinessDetails()
      })
    ).subscribe();
    
    this.filterChange$.pipe(
      debounceTime(1000)
    ).subscribe(change => {
      if (change.action === 'filter') {
        change.filter.filters.forEach(filter => {
          console.log('here the Field:', filter.field, 'here the Search:', filter.search);
        });
        const filters = [
          { field: 'doc_id', action: (value: string) => this.searchByDocId = value },
          { field: 'vendor_name', action: (value: string) => this.searchByVendorName = value },
          { field: 'account_number', action: (value: string) => this.searchByAccountNumber = value },
          { field: 'invoice_number', action: (value: string) => this.searchByInvoiceNumber = value },
        ];
        console.log("here the  filters", filters)
        filters.forEach(filter => {
          const filterObject = change.filter.filters.find(f => f.field === filter.field);

          if (filterObject) {
            filter.action(filterObject.search);
          }
          console.log('here the filterObject', filterObject);
        });
        
        this.pageNumber = 0;
        this.pageSize = 10;
        this.selectedPageNumber = 1;
        this.getTenantDocuments();
      }
    });

    this.source.onChanged().subscribe(change => {
      if (change.action === 'filter') {
        this.source.reset();
        this.filterChange$.next(change);
      }
    });
    this.getVendorsList();
    
  }

  getVendorsList() {
    this.isLoading = true;
    this.tenantVendorList
      .searchTenantVendorsWithActiveServices(
        this.searchByVendorName,
        this.pageNumber,
        490,
        this.tenantData.domain,
        this.selectedColumnOrder,
        this.sortByColumn,
        true
      )
      .toPromise()
      .then((response) => {
        this.vendorsList = response.data;  
        const vendorNames = this.vendorsList.map((vendor) => vendor.vendor?.vendor_name);
  
         const modifiedVendors = vendorNames.map((name) => ({
          value: name,
          title: name
        }));

        this.vendors = [...modifiedVendors];
        this.isLoading = false;
        this.initializeTable();
      })
  }

  getBusinessDetails() {
    this.tenantV2Service
      .getBusinessUnit(this.tenantData.domain)
      .toPromise()
      .then(response => {
        console.log("here getBusinessDetails response", response)
        this.getCurrencyDetailsDetails(response)
      })
      .catch((e) => {
        console.warn(e)
      }
      );
  }

  getCurrencyDetailsDetails(businessDetails) {
    this.currencyService
      .getCurrencyDetailsByCurrencyId(businessDetails.tenant_currency_id)
      .toPromise()
      .then(response => {
        this.currencyDetails = response
        this.isDataLoaded = true
        this.getTenantDocuments()
      })
      .catch((e) => {
        console.warn(e)
      }
      );
  }


  getTenantDocuments() {
    this.isDataLoaded = false;
    this.documentV2Service
      .getTenantDocumentsService(this.currencyDetails.tenant_id, this.pageNumber, this.pageSize, this.searchByServiceDisplayId, this.searchByServiceIdentifier, this.searchByDocId, this.searchByVendorName, this.searchByAccountNumber, this.searchByInvoiceNumber)
      .toPromise()
      .then(response => {
        this.isDataLoaded = true;
        this.totalNumberOfDocuments = response.total

        this.filteredDataRowCounts = !response.moreAvailable ? response.data.length : response.total

        this.formattedData = response.data.map((document) => {
          return {
            ...document,
            currencyDetails: this.currencyDetails,
            doc_id: parseInt(document.display_id.split('-')[1]),
            doc_extraction_date_utc: document.extraction_date != null ? this.getFormattedDate(document.extraction_date) : null
          }
        })
        this.source.load(this.formattedData);

        if (!this.tableInitiated) {
          this.initializeTable()
        }

      })
      .catch((e) => {
        console.warn(e)
      }
      );
  }

  onPageChange(page: number): void {
    this.pageNumber = page - 1;
    this.selectedPageNumber = page;
    this.getTenantDocuments();
  }

  getFormattedDate(extraction_date) {
    let [month, day, year] = extraction_date.split('/');
    return `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`;

  }


  initializeTable() {
    
    this.isDataLoaded = true
    this.tableInitiated = true
    this.settings = {
      pager: {
        display: true,
        perPage: 10,
        custom: true,
        component: CustomPagerComponent,
      },
      actions: {
        add: false,
        delete: false,
        edit: false,
        position: 'right',
      },
      noDataMessage: "No Documents found",
      rowClassFunction: (row) => 'cursor-pointer',
      columns: {
        doc_id: {
          title: 'DOC-ID',
          editable: false,
          sortDirection: 'desc',
          type: 'html',
          valuePrepareFunction: (cell: any, row: any) => 'DOC-' + row.doc_id,
          width: '9%',
          filter: {
            type: 'custom',
            component: AllFilterComponent,
            placeholder: 'DOC-ID',
          },
        },
        vendor_name: {
          title: 'Vendor',
          editable: false,
          filter: {
            type: 'list',
            config: {
              selectText: this.searchByVendorName ? this.searchByVendorName : 'All Vendors',
              list: this.isLoading ? [{ value: '', title: 'Loading Vendors...' }] : this.vendors,
            },
          },
          width: '15%',
        },
        account_number: {
          title: 'Account ID',
          editable: false,
          filter: {
            type: 'custom',
            component: AllFilterComponent,
            placeholder: 'Account ID',
          },
          width: '15%',
          type: 'html',
          valuePrepareFunction: (cell: any, row: any) => row.account_number ? row.account_number : '-'
        },
        invoice_number: {
          title: 'Invoice #',
          editable: false,
          width: '12%',
          type: 'html',
          filter: {
            type: 'custom',
            component: AllFilterComponent,
            placeholder: 'Invoice number',
          },
          valuePrepareFunction: (cell: any, row: any) => row.invoice_number ? row.invoice_number : '-'
        },
        doc_extraction_date_utc: {
          title: 'Invoice Date',
          editable: false,
          filter: false,
          width: '11%',
          type: 'html',
          valuePrepareFunction: (cell: any, row: any) => row.extraction_date != null ? row.extraction_date : '-'
        },
        invoice_value: {
          title: 'Invoice Value',
          editable: false,
          width: '12%',
          type: 'html',
          filter: false,
          valuePrepareFunction: (cell: any, row: any) => row.invoice_value ? this.decimalPipe.transform(row.invoice_value, '1.0-2') : '-'
        },
        invoice_tenant_currency_code: {
          title: 'Currency',
          editable: false,
          filter: false,
          width: '8%'
        },
        service_count: {
          title: 'SVC-Count',
          editable: false,
          filter: false,
          width: '12%'
        },
        mrc: {
          title: 'CMRC',
          type: 'custom',
          renderComponent: DocumentCmrcComponent,
          filter: false,
        },
        documentIconComponent: {
          title: 'View',
          editable: false,
          filter: false,
          class: 'disable-sorting',
          sortable: false,
          type: 'custom',
          renderComponent: DocumentIconComponent,
          width: '2%',
        },
      }
    };
  }

  onRowSelect(event: any) {
    this.documentV2Service
      .getBulkUploadSourceFileUrlService(event.data.id)
      .toPromise()
      .then(response => {
        window.open(response.download_url, "_blank");
      })
      .catch((e) => console.warn(e));
  }

  onChange() {
    setTimeout(() => {
      const getFilter = this.source.getFilter();
      this.appliedFilteredCounts = getFilter.filters.filter(filter => { if (filter.search.length > 0) { return filter } }).length
      const rowsCount = this.source.count();
      this.filteredDataRowCounts = rowsCount
    }, 1000)
  }

  onSearchChange(searchValue): void {
    this.isDataLoaded = false
    this.filteredDataRowCounts = null
    this.pageSize = 10
    this.isSearchEmptySVC = searchValue.trim() === '';
    this.svcSearchParam.next(searchValue.replace(/^\s+|\s+$/gm, '') || '');
  }
  onClearSearchSVC() {
    this.isSearchEmptySVC = true;
    this.searchInputSVC.nativeElement.value = '';
    this.onSearchChange('');
  }

  onClearSearchServiceIdentifier() {
    this.isSearchEmptyServiceIdentifier = true;
    this.searchInputServiceIdentifier.nativeElement.value = '';
    this.searchByServiceIdentifierHandler('');
  }

  searchByServiceIdentifierHandler(searchValue): void {
    this.isDataLoaded = false
    this.filteredDataRowCounts = null
    this.pageNumber = 0
    this.pageSize = 10
    this.isSearchEmptyServiceIdentifier = searchValue.trim() === '';
    this.serviceIdentifierSearchParam.next(searchValue);
  }

  getFormattedDataLength(): number {
    return this.formattedData.length;
  }

}