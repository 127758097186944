
const TIMEZONES_GROUPS = {
  AD: ['Europe/Andorra'],
  AE: ['Asia/Dubai'],
  AF: ['Asia/Kabul'],
  AG: ['America/Antigua'],
  AI: ['America/Anguilla'],
  AL: ['Europe/Tirane'],
  AM: ['Asia/Yerevan'],
  AO: ['Africa/Luanda'],
  AQ: [
    'Antarctica/McMurdo',
    'Antarctica/Rothera',
    'Antarctica/Palmer',
    'Antarctica/Mawson',
    'Antarctica/Davis',
    'Antarctica/Casey',
    'Antarctica/Vostok',
    'Antarctica/DumontDUrville',
    'Antarctica/Syowa',
    'Antarctica/Troll'
  ],
  AR: [
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia'
  ],
  AS: ['Pacific/Pago_Pago', 'Pacific/Samoa'],
  AT: ['Europe/Vienna'],
  AU: [
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Australia/Canberra',
    'Australia/Queensland',
    'Australia/Tasmania',
    'Australia/Victoria'
  ],
  AW: ['America/Aruba'],
  AX: ['Europe/Mariehamn'],
  AZ: ['Asia/Baku'],
  BA: ['Europe/Sarajevo'],
  BB: ['America/Barbados'],
  BD: ['Asia/Dhaka'],
  BE: ['Europe/Brussels'],
  BF: ['Africa/Ouagadougou'],
  BG: ['Europe/Sofia'],
  BH: ['Asia/Bahrain'],
  BI: ['Africa/Bujumbura'],
  BJ: ['Africa/Porto-Novo'],
  BL: ['America/St_Barthelemy'],
  BM: ['Atlantic/Bermuda'],
  BN: ['Asia/Brunei'],
  BO: ['America/La_Paz'],
  BQ: ['America/Kralendijk'],
  BR: [
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco'
  ],
  BS: ['America/Nassau'],
  BT: ['Asia/Thimphu'],
  BW: ['Africa/Gaborone'],
  BY: ['Europe/Minsk'],
  BZ: ['America/Belize'],
  CA: [
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Resolute',
    'America/Atikokan',
    'America/Rankin_Inlet',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'America/Montreal',
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon'
  ],
  CC: ['Indian/Cocos'],
  CD: ['Africa/Kinshasa', 'Africa/Lubumbashi'],
  CF: ['Africa/Bangui'],
  CG: ['Africa/Brazzaville'],
  CH: ['Europe/Zurich'],
  CI: ['Africa/Abidjan'],
  CK: ['Pacific/Rarotonga'],
  CL: [
    'America/Santiago',
    'Pacific/Easter',
    'Chile/Continental',
    'Chile/EasterIsland'
  ],
  CM: ['Africa/Douala'],
  CN: [
    'Asia/Shanghai',
    'Asia/Harbin',
    'Asia/Chongqing',
    'Asia/Urumqi',
    'Asia/Kashgar'
  ],
  CO: ['America/Bogota'],
  CR: ['America/Costa_Rica'],
  CU: ['America/Havana'],
  CV: ['Atlantic/Cape_Verde'],
  CW: ['America/Curacao'],
  CX: ['Indian/Christmas'],
  CY: ['Asia/Nicosia'],
  CZ: ['Europe/Prague'],
  DE: ['Europe/Berlin'],
  DJ: ['Africa/Djibouti'],
  DK: ['Europe/Copenhagen'],
  DM: ['America/Dominica'],
  DO: ['America/Santo_Domingo'],
  DZ: ['Africa/Algiers'],
  EC: ['America/Guayaquil', 'Pacific/Galapagos'],
  EE: ['Europe/Tallinn'],
  EG: ['Egypt'],
  EH: ['Africa/El_Aaiun'],
  ER: ['Africa/Asmara'],
  ES: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
  ET: ['Africa/Addis_Ababa'],
  FI: ['Europe/Helsinki'],
  FJ: ['Pacific/Fiji'],
  FK: ['Atlantic/Stanley'],
  FM: ['Pacific/Chuuk', 'Pacific/Pohnpei', 'Pacific/Kosrae'],
  FO: ['Atlantic/Faroe'],
  FR: ['Europe/Paris'],
  GA: ['Africa/Libreville'],
  GB: ['Europe/London'],
  GD: ['America/Grenada'],
  GE: ['Asia/Tbilisi'],
  GF: ['America/Cayenne'],
  GG: ['Europe/Guernsey'],
  GH: ['Africa/Accra'],
  GI: ['Europe/Gibraltar'],
  GL: [
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule'
  ],
  GM: ['Africa/Banjul'],
  GN: ['Africa/Conakry'],
  GP: ['America/Guadeloupe'],
  GQ: ['Africa/Malabo'],
  GR: ['Europe/Athens'],
  GS: ['Atlantic/South_Georgia'],
  GT: ['America/Guatemala'],
  GU: ['Pacific/Guam'],
  GW: ['Africa/Bissau'],
  GY: ['America/Guyana'],
  HK: ['Asia/Hong_Kong'],
  HN: ['America/Tegucigalpa'],
  HR: ['Europe/Zagreb'],
  HT: ['America/Port-au-Prince'],
  HU: ['Europe/Budapest'],
  ID: ['Asia/Jakarta', 'Asia/Pontianak', 'Asia/Makassar', 'Asia/Jayapura'],
  IE: ['Europe/Dublin'],
  IL: ['Asia/Jerusalem'],
  IM: ['Europe/Isle_of_Man'],
  IN: ['Asia/Kolkata'],
  IO: ['Indian/Chagos'],
  IQ: ['Asia/Baghdad'],
  IR: ['Asia/Tehran'],
  IS: ['Atlantic/Reykjavik'],
  IT: ['Europe/Rome'],
  JE: ['Europe/Jersey'],
  JM: ['America/Jamaica'],
  JO: ['Asia/Amman'],
  JP: ['Asia/Tokyo'],
  KE: ['Africa/Nairobi'],
  KG: ['Asia/Bishkek'],
  KH: ['Asia/Phnom_Penh'],
  KI: ['Pacific/Tarawa', 'Pacific/Enderbury', 'Pacific/Kiritimati'],
  KM: ['Indian/Comoro'],
  KN: ['America/St_Kitts'],
  KP: ['Asia/Pyongyang'],
  KR: ['Asia/Seoul'],
  KW: ['Asia/Kuwait'],
  KY: ['America/Cayman'],
  KZ: [
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Oral'
  ],
  LA: ['Asia/Vientiane'],
  LB: ['Asia/Beirut'],
  LC: ['America/St_Lucia'],
  LI: ['Europe/Vaduz'],
  LK: ['Asia/Colombo'],
  LR: ['Africa/Monrovia'],
  LS: ['Africa/Maseru'],
  LT: ['Europe/Vilnius'],
  LU: ['Europe/Luxembourg'],
  LV: ['Europe/Riga'],
  LY: ['Africa/Tripoli'],
  MA: ['Africa/Casablanca'],
  MC: ['Europe/Monaco'],
  MD: ['Europe/Chisinau'],
  ME: ['Europe/Podgorica'],
  MF: ['America/Marigot'],
  MG: ['Indian/Antananarivo'],
  MH: ['Pacific/Majuro', 'Pacific/Kwajalein'],
  MK: ['Europe/Skopje'],
  ML: ['Africa/Bamako'],
  MM: ['Asia/Rangoon'],
  MN: ['Asia/Ulaanbaatar', 'Asia/Hovd', 'Asia/Choibalsan'],
  MO: ['Asia/Macau'],
  MP: ['Pacific/Saipan'],
  MQ: ['America/Martinique'],
  MR: ['Africa/Nouakchott'],
  MS: ['America/Montserrat'],
  MT: ['Europe/Malta'],
  MU: ['Indian/Mauritius'],
  MV: ['Indian/Maldives'],
  MW: ['Africa/Blantyre'],
  MX: [
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Santa_Isabel',
    'America/Bahia_Banderas'
  ],
  MY: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
  MZ: ['Africa/Maputo'],
  NA: ['Africa/Windhoek'],
  NC: ['Pacific/Noumea'],
  NE: ['Africa/Niamey'],
  NF: ['Pacific/Norfolk'],
  NG: ['Africa/Lagos'],
  NI: ['America/Managua'],
  NL: ['Europe/Amsterdam'],
  NO: ['Europe/Oslo'],
  NP: ['Asia/Kathmandu'],
  NR: ['Pacific/Nauru'],
  NU: ['Pacific/Niue'],
  NZ: ['Pacific/Auckland', 'Pacific/Chatham'],
  OM: ['Asia/Muscat'],
  PA: ['America/Panama'],
  PE: ['America/Lima'],
  PF: ['Pacific/Tahiti', 'Pacific/Marquesas', 'Pacific/Gambier'],
  PG: ['Pacific/Port_Moresby'],
  PH: ['Asia/Manila'],
  PK: ['Asia/Karachi'],
  PL: ['Europe/Warsaw', 'Poland'],
  PM: ['America/Miquelon'],
  PN: ['Pacific/Pitcairn'],
  PR: ['America/Puerto_Rico'],
  PS: ['Asia/Gaza', 'Asia/Hebron'],
  PT: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
  PW: ['Pacific/Palau'],
  PY: ['America/Asuncion'],
  QA: ['Asia/Qatar'],
  RE: ['Indian/Reunion'],
  RO: ['Europe/Bucharest'],
  RS: ['Europe/Belgrade'],
  RU: [
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Volgograd',
    'Europe/Samara',
    'Europe/Simferopol',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Sakhalin',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Kamchatka',
    'Asia/Anadyr'
  ],
  RW: ['Africa/Kigali'],
  SA: ['Asia/Riyadh'],
  SB: ['Pacific/Guadalcanal'],
  SC: ['Indian/Mahe'],
  SD: ['Africa/Khartoum'],
  SE: ['Europe/Stockholm'],
  SG: ['Asia/Singapore'],
  SH: ['Atlantic/St_Helena'],
  SI: ['Europe/Ljubljana'],
  SJ: ['Arctic/Longyearbyen'],
  SK: ['Europe/Bratislava'],
  SL: ['Africa/Freetown'],
  SM: ['Europe/San_Marino'],
  SN: ['Africa/Dakar'],
  SO: ['Africa/Mogadishu'],
  SR: ['America/Paramaribo'],
  SS: ['Africa/Juba'],
  ST: ['Africa/Sao_Tome'],
  SV: ['America/El_Salvador'],
  SX: ['America/Lower_Princes'],
  SY: ['Asia/Damascus'],
  SZ: ['Africa/Mbabane'],
  TC: ['America/Grand_Turk'],
  TD: ['Africa/Ndjamena'],
  TF: ['Indian/Kerguelen'],
  TG: ['Africa/Lome'],
  TH: ['Asia/Bangkok'],
  TJ: ['Asia/Dushanbe'],
  TK: ['Pacific/Fakaofo'],
  TL: ['Asia/Dili'],
  TM: ['Asia/Ashgabat'],
  TN: ['Africa/Tunis'],
  TO: ['Pacific/Tongatapu'],
  TR: ['Europe/Istanbul'],
  TT: ['America/Port_of_Spain'],
  TV: ['Pacific/Funafuti'],
  TW: ['Asia/Taipei'],
  TZ: ['Africa/Dar_es_Salaam'],
  UA: ['Europe/Kiev', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
  UG: ['Africa/Kampala'],
  UM: ['Pacific/Johnston', 'Pacific/Midway', 'Pacific/Wake'],
  US: [
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'America/Metlakatla',
    'Pacific/Honolulu'
  ],
  UY: ['America/Montevideo'],
  UZ: ['Asia/Samarkand', 'Asia/Tashkent'],
  VA: ['Europe/Vatican'],
  VC: ['America/St_Vincent'],
  VE: ['America/Caracas'],
  VG: ['America/Tortola'],
  VI: ['America/St_Thomas'],
  VN: ['Asia/Ho_Chi_Minh'],
  VU: ['Pacific/Efate'],
  WF: ['Pacific/Wallis'],
  WS: ['Pacific/Apia'],
  YE: ['Asia/Aden'],
  YT: ['Indian/Mayotte'],
  ZA: ['Africa/Johannesburg'],
  ZM: ['Africa/Lusaka'],
  ZW: ['Africa/Harare']
};

const COUNTRIES = {
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  VG: 'British Virgin Islands',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  HK: 'Hong Kong',
  MO: 'Macao',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo (Brazzaville)',
  CD: 'Congo, (Kinshasa)',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: 'Côte d\'Ivoire',
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard and Mcdonald Islands',
  VA: 'Vatican City State',
  HN: 'Honduras',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'Korea (North)',
  KR: 'Korea (South)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Lao PDR',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint-Barthélemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint-Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen Islands',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom (GB)',
  US: 'United States (US)',
  UM: 'US Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VI: 'Virgin Islands, US',
  WF: 'Wallis and Futuna Islands',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
};


export interface IsoTimezone {
  value: string;
  text: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  utc: string[];
}

/* Via https://raw.githubusercontent.com/dmfilipenko/timezones.json/master/timezones.json */
const ISO_TIMEZONES: IsoTimezone[] = [{
  'value': 'Africa/Casablanca',
  'text': '(GMT) Casablanca, Monrovia',
  'abbr': 'NST',
  'offset': 1,
  'isdst': false,
  'utc': [
    'Africa/Casablanca',
    'Africa/El_Aaiun'
  ]
}, {
  'value': 'Europe/London',
  'text': '(GMT) Dublin, Edinburgh, Lisbon, London',
  'abbr': 'GDT',
  'offset': 1,
  'isdst': true,
  'utc': [
    'Atlantic/Canary',
    'Atlantic/Faeroe',
    'Atlantic/Madeira',
    'Europe/Dublin',
    'Europe/Guernsey',
    'Europe/Isle_of_Man',
    'Europe/Jersey',
    'Europe/Lisbon',
    'Europe/London'
  ]
}, {
  'value': 'GMT',
  'text': '(GMT) Greenwich Mean Time',
  'abbr': 'GMT',
  'offset': 0,
  'isdst': false,
  'utc': [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Bamako',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Freetown',
    'Africa/Lome',
    'Africa/Monrovia',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Sao_Tome',
    'Atlantic/Reykjavik',
    'Atlantic/St_Helena'
  ]
}, {
  'value': 'Europe/Amsterdam',
  'text': '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'abbr': 'WEDT',
  'offset': 2,
  'isdst': true,
  'utc': [
    'Arctic/Longyearbyen',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Berlin',
    'Europe/Busingen',
    'Europe/Gibraltar',
    'Europe/Luxembourg',
    'Europe/Malta',
    'Europe/Monaco',
    'Europe/Oslo',
    'Europe/Rome',
    'Europe/San_Marino',
    'Europe/Stockholm',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Zurich'
  ]
}, {
  'value': 'Europe/Belgrade',
  'text': '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana',
  'abbr': 'CEDT',
  'offset': 2,
  'isdst': true,
  'utc': [
    'Europe/Belgrade',
    'Europe/Bratislava',
    'Europe/Budapest',
    'Europe/Ljubljana',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Tirane'
  ]
}, {
  'value': 'Europe/Brussels',
  'text': '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
  'abbr': 'RDT',
  'offset': 2,
  'isdst': true,
  'utc':[
    'Africa/Ceuta',
    'Europe/Brussels',
    'Europe/Copenhagen',
    'Europe/Madrid',
    'Europe/Paris'
  ]
}, {
  'value': 'Europe/Prague',
  'text': '(GMT+01:00) Prague',
  'abbr': 'CEDT',
  'offset': 2,
  'isdst': true,
  'utc': [
    'Europe/Prague'
  ]
}, {
  'value': 'Europe/Warsaw',
  'text': '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  'abbr': 'CEDT',
  'offset': 2,
  'isdst': true,
  'utc': [
    'Europe/Sarajevo',
    'Europe/Skopje',
    'Europe/Warsaw',
    'Europe/Zagreb'
  ]
}, {
  'value': 'Africa/Malabo',
  'text': '(GMT+01:00) West Central Africa',
  'abbr': 'WCAST',
  'offset': 1,
  'isdst': false,
  'utc': [
    'Africa/Algiers',
    'Africa/Bangui',
    'Africa/Brazzaville',
    'Africa/Douala',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Luanda',
    'Africa/Malabo',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Porto-Novo',
    'Africa/Tunis',
    'Africa/Windhoek',
    'Etc/GMT-1'
  ]
}, {
  'value': 'Europe/Athens',
  'text': '(GMT+02:00) Athens, Istanbul, Minsk',
  'abbr': 'GDT',
  'offset': 3,
  'isdst': true,
  'utc': [
    'Asia/Nicosia',
    'Europe/Athens',
    'Europe/Bucharest',
    'Europe/Chisinau'
  ]
},
  {
    'value': 'Asia/Beirut',
    'text': '(UTC+02:00) Beirut',
    'abbr': 'MEDT',
    'offset': 3,
    'isdst': true,
    'utc': [
      'Asia/Beirut'
    ]
  }, {
    'value': 'Africa/Cairo',
    'text': '(GMT+02:00) Cairo',
    'abbr': 'EST',
    'offset': 2,
    'isdst': false,
    'utc': [
      'Africa/Cairo'
    ]
  }, {
    'value': 'Africa/Harare',
    'text': '(GMT+02:00) Harare, Pretoria',
    'abbr': 'SAST',
    'offset': 2,
    'isdst': false,
    'utc': [
      'Africa/Blantyre',
      'Africa/Bujumbura',
      'Africa/Gaborone',
      'Africa/Harare',
      'Africa/Johannesburg',
      'Africa/Kigali',
      'Africa/Lubumbashi',
      'Africa/Lusaka',
      'Africa/Maputo',
      'Africa/Maseru',
      'Africa/Mbabane',
      'Etc/GMT-2'
    ]
  }, {
    'value': 'Europe/Helsinki',
    'text': '(GMT+02:00) Helsinki, Riga, Sofia, Tallinn, Vilnius',
    'abbr': 'FDT',
    'offset': 3,
    'isdst': true,
    'utc': [
      'Europe/Helsinki',
      'Europe/Kiev',
      'Europe/Mariehamn',
      'Europe/Riga',
      'Europe/Sofia',
      'Europe/Tallinn',
      'Europe/Uzhgorod',
      'Europe/Vilnius',
      'Europe/Zaporozhye'
    ]
  }, {
    'value': 'Asia/Jerusalem',
    'text': '(GMT+02:00) Jerusalem',
    'abbr': 'JDT',
    'offset': 3,
    'isdst': true,
    'utc': [
      'Asia/Jerusalem'
    ]
  },
  {
    'value': 'Africa/Tripoli',
    'text': '(UTC+02:00) Tripoli',
    'abbr': 'LST',
    'offset': 2,
    'isdst': false,
    'utc': [
      'Africa/Tripoli'
    ]
  },
  {
    'value': 'Asia/Amman',
    'text': '(UTC+03:00) Amman',
    'abbr': 'JST',
    'offset': 3,
    'isdst': false,
    'utc': [
      'Asia/Amman'
    ]
  }, {
    'value': 'Asia/Baghdad',
    'text': '(GMT+03:00) Baghdad',
    'abbr': 'AST',
    'offset': 3,
    'isdst': false,
    'utc': [
      'Asia/Baghdad'
    ]
  },
  {
    'value': 'Europe/Kaliningrad',
    'text': '(UTC+03:00) Kaliningrad, Minsk',
    'abbr': 'KST',
    'offset': 3,
    'isdst': false,
    'utc': [
      'Europe/Kaliningrad',
      'Europe/Minsk'
    ]
  }, {
    'value': 'Asia/Kuwait',
    'text': '(GMT+03:00) Kuwait, Riyadh',
    'abbr': 'AST',
    'offset': 3,
    'isdst': false,
    'utc': [
      'Asia/Aden',
      'Asia/Bahrain',
      'Asia/Kuwait',
      'Asia/Qatar',
      'Asia/Riyadh'
    ]
  },
  {
    'value': 'Europe/Moscow',
    'text': '(UTC+03:00) Moscow, St. Petersburg, Volgograd',
    'abbr': 'MSK',
    'offset': 3,
    'isdst': false,
    'utc': [
      'Europe/Kirov',
      'Europe/Moscow',
      'Europe/Simferopol',
      'Europe/Volgograd'
    ]
  },
  {
    'value': 'Africa/Nairobi',
    'text': '(UTC+03:00) Nairobi',
    'abbr': 'EAST',
    'offset': 3,
    'isdst': false,
    'utc': [
      'Africa/Addis_Ababa',
      'Africa/Asmera',
      'Africa/Dar_es_Salaam',
      'Africa/Djibouti',
      'Africa/Juba',
      'Africa/Kampala',
      'Africa/Khartoum',
      'Africa/Mogadishu',
      'Africa/Nairobi',
      'Antarctica/Syowa',
      'Etc/GMT-3',
      'Indian/Antananarivo',
      'Indian/Comoro',
      'Indian/Mayotte'
    ]
  }
  ,
  {
    'value': 'Europe/Samara',
    'text': '(UTC+04:00) Samara, Ulyanovsk, Saratov',
    'abbr': 'SAMT',
    'offset': 4,
    'isdst': false,
    'utc': [
      'Europe/Astrakhan',
      'Europe/Samara',
      'Europe/Ulyanovsk'
    ]
  },
  {
    'value': 'Asia/Tehran',
    'text': '(GMT+03:30) Tehran',
    'abbr': 'IDT',
    'offset': 4.5,
    'isdst': true,
    'utc': [
      'Asia/Tehran'
    ]
  }, {
    'value': 'Asia/Muscat',
    'text': '(GMT+04:00) Abu Dhabi, Muscat',
    'abbr': 'AST',
    'offset': 4,
    'isdst': false,
    'utc': [
      'Asia/Dubai',
      'Asia/Muscat',
      'Etc/GMT-4'
    ]
  },
  {
    'value': 'Indian/Mauritius',
    'text': '(UTC+04:00) Port Louis',
    'abbr': 'MST',
    'offset': 4,
    'isdst': false,
    'utc': [
      'Indian/Mahe',
      'Indian/Mauritius',
      'Indian/Reunion'
    ]
  }, {
    'value': 'Asia/Tbilisi',
    'text': '(GMT+04:00) Baku, Tbilisi, Yerevan',
    'abbr': 'GST',
    'offset': 4,
    'isdst': false,
    'utc': [
      'Asia/Tbilisi'
    ]
  }, {
    'value': 'Asia/Kabul',
    'text': '(GMT+04:30) Kabul',
    'abbr': 'AST',
    'offset': 4.5,
    'isdst': false,
    'utc': [
      'Asia/Kabul'
    ]
  }, {
    'value': 'Asia/Yekaterinburg',
    'text': '(GMT+05:00) Ekaterinburg',
    'abbr': 'EST',
    'offset': 6,
    'isdst': false,
    'utc': [
      'Asia/Yekaterinburg'
    ]
  }, {
    'value': 'Asia/Karachi',
    'text': '(GMT+05:00) Islamabad, Karachi, Tashkent',
    'abbr': 'PST',
    'offset': 5,
    'isdst': false,
    'utc': [
      'Asia/Karachi'
    ]
  }, {
    'value': 'Asia/Calcutta',
    'text': '(GMT+05:30) Calcutta, Chennai, Mumbai, New Delhi',
    'abbr': 'IST',
    'offset': 5,
    'isdst': false,
    'utc': [
      'Asia/Kolkata',
      'Asia/Calcutta'
    ]
  }, {
    'value': 'Asia/Katmandu',
    'text': '(GMT+05:45) Kathmandu',
    'abbr': 'NST',
    'offset': 5.75,
    'isdst': false,
    'utc': [
      'Asia/Katmandu'
    ]
  },
  {
    'value': 'Asia/Novosibirsk',
    'text': '(GMT+06:00) Almaty, Novosibirsk',
    'abbr': 'NCAST',
    'offset': 7,
    'isdst': false,
    'utc': [
      'Asia/Novokuznetsk',
      'Asia/Novosibirsk',
      'Asia/Omsk'
    ]
  }, {
    'value': 'Asia/Dhaka',
    'text': '(GMT+06:00) Astana, Dhaka',
    'abbr': 'BST',
    'offset': 6,
    'isdst': false,
    'utc': [
      'Asia/Dhaka',
      'Asia/Thimphu'
    ]
  }, {
    'value': 'Asia/Colombo',
    'text': '(GMT+06:00) Sri Jayawardenepura',
    'abbr': 'SLST',
    'offset': 5.5,
    'isdst': false,
    'utc': [
      'Asia/Colombo'
    ]
  }, {
    'value': 'Asia/Rangoon',
    'text': '(GMT+06:30) Rangoon',
    'abbr': 'MST',
    'offset': 6.5,
    'isdst': false,
    'utc': [
      'Asia/Rangoon',
      'Indian/Cocos'
    ]
  }, {
    'value': 'Asia/Bangkok',
    'text': '(GMT+07:00) Bangkok',
    'abbr': 'SAST',
    'offset': 7,
    'isdst': false,
    'utc': [
      'Antarctica/Davis',
      'Asia/Bangkok',
      'Asia/Hovd',
      'Asia/Phnom_Penh',
      'Asia/Pontianak',
      'Asia/Saigon',
      'Asia/Vientiane',
      'Etc/GMT-7',
      'Indian/Christmas'
    ]
  }, {
    'value': 'Asia/Jakarta',
    'text': '(GMT+07:00) Hanoi, Jakarta',
    'abbr': 'SAST',
    'offset': 7,
    'isdst': false,
    'utc': [
      'Asia/Jakarta'
    ]
  }, {
    'value': 'Asia/Krasnoyarsk',
    'text': '(GMT+07:00) Krasnoyarsk',
    'abbr': 'NAST',
    'offset': 8,
    'isdst': false,
    'utc': [
      'Asia/Krasnoyarsk'
    ]
  }, {
    'value': 'Asia/Shanghai',
    'text': '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    'abbr': 'CST',
    'offset': 8,
    'isdst': false,
    'utc': [
      'Asia/Hong_Kong',
      'Asia/Macau',
      'Asia/Shanghai'
    ]
  }, {
    'value': 'Asia/Irkutsk',
    'text': '(GMT+08:00) Irkutsk, Ulaan Bataar',
    'abbr': 'NAEST',
    'offset': 9,
    'isdst': false,
    'utc': [
      'Asia/Irkutsk'
    ]
  }, {
    'value': 'Asia/Singapore',
    'text': '(GMT+08:00) Kuala Lumpur, Singapore',
    'abbr': 'MPST',
    'offset': 8,
    'isdst': false,
    'utc': [
      'Asia/Brunei',
      'Asia/Kuala_Lumpur',
      'Asia/Kuching',
      'Asia/Makassar',
      'Asia/Manila',
      'Asia/Singapore',
      'Etc/GMT-8'
    ]
  }, {
    'value': 'Australia/Perth',
    'text': '(GMT+08:00) Perth',
    'abbr': 'WAST',
    'offset': 8,
    'isdst': false,
    'utc': [
      'Antarctica/Casey',
      'Australia/Perth'
    ]
  }, {
    'value': 'Asia/Taipei',
    'text': '(GMT+08:00) Taipei',
    'abbr': 'TST',
    'offset': 8,
    'isdst': false,
    'utc': [
      'Asia/Taipei'
    ]
  }, {
    'value': 'Asia/Tokyo',
    'text': '(GMT+09:00) Osaka, Sapporo, Tokyo',
    'abbr': 'TST',
    'offset': 9,
    'isdst': false,
    'utc': [
      'Asia/Dili',
      'Asia/Jayapura',
      'Asia/Tokyo',
      'Etc/GMT-9',
      'Pacific/Palau'
    ]
  }, {
    'value': 'Asia/Seoul',
    'text': '(GMT+09:00) Seoul',
    'abbr': 'KST',
    'offset': 9,
    'isdst': false,
    'utc': [
      'Asia/Pyongyang',
      'Asia/Seoul'
    ]
  }, {
    'value': 'Asia/Yakutsk',
    'text': '(GMT+09:00) Yakutsk',
    'abbr': 'YST',
    'offset': 10,
    'isdst': false,
    'utc': [
      'Asia/Chita',
      'Asia/Khandyga',
      'Asia/Yakutsk'
    ]
  }, {
    'value': 'Australia/Adelaide',
    'text': '(GMT+09:30) Adelaide',
    'abbr': 'CAST',
    'offset': 9.5,
    'isdst': false,
    'utc': [
      'Australia/Adelaide',
      'Australia/Broken_Hill'
    ]
  }, {
    'value': 'Australia/Darwin',
    'text': '(GMT+09:30) Darwin',
    'abbr': 'ACST',
    'offset': 9.5,
    'isdst': false,
    'utc': [
      'Australia/Darwin'
    ]
  }, {
    'value': 'Australia/Brisbane',
    'text': '(GMT+10:00) Brisbane',
    'abbr': 'EAST',
    'offset': 10,
    'isdst': false,
    'utc': [
      'Australia/Brisbane',
      'Australia/Lindeman'
    ]
  }, {
    'value': 'Australia/Sydney',
    'text': '(GMT+10:00) Canberra, Melbourne, Sydney',
    'abbr': 'AEST',
    'offset': 10,
    'isdst': false,
    'utc': [
      'Australia/Melbourne',
      'Australia/Sydney'
    ]
  }, {
    'value': 'Pacific/Guam',
    'text': '(GMT+10:00) Guam, Port Moresby',
    'abbr': 'WPST',
    'offset': 10,
    'isdst': false,
    'utc': [
      'Antarctica/DumontDUrville',
      'Etc/GMT-10',
      'Pacific/Guam',
      'Pacific/Port_Moresby',
      'Pacific/Saipan',
      'Pacific/Truk'
    ]
  }, {
    'value': 'Australia/Hobart',
    'text': '(GMT+10:00) Hobart',
    'abbr': 'TST',
    'offset': 10,
    'isdst': false,
    'utc': [
      'Australia/Currie',
      'Australia/Hobart'
    ]
  }, {
    'value': 'Asia/Vladivostok',
    'text': '(GMT+10:00) Vladivostok',
    'abbr': 'VST',
    'offset': 11,
    'isdst': false,
    'utc': [
      'Asia/Sakhalin',
      'Asia/Ust-Nera',
      'Asia/Vladivostok'
    ]
  }, {
    'value': 'Asia/Magadan',
    'text': '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    'abbr': 'MST',
    'offset': 12,
    'isdst': false,
    'utc': [
      'Asia/Anadyr',
      'Asia/Kamchatka',
      'Asia/Magadan',
      'Asia/Srednekolymsk'
    ]
  }, {
    'value': 'Pacific/Auckland',
    'text': '(GMT+12:00) Auckland, Wellington',
    'abbr': 'NZST',
    'offset': 12,
    'isdst': false,
    'utc': [
      'Antarctica/McMurdo',
      'Pacific/Auckland'
    ]
  }, {
    'value': 'Pacific/Fiji',
    'text': '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
    'abbr': 'FST',
    'offset': 12,
    'isdst': false,
    'utc': [
      'Pacific/Fiji'
    ]
  }, {
    'value': 'Pacific/Tongatapu',
    'text': '(GMT+13:00) Nuku"alofa',
    'abbr': 'TST',
    'offset': 13,
    'isdst': false,
    'utc': [
      'Etc/GMT-13',
      'Pacific/Enderbury',
      'Pacific/Fakaofo',
      'Pacific/Tongatapu'
    ]
  }, {
    'value': 'Atlantic/Azores',
    'text': '(GMT-01:00) Azores',
    'abbr': 'ADT',
    'offset': 0,
    'isdst': true,
    'utc': [
      'America/Scoresbysund',
      'Atlantic/Azores'
    ]
  }, {
    'value': 'Atlantic/Cape_Verde',
    'text': '(GMT-01:00) Cape Verde Is.',
    'abbr': 'CVST',
    'offset': -1,
    'isdst': false,
    'utc': [
      'Atlantic/Cape_Verde',
      'Etc/GMT+1'
    ]
  }, {
    'value': 'America/Sao_Paulo',
    'text': '(GMT-03:00) Brasilia',
    'abbr': 'ESAST',
    'offset': -3,
    'isdst': false,
    'utc': [
      'America/Sao_Paulo'
    ]
  }, {
    'value': 'America/Buenos_Aires',
    'text': '(GMT-03:00) Buenos Aires, Georgetown',

    'abbr': 'AST',
    'offset': -3,
    'isdst': false,
    'utc': [
      'America/Argentina/La_Rioja',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
      'America/Buenos_Aires',
      'America/Catamarca',
      'America/Cordoba',
      'America/Jujuy',
      'America/Mendoza'
    ]
  }, {
    'value': 'America/St_Johns',
    'text': '(GMT-03:30) Newfoundland',
    'abbr': 'NDT',
    'offset': -2.5,
    'isdst': true,
    'utc': [
      'America/St_Johns'
    ]
  }, {
    'value': 'America/Halifax',
    'text': '(GMT-04:00) Atlantic Time (Canada)',
    'abbr': 'ADT',
    'offset': -3,
    'isdst': true,
    'utc': [
      'America/Glace_Bay',
      'America/Goose_Bay',
      'America/Halifax',
      'America/Moncton',
      'America/Thule',
      'Atlantic/Bermuda'
    ]
  }, {
    'value': 'America/Puerto_Rico',
    'text': '(GMT-04:00) Puerto Rico, ECT',
    'abbr': 'SWST',
    'offset': -4,
    'isdst': false,
    'utc': [
      'America/Anguilla',
      'America/Antigua',
      'America/Aruba',
      'America/Barbados',
      'America/Blanc-Sablon',
      'America/Boa_Vista',
      'America/Curacao',
      'America/Dominica',
      'America/Grand_Turk',
      'America/Grenada',
      'America/Guadeloupe',
      'America/Guyana',
      'America/Kralendijk',
      'America/La_Paz',
      'America/Lower_Princes',
      'America/Manaus',
      'America/Marigot',
      'America/Martinique',
      'America/Montserrat',
      'America/Port_of_Spain',
      'America/Porto_Velho',
      'America/Puerto_Rico',
      'America/Santo_Domingo',
      'America/St_Barthelemy',
      'America/St_Kitts',
      'America/St_Lucia',
      'America/St_Thomas',
      'America/St_Vincent',
      'America/Tortola',
      'Etc/GMT+4'
    ]
  }, {
    'value': 'America/Santiago',
    'text': '(GMT-04:00) Santiago',
    'abbr': 'PSST',
    'offset': -4,
    'isdst': false,
    'utc': [
      'America/Santiago',
      'Antarctica/Palmer'
    ]
  }, {
    'value': 'America/Caracas',
    'text': '(GMT-04:30) Caracas',
    'abbr': 'VST',
    'offset': -4.5,
    'isdst': false,
    'utc': [
      'America/Caracas'
    ]
  }, {
    'value': 'America/Bogota',
    'text': '(GMT-05:00) Bogota, Lima, Quito',
    'abbr': 'SPST',
    'offset': -5,
    'isdst': false,
    'utc': [
      'America/Bogota',
      'America/Cayman',
      'America/Coral_Harbour',
      'America/Eirunepe',
      'America/Guayaquil',
      'America/Jamaica',
      'America/Lima',
      'America/Panama',
      'America/Rio_Branco'
    ]
  }, {
    'value': 'America/New_York',
    'text': '(GMT-05:00) Eastern EST (US and Canada)',
    'abbr': 'EDT',
    'offset': -4,
    'isdst': true,
    'utc': [
      'America/Detroit',
      'America/Havana',
      'America/Indiana/Petersburg',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Iqaluit',
      'America/Kentucky/Monticello',
      'America/Louisville',
      'America/Montreal',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Port-au-Prince',
      'America/Thunder_Bay',
      'America/Toronto'
    ]
  }, {
    'value': 'America/Indianapolis',
    'text': '(GMT-05:00) Indiana (East)',
    'abbr': 'UEDT',
    'offset': -4,
    'isdst': true,
    'utc': [
      'America/Indiana/Marengo',
      'America/Indiana/Vevay',
      'America/Indianapolis'
    ]
  }, {
    'value': 'America/Chicago',
    'text': '(GMT-06:00) Central CST (US and Canada)',
    'abbr': 'CDT',
    'offset': -5,
    'isdst': true,
    'utc': [
      'America/Chicago',
      'America/Indiana/Knox',
      'America/Indiana/Tell_City',
      'America/Matamoros',
      'America/Menominee',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Resolute',
      'America/Winnipeg',
      'CST6CDT'
    ]
  }, {
    'value': 'America/Mexico_City',
    'text': '(GMT-06:00) Mexico City',
    'abbr': 'CDT',
    'offset': -5,
    'isdst': true,
    'utc': [
      'America/Bahia_Banderas',
      'America/Cancun',
      'America/Merida',
      'America/Mexico_City',
      'America/Monterrey'
    ]
  }, {
    'value': 'America/Phoenix',
    'text': '(GMT-07:00) Arizona',
    'abbr': 'UMST',
    'offset': -7,
    'isdst': false,
    'utc': [
      'America/Creston',
      'America/Dawson_Creek',
      'America/Hermosillo',
      'America/Phoenix',
      'Etc/GMT+7'
    ]
  }, {
    'value': 'America/Denver',
    'text': '(GMT-07:00) Mountain MST (US and Canada)',
    'abbr': 'MDT',
    'offset': -6,
    'isdst': true,
    'utc': [
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Denver',
      'America/Edmonton',
      'America/Inuvik',
      'America/Ojinaga',
      'America/Yellowknife',
      'MST7MDT'
    ]
  }, {
    'value': 'America/Los_Angeles',
    'text': '(GMT-08:00) Pacific PST (US and Canada)',
    'abbr': 'PDT',
    'offset': -7,
    'isdst': true,
    'utc': [
      'America/Dawson',
      'America/Los_Angeles',
      'America/Tijuana',
      'America/Vancouver',
      'America/Whitehorse',
      'PST8PDT'
    ]
  }, {
    'value': 'America/Anchorage',
    'text': '(GMT-09:00) Alaska',
    'abbr': 'PDT',
    'offset': -7,
    'isdst': true,
    'utc': [
      'America/Dawson',
      'America/Los_Angeles',
      'America/Tijuana',
      'America/Vancouver',
      'America/Whitehorse',
      'PST8PDT'
    ]
  }, {
    'value': 'Pacific/Honolulu',
    'text': '(GMT-10:00) Hawaii',
    'abbr': 'HST',
    'offset': -10,
    'isdst': false,
    'utc': [
      'Etc/GMT+10',
      'Pacific/Honolulu',
      'Pacific/Johnston',
      'Pacific/Rarotonga',
      'Pacific/Tahiti'
    ]
  }, {
    'value': 'Pacific/Samoa',
    'text': '(GMT-11:00) Midway Island, Samoa',
    'abbr': 'SST',
    'offset': 13,
    'isdst': false,
    'utc': [
      'Pacific/Apia'
    ]
  }];

const TIMEZONES = ISO_TIMEZONES.reduce((accumulator, tz)=>accumulator.concat(tz.utc), []);


export {
  COUNTRIES,
  ISO_TIMEZONES,
  TIMEZONES,
  TIMEZONES_GROUPS
};
