<div class="card p-3">
  <mi-opacity-loader [ready]="!loading">
    <mi-server-error [error]="loadError"></mi-server-error>

    <div *ngIf="successMessage != null">
      <mi-alert-item [alertType]="'SUCCESS'">{{ successMessage }}</mi-alert-item>
    </div>

    <form (ngSubmit)="onFormSubmit(form.value, $event)" *ngIf="form !== null" [formGroup]="form" autocomplete="off">
      <!-- First Row -->
      <div class="">
        <mi-extended-input [errorDefs]='{required: "Please enter a first name", invalidFormat: "Please enter a valid first name!"}'
                            [inputControl]="form.controls['first_name']"
                            [inputErrors]="form.controls['first_name'].errors"
                            [inputTouched]="form.controls['first_name'].touched"
                            [labelRequired]="true"
                            [labelText]="'First Name'"
                            class="half-column">
          <input class="form-control"
                  formControlName="first_name"
                  placeholder="First Name"
                  type="text"
                  [attr.disabled]="!canEditRoleOnBu ? true : null"
                  mi-restrict-input miRestrictType="Name"
                  tabindex="1">
        </mi-extended-input>

        <mi-extended-input [labelText]="'Mobile Phone'"
                            class="half-column"
                            [labelRequired]="false"
                            [(inputControl)]="form.controls['mobile_phone']">
          <mi-telephone-simple-input *ngIf="canEditRoleOnBu" id="mobile_phone"
                                      class="form-control"
                                      name="mobile_phone"
                                      placeholder=""
                                      formControlName="mobile_phone"
                                      type="text"
                                      [tabindex]="5">
          </mi-telephone-simple-input>

          <input *ngIf="!canEditRoleOnBu" class="form-control"
                                    name="mobile_phone"
                                    placeholder=""
                                    formControlName="mobile_phone"
                                    type="text"
                                    disabled="true" tabindex="5"
          />
        </mi-extended-input>
      </div>
      <!-- </First Row -->

      <!-- Second Row -->
      <div class="">
        <mi-extended-input [errorDefs]='{required: "Please enter a last name", invalidFormat: "Please enter a valid last name!"}'
                            [inputControl]="form.controls['last_name']"
                            [inputErrors]="form.controls['last_name'].errors"
                            [inputTouched]="form.controls['last_name'].touched"
                            [labelRequired]="true"
                            [labelText]="'Last Name'"
                            class="half-column">
          <input class="form-control"
                  formControlName="last_name"
                  placeholder="Last Name"
                  type="text"
                  [attr.disabled]="!canEditRoleOnBu ? true : null"
                  mi-restrict-input
                  miRestrictType="Name"
                  tabindex="2">
        </mi-extended-input>

        <mi-extended-input *ngIf="editId" [errorDefs]='{required: "Please enter an Status."}'
                        [inputControl]="form.controls['is_active']"
                        [inputErrors]="form.controls['is_active'].errors"
                        [inputTouched]="form.controls['is_active'].touched"
                        [labelRequired]="true"
                        [labelText]="'Status'"
                        class="half-column">
          <select formControlName="is_active" [attr.disabled]="!canEditRoleOnBu ? true : null" tabindex="8">
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        </mi-extended-input>

        <mi-extended-input [errorDefs]='{required: "Please enter an email.", emailValidator:"Please enter a valid email."}'
                            [inputControl]="form.controls['email']"
                            [inputErrors]="form.controls['email'].errors"
                            [inputTouched]="form.controls['email'].touched"
                            [labelRequired]="true"
                            [labelText]="'Email'"
                            class="half-column">
          <input class="form-control"
                  formControlName="email"
                  placeholder="email@domain.com"
                  type="email"
                  [attr.disabled]="this.mode === 'EDIT'? true: null"
                  tabindex="7">
        </mi-extended-input>

        <!-- <mi-extended-input [labelText]="'Office Phone'"
                            class="half-column"
                            [labelRequired]="false"
                            [(inputControl)]="form.controls['office_phone']">
          <mi-telephone-simple-input *ngIf="canEditRoleOnBu"
                                      id="office_phone"
                                      class="form-control"
                                      name="office_phone"
                                      placeholder=""
                                      formControlName="office_phone"
                                      type="text"
                                      [tabindex]="6">
          </mi-telephone-simple-input>

          <input *ngIf="!canEditRoleOnBu" class="form-control"
                                    name="office_phone"
                                    placeholder=""
                                    formControlName="office_phone"
                                    type="text"
                                    disabled="true"
          />
        </mi-extended-input> -->
      </div>
      <!-- </Second Row -->

      <div *ngIf="showButtonContainer" class="d-flex justify-content-end note-info mt-4">
        <a (click)="onCancel($event)"
            buttonTheme="light-theme"
            class="button--cancel mr-2"
            mi-button>
          Cancel
        </a>

        <button *ngIf="canEditRoleOnBu"
                [disabled]="!form.valid"
                mi-button
                type="submit">
          {{ createUpdateLabel }}
        </button>
      </div>
    </form>
  </mi-opacity-loader>
</div>
