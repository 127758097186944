<mi-page-header title="Cost Change Analysis" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
</mi-page-header>


<div class="cost-change-analyzer-wizard">
    <div class="d-flex w-100 justify-content-between note-gray mb-2 cost-change-analyzer-wizard-title">
        <div>
            Cost Change Companion 
            <span *ngIf="showMetabaseUi">
                ({{ formatBillingMonth(selectedBillingMonthOption) }} compared to {{ formatBillingMonth(selectedPreviousBillingMonthOptions) }})
            </span>
        </div>
            
        <button *ngIf="showMetabaseUi" class="btn btn-md btn-primary mr-2" (click)="backToCompanion()">
            Back
        </button>
    </div>

    <div class="mt-1 mb-2 cost_change_analyser_form overlay-container" [class.d-block]="showMetabaseUi"
        [class.d-none]="!showMetabaseUi">
        <div class="card p-3 filter-container-card">
            <div class="row">
                <div class="col-2">
                    <label for="vendorSelect" class="form-label">
                        Vendors
                    </label>
                    <ng-select id="vendorSelect" [items]="vendorsOptions" bindLabel="label" bindValue="label"
                        [(ngModel)]="selectedVendorLabel"
                        [placeholder]="isVendorsLoading ? 'Loading vendors...' : 'Select Vendor'"
                        (change)="onVendorChangeHandler($event)" [searchable]="true">
                    </ng-select>
                </div>
                <div class="col-2">
                    <label for="accountSelect" class="form-label">
                        Accounts
                    </label>
                    <ng-select id="accountSelect" [items]="selectedVendorAccountsOptions" bindLabel="label"
                        bindValue="label" [disabled]="!selectedVendorLabel" [(ngModel)]="selectedVendorAccountLabel"
                        [placeholder]="isVendorAccountsLoading ? 'Loading accounts...' : 'Select Account'"
                        (change)="onAccountChangeHandler($event)" [searchable]="true"
                        (open)="selectedVendorAccountsOptions.length === 0 ? getVendorAccounts() : null">
                    </ng-select>
                </div>
                <div class="col-2" *ngIf="selectedCategory != 'Missing invoices'">
                    <label for="invoiceSelect" class="form-label">
                        Invoice
                    </label>
                    <ng-select id="invoiceSelect" [items]="invoiceOptions" bindLabel="label" bindValue="label"
                        [disabled]="!selectedVendorLabel" [(ngModel)]="selectedInvoiceLabel"
                        [placeholder]="isInVoicesLoading ? 'Loading invoices...' : 'Select Invoice'"
                        (change)="onInvoiceChangeHandler($event)" [searchable]="true"
                        (open)="invoiceOptions.length === 0 ? fetchTenantInvoice() : null">
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="collaps-filters" *ngIf="!showMetabaseUi">
    <div class="note-info d-flex justify-content-start step-title">
        <b class="mr-1">Step 1.</b>
        Select cost changes you want to review
    </div>

    <section class="mt-1">
        <div class="service-data-highlight-list card p-2">
            <div class="service-details">
                <ul class="dashboard-tools">
                    <li *ngFor="let category of categories" [class.selected]="category.name === selectedCategory"
                        [class.not-selected]="category.name !== selectedCategory && selectedCategory"
                        (click)="onCategoryClick(category.name)">
                        <div class="title">{{ category.name }}</div>
                    </li>
                </ul>
            </div>
            <div class="service-details mt-3" *ngIf="selectedCategory">
                <ul class="dashboard-tools answers-boxes">
                    <li *ngFor="let option of filteredOptions" [class.selected]="isOptionSelected(option)"
                        [class.disabled]="isOptionDisabled(option)"
                        (click)="!isOptionDisabled(option) && onOptionToggle(option)">
                        <input type="checkbox" class="mr-2" [disabled]="isOptionDisabled(option)"
                            [checked]="isOptionSelected(option)">
                        <div class="title">{{ option.key }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </section>

    <div class="note-info d-flex justify-content-start mt-4 step-title overlay-container">
        <b class="mr-1">Step 2.</b>
        Select the month you want to analyze
        <div class="overlay" *ngIf="!isStep1Clear"></div>
    </div>


    <div class="my-1 cost_change_analyser_form overlay-container">
        <div class="card p-3">
            <div class="row">
                <div class="col-5 d-flex align-items-center">
                    <div class="mr-3 question">
                        Choose a month to compare against the previous month
                    </div>
                </div>
                <div class="col-3 select-month">
                    <select [disabled]="!isStep1Clear" class="mt-1"
                        (change)="onBillingMonthChangesHandler($event.target.value)"
                        (click)="getMonthsWithRespectToSelectedOptions()">
                        <option [selected]="selectedBillingMonthOption === undefined" [value]="null" [disabled]="true" *ngIf="selectedBillingMonthOption === undefined">
                            <ng-container *ngIf="billingMonthOptions.length === 0">
                                <span *ngIf="isMonthOptionsLoading">Loading available months...</span>
                                <span *ngIf="!isMonthOptionsLoading && isMonthOptionsLoaded">Data is not available</span>
                                <span *ngIf="!isMonthOptionsLoading && !isMonthOptionsLoaded">Please select a month</span>
                            </ng-container>
                            <span *ngIf="billingMonthOptions.length > 0">Please select a month</span>
                        </option>
                        <option *ngFor="let billingMonth of billingMonthOptions"
                            [selected]="selectedBillingMonthOption === billingMonth.value"
                            [value]="billingMonth.value">
                            {{ billingMonth.label }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="overlay" *ngIf="!isStep1Clear"></div>
    </div>

    <div class="note-info d-flex justify-content-start mt-4 step-title overlay-container">
        <b class="mr-1">Step 3:</b>
        Start filtering by vendor, account, and invoice
        <div class="overlay" *ngIf="!isStep2Clear"></div>
    </div>

    <div class="my-1 cost_change_analyser_form  overlay-container">
        <div class="card p-4 d-flex justify-content-between align-items-center ">
            <div class="col-2 py-4">
                <button [disabled]="!isStep2Clear" (click)="getAnalyzeReport()"
                    class=" btn py-3 btn-block btn-primary mr-2 analyze-btn ">
                    Analyze
                </button>
            </div>
        </div>
        <div class="overlay" *ngIf="!isStep2Clear"></div>
    </div>
</div>

<div class="all-iframe-container mt-3" [class.d-contents]="!showLoader" [class.d-block]="showMetabaseUi" [class.d-none]="!showMetabaseUi">
    <div [class.d-block]="!isIframe1Loaded && isIframe1Available"
        [class.d-none]="isIframe1Loaded || !isIframe1Available" class="w-100 h-100">
        <div class="metabase-loader">
            <div class="spinner"></div>
            <p>Loading...</p>
        </div>
    </div>

    <div [class.d-block]="isIframe1Loaded && isIframe1Available"
        [class.d-none]="!isIframe1Loaded || !isIframe1Available" class="iframe-wrapper mb-2">
        <iframe class="iframe-container" id="metabase-iframe-1" src></iframe>
    </div>

    <div [class.d-block]="!isIframe2Loaded && isIframe2Available"
        [class.d-none]="isIframe2Loaded || !isIframe2Available" class="w-100 h-100">
        <div class="metabase-loader">
            <div class="spinner"></div>
            <p>Loading...</p>
        </div>
    </div>

    <div [class.d-block]="isIframe2Loaded && isIframe2Available"
        [class.d-none]="!isIframe2Loaded || !isIframe2Available" class="iframe-wrapper mb-2">
        <iframe class="iframe-container" id="metabase-iframe-2" src></iframe>
    </div>

    <div [class.d-block]="!isIframe3Loaded && isIframe3Available"
        [class.d-none]="isIframe3Loaded || !isIframe3Available" class="w-100 h-100">
        <div class="metabase-loader">
            <div class="spinner"></div>
            <p>Loading...</p>
        </div>
    </div>

    <div [class.d-block]="isIframe3Loaded && isIframe3Available"
        [class.d-none]="!isIframe3Loaded || !isIframe3Available" class="iframe-wrapper mb-2">
        <iframe class="iframe-container" id="metabase-iframe-3" src></iframe>
    </div>

    <div [class.d-block]="!isIframe4Loaded && isIframe4Available"
        [class.d-none]="isIframe4Loaded || !isIframe4Available" class="w-100 h-100">
        <div class="metabase-loader">
            <div class="spinner"></div>
            <p>Loading...</p>
        </div>
    </div>

    <div [class.d-block]="isIframe4Loaded && isIframe4Available"
        [class.d-none]="!isIframe4Loaded || !isIframe4Available" class="iframe-wrapper mb-2">
        <iframe class="iframe-container" id="metabase-iframe-4" src></iframe>
    </div>
</div>



<ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
</ng-template>