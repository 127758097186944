<ng-template #loadingAnimation>
  <mi-loading-animation [type]="'dots'"></mi-loading-animation>
</ng-template>

<mi-opacity-loader [ready]="!loading">
  <div class="advanced-search-header">
    <!-- <div class="clear-fix">
      <div class="search-col">
        <h2>{{searchTypeTitle}}</h2>
        <mi-search type="" [value]="q" (clearSearch)="clearSearch()" (searchSubmit)="onSearchSubmit($event)"></mi-search>
      </div>
      <div class="cost-col" *ngIf="showTotalMrc">
        <h2>Total Monthly Recurring Cost</h2>
        <p *ngIf="cmrcLoaded; else loadingAnimation" class="cost">{{total_mrc | tenantcurrency:'1.0'}}</p>
      </div>
      <div class="cost-col" *ngIf="showButton && buttonLabel && buttonUrl">
        <a class="breadcrumb button dark-theme" routerLink="{{buttonUrl}}">
          {{buttonLabel}}
        </a>
      </div>
    </div> -->

    <div class="clear-fix">
      <div class="search-col d-flex">
        <!-- search by svc input start -->
        <div class="w-50 mr-3">
          <h2>{{searchTypeTitle}}</h2>
            <mi-search type="" [value]="q" (clearSearch)="clearSearch()" (searchSubmit)="onSearchSubmit($event)"></mi-search>
        </div>
        <!-- search by svc input end -->

        <!-- search input start -->
        <div class="w-50  svc-search" *ngIf="showSVCSearch">
          <h2></h2>
          <div class="label-combo-input">
            <label class="label-combo-input-label">SVC-</label>
            <input #searchInputSVC  placeholder="Search by SVC-ID" (input)="onSearchBySVC($event.target.value)" 
                type="number" autofocus>
            <button *ngIf="showSVCclearButton" class="cross-button"(click)="clearSVCSearch()" ></button>
          </div>
        </div>
        <!-- search input end -->

      </div>
      <div class="cost-col" *ngIf="showTotalMrc">
        <h2>Total Monthly Recurring Cost</h2>
        <p *ngIf="cmrcLoaded; else loadingAnimation" class="cost">{{total_mrc | tenantcurrency:'1.0'}}</p>
      </div>
      <div class="cost-col" *ngIf="showButton && buttonLabel && buttonUrl">
        <a class="breadcrumb button dark-theme" routerLink="{{buttonUrl}}">
          {{buttonLabel}}
        </a>
      </div>
    </div>

    <div class="clear-fix">
      <div class="search-col">
        <div class="reporting count">
          <!--{{ ('SEARCH_QUANTITIES.' + searchType) | translate:{count:total} | translateSelector:total }}-->
          <div *ngIf="quantityLabel.length; else loadingAnimation">{{quantityLabel}}</div>
        </div>
        <div class="reporting sort">
          <mi-advanced-search-sort-v2-component [searchType]="searchType"></mi-advanced-search-sort-v2-component>
        </div>
        <div class="active-checkbox" *ngIf="searchType==='SERVICE'">
          <label class="checkbox">
            <input type="checkbox"
                  (change)="activeOnlyCheckbox($event)"
                  [disabled]="!activeButton"
                  [checked]="activeButtonSelected"
            />
            Active Only
          </label>
        </div>
      </div>
      <div class="cost-col">
        <mi-header-action-dropdown-v2 [searchType]="searchType" [addNewURL]="buttonUrl">
          <ng-content>
          </ng-content>
        </mi-header-action-dropdown-v2>
      </div>
    </div>
  </div>
</mi-opacity-loader>