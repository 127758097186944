<div class="service-details-header-item-economic">
  <div class="service-summary-item">
    <div class="section-title">
      <!-- Parent Group ({{serviceDetails.general.parent_service_display_id
      + ' ' +
      serviceDetails.general.parent_service_group_name}}) -->
      Parent Group ({{serviceDetails.general.parent_service_display_id}})
    </div>
    <hr>
    <dl class="section-details">
      <div>
        <dt>Product</dt>
        <dd>
          {{serviceDetails.service_data.product_name}}<br />
        </dd>
      </div>
      <div>
        <dt>P-CMRC</dt>
        <dd>
          <ng-container>
            {{parentServiceCalculationDetails.daily_rate.p_cmrc | tenantcurrency:'1.0-0' }}
          </ng-container>
        </dd>
      </div>
      <div>
        <dt>End of Term</dt>
        <dd>
          {{parentServiceCalculationDetails.current_dates.current_end_date ?
          (parentServiceCalculationDetails.current_dates.current_end_date | date:'shortDate') : "Unknown"}}
        </dd>
      </div>
      <div>
        <dt>Group Count</dt>
        <dd>
          {{activeServiceCount }}
        </dd>
      </div>
      <div>
        <dt>Remaining Liability</dt>
        <dd>
          <ng-container>
            {{ parentServiceCalculationDetails.remaining_liability === null ? "Unknown" :
            parentServiceCalculationDetails.remaining_liability | tenantcurrency:'1.0-0' }}
          </ng-container>
        </dd>
      </div>
    </dl>
  </div>
  <div class="parent-bottom">
  </div>
</div>