import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DocumentsV2ListRequest, DocumentsV2ListResponse } from "src/shared/models/DocumentV2";

@Injectable()
export class DocumentV2Service {
  private apiUrl = '_MICRO_SERVICE_/document';
  private getDocumentsAPI = `_MICRO_SERVICE_/document/download`;
  private getTenantDocumentsAPI = `_MICRO_SERVICE_/document/documents`;
  private getBulkUploadSourceFileDownloadAPI = `_MICRO_SERVICE_/document/download`;
  private getDocumentUrlAPI = `_MICRO_SERVICE_/document/get-file-link`;

  constructor(private http: HttpClient) {}

  getDocuments(request: DocumentsV2ListRequest): Observable<DocumentsV2ListResponse> {
    console.log("request", request);
    let queryString = this.apiUrl + "/documents";
    queryString += "?business_unit_id=" + request.business_unit_id;
    if(request.page_number != null) {
      queryString += "&page=" + request.page_number;
    }
    if(request.size != null) {
      queryString += "&size=" + request.size;
    }
    if(request.search) {
      queryString += "&search=" + request.search;
    }
    if(request.service_id) {
      queryString += "&service_id=" + request.service_id;
    }
    if(request.types) {
      queryString += "&types=" + request.types;
    }

    queryString += "&sort=extraction_date,DESC";

    return this.http
      .get(queryString)
      //.map((response: any) => response as DocumentsV2ListResponseObject[]);
      .map((response: DocumentsV2ListResponse) => {
        if(request.search) {
          response.search = request.search;
        }
        if(request.types) {
          response.types = request.types;
        }
        if(request.service_id) {
          response.serviceId = request.service_id;
        }
        return response;
      });
  }
  
  getVendorDocuments(request: DocumentsV2ListRequest): Observable<DocumentsV2ListResponse> {
    console.log("request", request);
    let queryString = this.apiUrl + "/vendor-documents";
    queryString += "?owner=" + request.business_unit_id;
    queryString += "&tenant_vendor_id=" + request.tenant_vendor_id;
    if(request.page_number != null) {
      queryString += "&page_number=" + request.page_number;
    }
    if(request.size != null) {
      queryString += "&size=" + request.size;
    }
    if(request.search) {
      queryString += "&q=" + request.search;
    }
    if(request.service_id) {
      queryString += "&service_id=" + request.service_id;
    }
    if(request.types) {
      queryString += "&types=" + request.types;
    }

    queryString += "&sort=createdOnUtc&order=DESC";

    return this.http
      .get(queryString)
      //.map((response: any) => response as DocumentsV2ListResponseObject[]);
      .map((response: DocumentsV2ListResponse) => {
        if(request.search) {
          response.search = request.search;
        }
        if(request.types) {
          response.types = request.types;
        }
        if(request.service_id) {
          response.serviceId = request.service_id;
        }
        return response;
      });
  }

  // getTenantDocumentsService(
  //   tenantId: string,
  //   pageNumber: number,
  //   pageSize: number = 10,
  //   searchByServiceDisplayId?: string,
  //   searchByServiceIdentifier?: string,
  //   documentDisplayId?: string,
  //   vendorName?: string,
  //   accountIdLable?: string,
  //   invoiceNumber?: string,
  // ) {
  //   const baseUrl = `${this.getTenantDocumentsAPI}?business_unit_id=${tenantId}&page=${pageNumber}&size=${pageSize}&types=INVOICE,RECEIPT,OTHER&sort=created_on_utc,DESC`;
    
  //   let searchParam = '';
    
  //   if (searchByServiceDisplayId) {
  //     searchParam += `&display_id=${searchByServiceDisplayId}`;
  //   } else if (searchByServiceIdentifier) {
  //     searchParam += `&service_identifier=${searchByServiceIdentifier}`;
  //   } else if (documentDisplayId) {
  //     searchParam += `&document_display_id=${documentDisplayId}`;
  //   } else if (vendorName) {
  //     searchParam += `&vendor_name=${vendorName}`;
  //   } else if (accountIdLable) {
  //     searchParam += `&accountid_label=${accountIdLable}`;
  //   } else if (invoiceNumber) {
  //     searchParam += `&invoice_number=${invoiceNumber}`;
  //   }
  
  //   return this.http.get<any>(`${baseUrl}${searchParam}`);
  // }

  getTenantDocumentsService(
    tenantId: string,
    pageNumber: number,
    pageSize: number = 10,
    searchByServiceDisplayId?: string,
    searchByServiceIdentifier?: string,
    documentDisplayId?: string,
    vendorName?: string,
    accountIdLable?: string,
    invoiceNumber?: string,
) {
    const baseUrl = `${this.getTenantDocumentsAPI}?business_unit_id=${tenantId}&page=${pageNumber}&size=${pageSize}&types=INVOICE,RECEIPT,OTHER&sort=created_on_utc,DESC`;
    
    let searchParam = '';
    
    if (searchByServiceDisplayId) {
        searchParam += `&display_id=${searchByServiceDisplayId}`;
    }
    
    if (searchByServiceIdentifier) {
        searchParam += `&service_identifier=${searchByServiceIdentifier}`;
    }
    
    if (documentDisplayId) {
        searchParam += `&document_display_id=DOC-${documentDisplayId}`;
    }
    
    if (vendorName) {
        searchParam += `&vendor_name=${vendorName}`;
    }
    
    if (accountIdLable) {
        searchParam += `&accountid_label=${accountIdLable}`;
    }
    
    if (invoiceNumber) {
        searchParam += `&invoice_number=${invoiceNumber}`;
    }
  
    return this.http.get<any>(`${baseUrl}${searchParam}`);
  }

  getDocumentUrlService(documentId: string) {
    return this.http.get<any>(`${this.getDocumentsAPI}?id=${documentId}`)
  }

  getBulkUploadSourceFileUrlService(documentId: string) {
    return this.http.get<any>(`${this.getBulkUploadSourceFileDownloadAPI}?id=${documentId}`)
  }

  getDownloadConnectDocumentUrlService(fileName: string, s3Location: string) {
    return this.http.get<any>(`${this.getDocumentUrlAPI}?fileName=${fileName}&s3Location=${s3Location}`)
  }

}