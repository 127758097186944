import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ISecureUser } from "src/common/security/secureUser";
import { CustomField } from "src/shared/models/CustomField";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as ServiceV2Reducer from '../../../shared/state/service-v2/reducers';
import * as ServiceV2Actions from '../../../shared/state/service-v2/actions';
import { SearchFilterV2, ServiceListingColumnsCustom, ServiceListingColumnsInitial, ServiceV2Filter, ServiceV2Request, ServiceV2Response, ServiceV2ResponseData } from "src/shared/models/ServiceV2";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import { ServiceV2Service } from "src/libs/api2/service-v2/service-v2.service";
import { TagsService } from "src/libs/api2/tags/tags.service";
import { TagDefinition } from "src/shared/models/Tags";
import { AdvancedSearchHeaderV2Component } from "../advanced-search/advanced-search-header-v2/advanced-search-header-v2.component";

@Component({
  selector: 'mi-applied-search-filters-v2',
  styleUrls: ['./applied-search-filters-v2.component.scss'],
  templateUrl: './applied-search-filters-v2.component.html'
})

export class AppliedSearchFiltersV2Component extends AbstractPageComponent implements OnInit {
  error: string = null;
  filters: SearchFilterV2[] = [];
  loaded = false;
  resultsLoaded$: Observable<boolean>;
  users: ISecureUser[] = [];
  customFields: CustomField[] = [];
  pageLockFilters: string[] = [];
  contacts: any = {};
  customFieldsByIDs: any = {};
  businessUnitsByIDs: any = {};
  accountIdsByIDs: any = {};
  contactsByIDs: any = {};
  serviceGroupByIDs: any = {};
  searchInputSVC: any = {};  
  searchType: string = '';
  serviceData: ServiceV2Response;
  existingFilters: ServiceV2Filter[];
  allTags: TagDefinition[] = [];
  tagById: any = {};
  tenantId: string;

  @Input() nonRemovableFilters: string[] = [];
  request: ServiceV2Request = {
    pageSize: 0,
    page_number: 0,
    tenant_id: "",
    search: "",
    sort: {},
    filters: []
  };

  constructor(
    private serviceV2Service: ServiceV2Service,
    private tagsService: TagsService,
    private store: Store<ServiceV2Reducer.State>
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.store.select(ServiceV2Reducer.getServiceV2Data)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          console.log("store data", data);
          this.serviceData = data.results;
          this.tenantId = this.serviceData.tenantId;
          this.existingFilters = data.results.filters;

          this.calculateFilters();
          this.getTags();
        }
      );
  }

  getTags(): void {
    this.tagsService.getTags(this.tenantId)
    .subscribe(res => {
      console.log("getTags", res);
      this.allTags = res;

      this.populateTags();
    });
  }

  populateTags(): void {
    this.allTags.map(tag => this.tagById[tag.tagId] = tag.tagName);
  }

  onRemoveAllClick(): void {
    this.loaded = false;

    this.serviceData.filters = [{
      field_name: "service_status",
      type: "option",
      is_custom_field: false,
      is_tag: false,
      is_location: false,
      values: [
        "active"
      ]
    }];
    this.filters = [];

    this.updateResults();
  }

  onRemoveClick($event: SearchFilterV2): void {
    console.log("event", $event);
    console.log("filters", this.filters);
    this.loaded = false;

    if($event.field_type == "search") {
      this.serviceData.search = "";
      this.filters.splice(this.filters.indexOf(this.filters.find(f => f.field_type == "search")), 1);
    } else if($event.field_name === "parent_service_group_search") {
        $event.value = null;
        this.filters = this.filters.filter(filter => filter.field_name !== "parent_service_group_search");
        this.serviceData.filters = this.serviceData.filters.filter(filter => filter.field_name !== "parent_service_group_search");
    }
    else if($event.field_type == "option") {
      if($event.field_name == "location_a") {
        this.serviceData.filters.forEach(existingFilter => {
          if(existingFilter.field_name == $event.field_name) {
            switch($event.location_type) {
              case "country":
                existingFilter.locations.country.splice(existingFilter.locations.country.indexOf($event.value), 1);
                break;
              case "state":
                existingFilter.locations.state.splice(existingFilter.locations.state.indexOf($event.value), 1);
                break;
              case "city":
                existingFilter.locations.city.splice(existingFilter.locations.city.indexOf($event.value), 1);
                break;
            }
            if(existingFilter.locations.country.length == 0
              && existingFilter.locations.state.length == 0
              && existingFilter.locations.city.length == 0) {
                this.serviceData.filters.splice(this.serviceData.filters.indexOf(existingFilter), 1);
                this.filters.splice(this.filters.indexOf(this.filters.find(f => f.field_name == $event.field_name)), 1);
              }
          }
        });
      }
      else {
        this.serviceData.filters.forEach(existingFilter => {
          if(existingFilter.field_name == $event.field_name) {
            existingFilter.values.splice(existingFilter.values.indexOf($event.value), 1);
            if(existingFilter.values.length == 0) {
              this.serviceData.filters.splice(this.serviceData.filters.indexOf(existingFilter), 1);
              this.filters.splice(this.filters.indexOf(this.filters.find(f => f.field_name == $event.field_name)), 1);
            }
          }        
        });
      }      
    }
    else if($event.field_type == "range") {
      this.serviceData.filters.forEach(existingFilter => {
        if(existingFilter.field_name == $event.field_name) {
          if($event.filterName.split("").reverse().join("").substring(0,4) == "XAM_") {
            existingFilter.range_max = null;
          }
          else if($event.filterName.split("").reverse().join("").substring(0,4) == "NIM_") {
            existingFilter.range_min = null;
          }
          if(!existingFilter.range_max && !existingFilter.range_min) {
            this.serviceData.filters.splice(this.serviceData.filters.indexOf(existingFilter), 1);
            this.filters.splice(this.filters.indexOf(this.filters.find(f => f.field_name == $event.field_name)), 1);
          }
        }        
      });
    }
    else if($event.field_type == "date_range") {
      this.serviceData.filters.forEach(existingFilter => {
        if(existingFilter.field_name == $event.field_name) {
          if($event.filterName.split("").reverse().join("").substring(0,4) == "XAM_") {
            existingFilter.date_range_max = null;
          }
          else if($event.filterName.split("").reverse().join("").substring(0,4) == "NIM_") {
            existingFilter.date_range_min = null;
          }
          if(!existingFilter.date_range_max && !existingFilter.date_range_min) {
            this.serviceData.filters.splice(this.serviceData.filters.indexOf(existingFilter), 1);
            this.filters.splice(this.filters.indexOf(this.filters.find(f => f.field_name == $event.field_name)), 1);
          }
        }        
      });
    }
    console.log("filters", this.filters);
    console.log("serviceData", this.serviceData);

    this.updateResults();
  }

  setLoadMoreStatus(data: any[]): void {
    if(data.length > 0) {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(true));
    }
    else {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(false));
    }
  }

  combineData(data1: ServiceV2ResponseData[], data2: ServiceV2ResponseData[]): any {
    let combinedData: ServiceV2ResponseData[] = [];
   
   data1.forEach((data, index) => {
    data.miso_service.custom_fields = data2[index].miso_service.custom_fields;
    data.miso_service.tags = data2[index].miso_service.tags;
   });

   return data1;
  }

  formatData(data: ServiceV2ResponseData[]): any {
    //console.log("formatData", data);

    data.map(service => {
      if(service.miso_service.tags.length > 0) {
        let spliceList: number[] = [];
        
        for(let i=0; i<service.miso_service.tags.length; i++) {
          let tagId = service.miso_service.tags[i].tag_id;
          for(let j=0; j<service.miso_service.tags.length; j++) {
            let comparedTagId = service.miso_service.tags[j].tag_id;
            //console.log("formatData tagId", tagId);
            //console.log("formatData comparedTagId", comparedTagId);
            if(j == i) continue;
            if((tagId == comparedTagId) && (spliceList.indexOf(i) == -1)) {
              service.miso_service.tags[i].tag_value += ", " + service.miso_service.tags[j].tag_value;
              spliceList.push(j);
            }
          }
        }

        //console.log("formatData spliceList", spliceList);

        spliceList.map(spliceIndex => {
          service.miso_service.tags.splice(spliceIndex, 1);
        });
      }
    });

    return data;
  }

  updateResults(): void {
    this.setDefaultRequestParams();

    console.log("UPDATING SERVICES");
    this.store.dispatch(new ServiceV2Actions.SetSearchLoading(this.serviceData));
    this.serviceV2Service.getServices(this.request)
    .toPromise()
    .then(res => {
      console.log("res", res);
      this.setLoadMoreStatus([...res.data]);
      this.serviceData = res;
      this.serviceData.filters = this.request.filters;
      this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));
      this.store.dispatch(new ServiceV2Actions.SetSearchLoaded(this.serviceData));
      this.loaded = true;
      this.serviceV2Service.getTotalCmrc(this.request)
      .toPromise()
      .then((totalCmrc: any) => {
        this.serviceData.total_mrc = totalCmrc.total_cmrc;
        this.serviceData.total = totalCmrc.total_service_count;
        this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));

        this.request.columns = ServiceListingColumnsCustom;
        this.serviceV2Service.getServices(this.request)
        .toPromise()
        .then((response: ServiceV2Response) => {
          let combinedData = this.combineData([...this.serviceData.data], [...response.data]);
          //console.log("combinedData", combinedData);
          let formattedData = this.formatData([...combinedData]);
          console.log("formattedData", formattedData);
          this.serviceData.data = formattedData;
          this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));
        })
        .catch(error => {
          console.log("error while getServices_2", error);
          this.error = error;
        });

      })
      .catch(error => {
        console.log("error while getCmrc", error);
      });
    })
    .catch(err => {
      console.log("error while getServices", err);
    });
  }

  setDefaultRequestParams() {
    this.request.pageSize = 25;
    this.request.page_number = 0;
    this.request.tenant_id = FrontEndSettingsUtil.getDomain();
    this.request.sort = this.serviceData.sort;
    this.request.filters = this.serviceData.filters;
    this.request.search = this.serviceData.search;
    //this.request.cursor = 0;
    this.request.columns = ServiceListingColumnsInitial;
  }

  calculateFilters() {
    this.filters = [];

    if(this.serviceData.search.length > 0) {
      this.filters.push({
        filterName: "SEARCH",
        value: this.serviceData.search,
        field_name: "SEARCH",
        field_type: "search",
        removable: true
      });
    }

    console.log("calculateFilters existingFilters", this.existingFilters);
    
    this.existingFilters.forEach(filter => {
      switch(filter.type) {
        case "option":
          if(filter.is_location) {
            if(filter.locations.country.length > 0) {
              this.filters.push({
                filterName: filter.field_name,
                value: filter.locations.country,
                field_name: filter.field_name,
                field_type: "option",
                location_type: "country",
                filterLabel: "Country",
                removable: filter.removable
              });
            }
            if(filter.locations.state.length > 0) {
              this.filters.push({
                filterName: filter.field_name,
                value: filter.locations.state,
                field_name: filter.field_name,
                field_type: "option",
                location_type: "state",
                filterLabel: "State",
                removable: filter.removable
              });
            }
            if(filter.locations.city.length > 0) {
              this.filters.push({
                filterName: filter.field_name,
                value: filter.locations.city,
                field_name: filter.field_name,
                field_type: "option",
                location_type: "city",
                filterLabel: "City",
                removable: filter.removable
              });
            }
          }
          else {
            this.filters.push({
              filterName: filter.field_name,
              value: filter.values,
              field_name: filter.field_name,
              field_type: "option",
              // removable: filter.removable
              removable: !this.nonRemovableFilters.includes(filter.field_name),
            });
          }          
          break;
        case "range":
          if(filter.range_max) {
            this.filters.push({
              filterName: filter.field_name + "_MAX",
              value: filter.range_max,
              field_name: filter.field_name,
              field_type: "range",
              removable: filter.removable
            });
          }
          if(filter.range_min) {
            this.filters.push({
              filterName: filter.field_name + "_MIN",
              value: filter.range_min,
              field_name: filter.field_name,
              field_type: "range",
              removable: filter.removable
            });
          }
          break;
        case "date_range":
          if(filter.date_range_max) {
            this.filters.push({
              filterName: filter.field_name + "_MAX",
              value: filter.date_range_max,
              field_name: filter.field_name,
              field_type: "date_range",
              removable: filter.removable
            });
          }
          if(filter.date_range_min) {
            this.filters.push({
              filterName: filter.field_name + "_MIN",
              value: filter.date_range_min,
              field_name: filter.field_name,
              field_type: "date_range",
              removable: filter.removable
            });
          }
          break;
      }
    });

    this.loaded = true;
  }
}