import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import * as userReducer from "../../../libs/user-store/state/reducers";
import { select, Store } from "@ngrx/store";
import { TenantV2 } from 'src/libs/api2/types/TenantV2.interface';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ISO_TIMEZONES, IsoTimezone } from "../../../common/utils/isoConstants";
import * as appDataReducer from "../../../shared/state/app-data/reducers";
import { map, switchMap, takeUntil, tap } from "rxjs/operators";
import { TenantV2Service } from "../../../libs/api2/tenant-v2/tenant-v2.service";
import { combineLatest, forkJoin, Observable } from "rxjs";
import { filterRole, isEditRoleOnBU } from "../../../common/utils/UserAccess";
import { emailValidator, mobileNumberValidator, companyUrlValidator } from 'src/shared/shared.validators';
import { UPLOAD_TYPES } from '../../../common/utils/sharedConstants';
import { FileUpload } from '../../../shared/models/FileUpload';
import { LocationV2 } from '../../../libs/api2/types/LocationV2.interface';
import { TenantCurrencyV2 } from '../../../libs/api2/types/TenantCurrencyV2.interface';
import linkFormatter from 'src/common/utils/linkFormatter';
import { ToastrService } from 'ngx-toastr';
import { Link } from 'src/shared/ng-models/Link.interface';
import { ActivatedRoute, Params } from '@angular/router';
import { BusinessUnitContactV2 } from 'src/libs/api2/types/BussinessUnitContactV2.interface';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { Miso3baseService } from 'src/libs/api2/miso3base-v2/miso3base.service';
import { RoleV2 } from 'src/libs/api2/types/RolesV2.interface';
import { AppDataGetAction } from 'src/shared/state/app-data/actions';
@Component({
  selector: 'mi-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent extends AbstractPageComponent implements OnInit {
  // breadcrumbs: Link[];
  title: string = 'Company Information';

  breadcrumbs = [
    {
      label: 'Dashboard',
      url: '/',
      internal: true
    },
    {
      label: this.title,
      url: './',
      internal: true
    }
  ];

  readonly DOMAIN_MIN_LENGTH: number = 4;
  readonly DOMAIN_MAX_LENGTH: number = 25;
  isDataLoaded: boolean = false;
  buData: TenantV2;
  buContactData: BusinessUnitContactV2;
  error: any;

  form: FormGroup;
  formCompany: FormGroup;

  timezones: IsoTimezone[];
  success: string = '';
  $isSuperUser: Observable<boolean>;
  canViewOrEdit: boolean = false;
  canViewOrEditContact: boolean = false;
  location: LocationV2;
  locationId: string;
  tenantId: string;
  canRecalculate: boolean = false;
  fullName: string;

  @ViewChild('editContactModal') editContactModal;
  @ViewChild('editModal') editModal;

  readonly UPLOAD_TYPES: any = UPLOAD_TYPES;
  searchInited = false;
  tenantV1Data: any = {};
  tenantContactData: any = {};
  tenantCurrencies: TenantCurrencyV2[];
  availableRoles: RoleV2[] = [];
  oldDomain: string = "";
  businessUnitId: string = null;
  canEditView: boolean = false;

  constructor(
    private tenantV2Service: TenantV2Service,
    private formBuilder: FormBuilder,
    private miso3baseService: Miso3baseService,
    private store: Store<any>,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private contactV2Service: ContactV2Service
  ) {
    super();
  }

  ngOnInit() { 
    this.initializeForms();
    this.dataFetching();
    this.timezones = ISO_TIMEZONES;
  }

  dataFetching() {
    const buSub = this.store.pipe(
      select(appDataReducer.getAppData),
      map(appData => {
        this.tenantV1Data = appData.tenantData;        
        this.oldDomain = appData.tenantData.v2.domain;
        this.tenantId = appData.tenantData.v2.id;
        this.canRecalculate = appData.tenantData.v2.role == 1 || appData.tenantData.v2.role == 6 || appData.tenantData.v2.role == 5;
        return appData.tenantData.domain
      }),
      switchMap(id => this.tenantV2Service.getBusinessUnit(id).pipe(
        tap((response) => {
          this.tenantV1Data.v2.name = response.name;
          this.tenantV1Data.v2.url = response.url;
          this.buData = response;
        })
      )),
    );

    const fetchTenant = this.store.pipe(
      select(appDataReducer.getAppData)
    );

    const fetchContact = this.store.pipe(
      select(appDataReducer.getAppData),
      takeUntil(this.destroy$),
      map((appData) => {
        this.businessUnitId = appData.tenantData.domain;
        this.getBusinessUnit(this.businessUnitId);
        return this.businessUnitId;
      }),
      switchMap((id) =>
        this.tenantV2Service.getBusinessUnitContact(id).pipe(
          tap((response) => {
            this.tenantContactData = response;
            this.buContactData = response;            
          })
        )
      )
    );

    const fetchRoles = this.miso3baseService.getRoles();
    combineLatest(
      buSub,
      fetchContact,
      fetchRoles,
      fetchTenant
    )
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        ([buData, buContact, buRoles, buTenant]) => {
          this.isDataLoaded = false;
          this.buData = buData;
          this.availableRoles = filterRole(buRoles, buTenant.tenantData && buTenant.tenantData.v2 ? buTenant.tenantData.v2.miso_business_unit : false);
          this.buContactData = buContact;
          this.isDataLoaded = true;
          if (buContact && buContact.tenant_user_id) {
            this.contactV2Service.indexTenantUser({ id: buContact.tenant_user_id }).subscribe(
              (tu) => {
              });
          }
          this.setupForm(this.buContactData);
          this.setupCompanyForm(this.buData);
        },
        (e) => {
          this.error = e;
        }
      );

    this.$isSuperUser = this.store.pipe(select(userReducer.isSuperUser)) as unknown as Observable<boolean>;
  }

  initializeForms() {
    this.form = this.formBuilder.group({
      full_name: ['', Validators.required],
      role_id: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, emailValidator]],
      mobile_phone: ['', [Validators.required, mobileNumberValidator]],
    });

    this.formCompany = this.formBuilder.group({
      name: ['', Validators.required],
      url: ['', [Validators.required, companyUrlValidator]],
      logo: [null],
    });
  }

  onEditClick(): void {
    this.setupCompanyForm(this.buData);
    this.canViewOrEdit = true;
    this.editModal.open();
  }

  onEditContactClick(): void {
    this.setupForm(this.buContactData);
    const fullName = this.form.get('full_name').value || null;
    const [firstName, lastName] = this.splitFullName(fullName);
    // To Populate the full name in the edit modal
    this.form.patchValue({
      first_name: firstName,
      last_name: lastName,
    });
    this.editContactModal.open();
  }

  private splitFullName(fullName: string): [string, string] {
    const nameParts = fullName.trim().split(' ');
    const firstName = nameParts.shift() || '';
    const lastName = nameParts.join(' ') || '';
    return [firstName, lastName];
  }

  getBusinessUnit(id) {
    this.tenantV2Service.getBusinessUnit(id).subscribe(
      (data) => {
        this.canEditView = isEditRoleOnBU(data.role);
      });
  }

  setupCompanyForm(currentData: TenantV2): void {
    let {
      name = '',
      url = '',
      address_location_id,
    } = currentData;
    this.locationId = address_location_id;
    let logo: FileUpload = this.tenantV1Data.logo;
    if (currentData.logo) {
      if (typeof currentData.logo === 'string') {
        logo = {
          file_name: currentData.logo,
          upload_type: UPLOAD_TYPES.TENANT_LOGO.KEY_PREFIX,
          file_path: currentData.logo,
          full_path: currentData.logo
        };
      }
      else {
        logo = currentData.logo;
      }
    }
    this.formCompany.controls['name'].setValue(name);
    this.formCompany.controls['url'].setValue(url);
    this.formCompany.controls['logo'].setValue(logo);
  }

  setupForm(contactData: BusinessUnitContactV2): void {
    const {
      first_name = '',
      last_name = '',
      role_id = '5',
      email = '',
      mobile_phone = '',
    } = contactData;
    this.fullName = `${first_name} ${last_name}`.trim();
    if (contactData) {
      if (typeof contactData === 'object') {
        contactData = {
          ...contactData,
          first_name: contactData.first_name,
          last_name: contactData.last_name,
          role_id: contactData.role_id,
          email: contactData.email,
          mobile_phone: contactData.mobile_phone,
        };
      } else {
        contactData = null;
      }
      this.form.controls['full_name'].setValue(this.fullName);
      this.form.controls['role_id'].setValue(role_id);
      this.form.controls['first_name'].setValue(this.fullName);
      this.form.controls['last_name'].setValue(this.fullName);
      this.form.controls['email'].setValue(email);
      this.form.controls['mobile_phone'].setValue(mobile_phone || '');
    }
  }

  onResetForm($event): void {
    $event.preventDefault();
    this.setupForm(this.buContactData);
    this.setupCompanyForm(this.buData);
    this.canViewOrEdit = false;
    this.canViewOrEditContact = false;
    this.editContactModal.close();
    this.editModal.close();
  }

  onSubmitCompany(data): void {
    const newData: TenantV2 = {
      ...this.buData,
      ...data,
      // is_active: !!data.active,
      logo: data.logo ? data.logo.full_path : null
    };
    this.success = '';
    this.error = '';
    this.tenantV2Service.saveBusinessUnit(newData)
      .subscribe(
        (data) => {
          this.buData = data;
          if (data.domain !== this.oldDomain) {
            const link = linkFormatter.getTenantManageLink(data.domain);
            window.location.href = link;
          }
          this.store.dispatch(new AppDataGetAction(this.buData.name));
          this.dataFetching();
          // this.setupCompanyForm(this.buData);
          this.toastr.success(`Your Company Information has been saved.`);
          this.editModal.close();
        },
        (e) => {
          this.error = e;
        });
  }

  onSubmitContact(data): void {
    const newData: BusinessUnitContactV2 = {
      ...this.buData,
      ...data,
      // is_active: data.active,
      business_unit_id: this.businessUnitId
    };
    this.success = '';
    this.error = '';
    this.tenantV2Service.saveBusinessContact(newData)
      .subscribe(
        (data) => {
          this.buContactData = data;
          if (data.tenant_user_id) {
            this.contactV2Service.indexTenantUser({ id: data.tenant_user_id }).subscribe(
              (tu) => {
              });
          }
          this.dataFetching();
          // this.setupForm(this.buContactData);
          this.toastr.success(`Your Contact Information has been saved.`);
          this.editContactModal.close();
        },
        (e) => {
          this.error = e;
        }
      );
  }

  onResetAddress($event) {
    $event.preventDefault();
    this.location = null;
    this.locationId = null;
    this.form.controls.address_location_id.setValue(this.locationId);
  }
}